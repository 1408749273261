<template>
    <div class="flexem">
        <div class="title">{{ $t('blindBoxHistory') }}</div>
        <div class="base-table blind-box">
            <b-table
                :fields="fields"
                :items="HistoryList"
                hover
                responsive
                show-empty
                sticky-header="100%"
                thead-class="custom-header"
            >
                <template #head()="data">
                    {{ $t(data.label) }}
                </template>
                <template #empty>
                    <div class="text-center my-3">
                        {{ $t('noData') }}
                    </div>
                </template>
                <template #cell(date)="{ item }">
                    {{ getDateTime5(item.openedAt) }}
                </template>
                <template #cell(name)="{ item }">
                    <template v-if="item.nodeInfo">
                        {{ item.nodeInfo.name }}
                    </template>
                </template>
                <template #cell(value)="{ item }">
                    <template v-if="item.nodeInfo">
                        {{ item.nodeInfo.priceUSDT }} USDT -
                        {{ item.nodeInfo.performance }} Hz
                    </template>
                </template>
            </b-table>
        </div>
        <div class="paging">
            <b-pagination
                v-if="History"
                v-model="page"
                :total-rows="History.totalResults"
                :per-page="limit"
            >
            </b-pagination>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'BlindBoxHistory',
    data() {
        return {
            fields: [
                {
                    class: 'text-center',
                    label: 'name',
                    key: 'name',
                },
                {
                    class: 'text-center',
                    label: 'value',
                    key: 'value',
                },
                {
                    class: 'text-center',
                    label: 'date',
                    key: 'date',
                },
            ],
            page: 1,
            limit: 4,
        };
    },
    computed: {
        ...mapGetters({
            History: 'blindbox/History',
        }),
        HistoryList() {
            return this.History?.results ?? [];
        },
    },
    methods: {
        async requestHistory() {
            return this.$store.dispatch('blindbox/getBlindBoxHistory', {
                page: this.page,
                limit: this.limit,
            });
        },
    },
    mounted() {
        this.requestHistory();
    },
    watch: {
        page: {
            handler() {
                this.requestHistory();
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.base-table {
    min-height: none;
    flex-basis: 10px;
    flex-shrink: 1;
    flex-grow: 1;
    max-height: 100%;
    overflow: hidden;
}
.flexem {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.paging {
    flex-shrink: 0;
}
::v-deep {
    .paging .pagination {
        margin-bottom: 0px;
    }
}
.title {
    font-size: 18px;
    color: #1478b0;
    font-weight: 600;
    flex-shrink: 0;
}
</style>
