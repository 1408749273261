<template>
    <!--    <div class="qr-container">-->
    <!--        <div class="qr">-->
    <!--            <img :src="MyMerchantStatical.qrUrl" alt="qr-merchant" />-->
    <!--        </div>-->
    <!--        <div class="note">-->
    <!--            {{-->
    <!--                $t('scanQR', {-->
    <!--                    number:-->
    <!--                        Number(MyMerchantStatical.nodeInfo.memberPercent) * 100,-->
    <!--                })-->
    <!--            }}-->
    <!--        </div>-->
    <!--        <div class="manual">-->
    <!--            {{ $t('codeQR') }}:-->
    <!--            <span>{{ MyMerchantStatical.qrManual }}</span>-->
    <!--            <button-->
    <!--                v-b-tooltip.hover-->
    <!--                v-clipboard:copy="MyMerchantStatical.qrManual"-->
    <!--                v-clipboard:error="onError"-->
    <!--                v-clipboard:success="onCopy"-->
    <!--                :title="$t('copy')"-->
    <!--            >-->
    <!--                <svg-->
    <!--                    height="24"-->
    <!--                    viewBox="0 0 24 24"-->
    <!--                    width="24"-->
    <!--                    xmlns="http://www.w3.org/2000/svg"-->
    <!--                >-->
    <!--                    <path-->
    <!--                        d="M6 15h-.6C4.07 15 3 13.93 3 12.6V5.4C3 4.07 4.07 3 5.4 3h7.2C13.93 3 15 4.07 15 5.4V6m-3.6 3h7.2a2.4 2.4 0 0 1 2.4 2.4v7.2a2.4 2.4 0 0 1-2.4 2.4h-7.2A2.4 2.4 0 0 1 9 18.6v-7.2A2.4 2.4 0 0 1 11.4 9"-->
    <!--                        fill="none"-->
    <!--                        stroke="currentColor"-->
    <!--                        stroke-linecap="round"-->
    <!--                        stroke-linejoin="round"-->
    <!--                        stroke-miterlimit="10"-->
    <!--                        stroke-width="1.5"-->
    <!--                    />-->
    <!--                </svg>-->
    <!--            </button>-->
    <!--        </div>-->
    <!--    </div>-->
    <div class="qr-container">
        <div :class="{ disconnected: !SSEConnected }" class="qr">
            <!-- QR image with conditional blur -->
            <img
                v-if="MyMerchantStatical"
                :class="{ blurred: !SSEConnected }"
                :src="MyMerchantStatical.qrUrl"
                alt="qr-merchant"
            />

            <!-- Connection status overlay with spinner -->
            <div v-if="!SSEConnected" class="connection-status">
                <div class="status-container">
                    <div class="spinner"></div>
                    <div class="status-message">
                        {{
                            $t('waitForConnection') ||
                            'Waiting to connect to server...'
                        }}
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="MyMerchantStatical && MyMerchantStatical.nodeInfo"
            class="note"
        >
            {{
                $t('scanQR', {
                    number:
                        Number(MyMerchantStatical.nodeInfo.memberPercent) * 100,
                })
            }}
        </div>
        <!-- Manual section with conditional blur -->
        <div
            v-if="MyMerchantStatical"
            :class="{ 'blurred-content': !SSEConnected }"
            class="manual"
        >
            {{ $t('codeQR') }}:
            <span>{{ MyMerchantStatical.qrManual }}</span>
            <button
                v-b-tooltip.hover
                v-clipboard:copy="MyMerchantStatical.qrManual"
                v-clipboard:error="onError"
                v-clipboard:success="onCopy"
                :title="$t('copy')"
            >
                <svg
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6 15h-.6C4.07 15 3 13.93 3 12.6V5.4C3 4.07 4.07 3 5.4 3h7.2C13.93 3 15 4.07 15 5.4V6m-3.6 3h7.2a2.4 2.4 0 0 1 2.4 2.4v7.2a2.4 2.4 0 0 1-2.4 2.4h-7.2A2.4 2.4 0 0 1 9 18.6v-7.2A2.4 2.4 0 0 1 11.4 9"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                    />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ManageMerchant',

    methods: {
        onCopy() {
            this.$toastr.s('Copy Success', 'Successfully');
        },
        onError() {
            this.$toastr.e('Copy Fail,Please Try Again', 'Failed');
        },
    },

    computed: {
        ...mapGetters({
            MyMerchantStatical: 'merchant/MyMerchantStatical',
            SSEConnected: 'merchant/SSEConnected',
        }),
    },
};
</script>

<style lang="scss" scoped>
.qr-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .qr {
        width: 300px;
        height: 300px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;

            transition: filter 0.3s ease;

            &.blurred {
                filter: blur(6px);
            }
        }

        .connection-status {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .status-container {
                background-color: rgba(0, 0, 0, 0.7);
                color: white;
                padding: 16px;
                border-radius: 8px;
                text-align: center;
                max-width: 85%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 12px;

                .spinner {
                    width: 30px;
                    height: 30px;
                    border: 3px solid rgba(255, 255, 255, 0.3);
                    border-radius: 50%;
                    border-top-color: white;
                    animation: spin 1s ease-in-out infinite;
                }

                .status-message {
                    font-weight: 500;
                }
            }
        }
    }

    .note {
        color: #dc7f33;
    }

    .manual {
        margin-top: 12px;
        padding: 12px 18px;
        border-radius: 8px;
        background: #0087cc1a;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        &.blurred-content {
            filter: blur(4px);
            pointer-events: none; // Disable interactions when blurred
        }

        span {
            color: #0087cc;
        }

        button {
            color: #0087cc;

            &:focus {
                outline: none;
            }
        }
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 425px) {
    .qr-container {
        .note {
            text-align: center;
        }

        .manual {
            text-align: center;
            gap: 0;
        }
    }
}
</style>
