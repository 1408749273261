<template>
    <div class="ambassador-commission-container">
        <div class="commission-header">
            {{ $t('ambassadorCommissionHistory') }}
        </div>
        <div class="base-table">
            <div v-if="dataCommission.length > 0">
                <b-table
                    :fields="fields"
                    :items="dataCommission"
                    class="ambassador-commission-table"
                    hover
                    responsive
                    striped
                >
                    <template #empty>
                        <h4 class="text-center my-3">
                            {{ $t('noData') }}
                        </h4>
                    </template>

                    <template #head()="{ label }">
                        {{ $t(label) }}
                    </template>
                </b-table>
            </div>
            <div v-else>
                <b-table
                    :fields="fields"
                    :items="dataCommission"
                    class="ambassador-commission-table"
                    hover
                    responsive
                    show-empty
                    striped
                >
                    <template #head()="{ label }">
                        {{ $t(label) }}
                    </template>

                    <template #empty>
                        <div class="no-data text-center">
                            {{ $t('noData') }}
                        </div>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AmbassadorCommissionHistory',
    data() {
        return {
            fields: [
                {
                    key: 'from',
                    label: 'from',
                    thClass: 'text-center text-white',
                    tdClass: 'text-center',
                },
                {
                    key: 'amount',
                    label: 'amount',
                    thClass: 'text-center text-white',
                    tdClass: 'text-center',
                },
                {
                    key: 'currency',
                    label: 'currency',
                    thClass: 'text-center text-white',
                    tdClass: 'text-center',
                },
                {
                    key: 'comment',
                    label: 'comment',
                    thClass: 'text-center text-white',
                    tdClass: 'text-center',
                },
                {
                    key: 'time',
                    label: 'time',
                    thClass: 'text-center text-white',
                    tdClass: 'text-center',
                },
                {
                    key: 'status',
                    label: 'status',
                    thClass: 'text-center text-white',
                    tdClass: 'text-center',
                },
            ],
            dataCommission: [],
        };
    },
};
</script>

<style scoped lang="scss">
.ambassador-commission-container {
    margin-top: 32px;
    padding: 16px 20px;

    .commission-header {
        font-size: 24px;
        font-weight: bold;
        color: #107fb9;
        margin-bottom: 8px;
    }
}
</style>
