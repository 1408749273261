const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        mysteryBoxList: undefined,
        history: undefined,
    }),
    getters: {
        MysteryBoxList: (state) => state.mysteryBoxList,
        History: (state) => state.history,
    },
    actions: {
        async getMysteryBox({ commit }) {
            return axios.get('mystery-box').then((res) => {
                if (res?.status) {
                    commit('SET_MYSTERY_BOX', res.data);
                }
            });
        },
        async getBlindBoxHistory({ commit }, payload) {
            return axios
                .get('mystery-box/history', { params: payload })
                .then((res) => {
                    if (res?.status) {
                        commit('SET_HISTORY', res.data);
                    }
                });
        },
    },
    mutations: {
        SET_MYSTERY_BOX(state, data) {
            state.mysteryBoxList = data;
        },
        SET_HISTORY(state, data) {
            state.history = data;
        },
    },
};
