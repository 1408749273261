// src/components/MysteryBox/services/lighting.service.js
import * as THREE from 'three';

/**
 * Setup optimized lighting for the scene
 *
 * @param {THREE.Scene} scene - Three.js scene
 * @returns {Object} - Object containing references to all lights
 */
export const setupLights = (scene) => {
    // Ambient light - increase intensity significantly
    const ambientLight = new THREE.AmbientLight(0x087f5b, 0.8);
    scene.add(ambientLight);

    // Primary directional light - increase intensity and adjust position
    const directionalLight = new THREE.DirectionalLight(0xe3fafc, 2.2);
    directionalLight.position.set(25, 25, 25);
    directionalLight.castShadow = true;

    // Improve directional light shadows
    directionalLight.shadow.mapSize.width = 2048;
    directionalLight.shadow.mapSize.height = 2048;
    directionalLight.shadow.camera.near = 5.5;
    directionalLight.shadow.camera.far = 25;
    directionalLight.shadow.bias = -0.0001;

    scene.add(directionalLight);

    // Rim lights - increase intensity
    const rimLight1 = new THREE.PointLight(0xffeedd, 1.8, 10);
    rimLight1.position.set(-2, 2, -3);
    scene.add(rimLight1);

    const rimLight2 = new THREE.PointLight(0xffeedd, 1.8, 10);
    rimLight2.position.set(4, 2, -3);
    scene.add(rimLight2);

    const rimLight3 = new THREE.PointLight(0xeeeeff, 1.5, 10);
    rimLight3.position.set(3, 2, 3);
    scene.add(rimLight3);

    const rimLight4 = new THREE.PointLight(0xeeeeff, 1.5, 10);
    rimLight4.position.set(-2, 1.5, 3);
    scene.add(rimLight4);

    const bottomLight = new THREE.PointLight(0x8888ff, 0.8, 10);
    bottomLight.position.set(0, -2, 0);
    scene.add(bottomLight);

    // Return references to all lights
    return {
        ambientLight,
        directionalLight,
        rimLights: [rimLight1, rimLight2, rimLight3, rimLight4, bottomLight],
    };
};
