<template>
    <div v-if="list && list.length === 0" class="empty">
        <EmptyIcon />
        <p>{{ $t('noData') }}</p>
    </div>
</template>

<script>
import EmptyIcon from '../icons/EmptyIcon.vue';

export default {
    components: { EmptyIcon },
    props: {
        list: Array,
    },
};
</script>
<style>
.empty {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
