<template>
    <div class="buy-xnode">
        <div class="tab-buyxnode">
            <b-button
                size="sm"
                :class="`px-2 py-0 custom-button-buyxnode ${
                    tab === 'package' ? 'active' : ''
                }`"
                @click="onChangeTab('package')"
            >
                Package
            </b-button>
            <b-button
                v-if="IsMerchant"
                size="sm"
                :class="`px-2 py-0 custom-button-buyxnode ${
                    tab === 'manage' ? 'active' : ''
                }`"
                @click="onChangeTab('manage')"
            >
                Manage
            </b-button>
        </div>

        <PackageMerchant v-if="tab === 'package'" />
        <ManageMerchant v-else-if="tab === 'manage'" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PackageMerchant from '../../components/corpo/PackageMerchant.vue';
import ManageMerchant from '../../components/corpo/ManageMerchant.vue';

export default {
    name: 'BuyXnode',
    components: { PackageMerchant, ManageMerchant },
    data() {
        return {
            promotion: null,
            page: 1,
            tab: 'package',
        };
    },

    computed: {
        ...mapGetters({
            NodeList: 'mining/NodeList',
            Rates: 'wallet/Rates',
            CorpoNodes: 'mining/CorpoNodes',
            NodePromo: 'promotion/NodePromo',
            MyMerchantStatical: 'merchant/MyMerchantStatical',
            IsMerchant: 'merchant/IsMerchant',
        }),
        ComputedCorpos() {
            return this.CorpoNodes;
        },
    },

    methods: {
        onChangeTab(tab) {
            this.tab = tab;
        },
    },

    watch: {
        IsMerchant(isMerchant) {
            if (isMerchant) {
                this.$store.dispatch('merchant/getStatical');
                this.$store.dispatch('merchant/getHistoryScan', {
                    page: 1,
                    limit: 10,
                });
            }
        },
    },

    mounted() {
        this.$store.dispatch('voucher/filteredUserVoucher', {
            page: this.page,
            type: 'DISCOUNT_BUY_NODE',
        });
        this.$store.dispatch('voucher/userVoucher', { page: this.page });
        this.$store.dispatch('mining/businessNode');

        if (this.IsMerchant) {
            this.$store.dispatch('merchant/getStatical');
            this.$store.dispatch('merchant/getHistoryScan', {
                page: 1,
                limit: 10,
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.buy-xnode {
    margin: 2rem;
    margin-top: 1rem;

    .tab-buyxnode {
        display: flex;
        gap: 16px;

        .custom-button-buyxnode {
            padding: 8px 16px !important;
            background-color: transparent;
            color: #8294a7;
            border-color: #8294a7;
            transition: all 0.1s ease-in-out;
            font-size: 16px;
            font-weight: 600;

            &:hover {
                color: #107fb9;
                border-color: #107fb9;
            }
        }

        .custom-button-buyxnode.active {
            background-color: #107fb9;
            border-color: #107fb9;
            color: white;
        }
    }
}

@media (max-width: 425px) {
    .buy-xnode {
        margin: 12px;
    }
}
</style>
