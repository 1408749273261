// src/components/MysteryBox/services/three.service.js
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import gsap from 'gsap';
import { handleInteractionClick } from '@/components/shared/MysteryBox/services/interaction.service';
import BoxModel from '../models/box.model';
import PlatformModel from '../models/platform.model';
import { setupLights } from './lighting.service';
import {
    adjustElementsForScreenSize,
    handleScreenResize,
} from '../utils/responsive.utils';

export default class ThreeService {
    constructor(containerRef, options = {}) {
        this.containerRef = containerRef;
        this.options = options;

        // Three.js properties
        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.controls = null;
        this.clock = null;

        // Models
        this.boxModel = null;
        this.platformModel = null;

        // Animation properties
        this.animationFrame = null;
        this.lidOpeningStartTime = 0;
        this.lidOpeningDuration = 2.2;
        this.particleStartTime = 0;
        this.particleEmitDuration = 3;

        this.autoRotationEnabled = true;
        this.rotationSpeed = 0.5; // You can adjust this value to control rotation speed
        this.rotationAxis = { x: false, y: true, z: false };

        // Raycaster for click detection
        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2();

        // Initialize the 3D scene
        this.initThreeJS();
    }

    async initThreeJS() {
        // Create scene
        this.scene = new THREE.Scene();
        const showBackground =
            this.options.showBackground !== undefined
                ? this.options.showBackground
                : true;

        // Handle fog visibility (default to true if not specified)
        const showFog =
            this.options.showFog !== undefined ? this.options.showFog : true;

        // Set background if enabled
        if (showBackground) {
            this.scene.background = new THREE.Color(0x050510);
        }

        // Set fog if both background and fog are enabled
        if (showBackground && showFog) {
            this.scene.fog = new THREE.FogExp2(0x070720, 0.0008);
        }

        // Setup camera
        this.camera = new THREE.PerspectiveCamera(
            60,
            this.containerRef.clientWidth / this.containerRef.clientHeight,
            0.1,
            1000,
        );
        this.camera.position.set(0, 1.5, 4);
        this.camera.lookAt(0, 0, 0);

        // Setup renderer with higher quality settings
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            powerPreference: 'high-performance',
            precision: 'highp',
            premultipliedAlpha: false,
        });
        this.renderer.setSize(
            this.containerRef.clientWidth,
            this.containerRef.clientHeight,
        );
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.renderer.physicallyCorrectLights = true;
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this.renderer.toneMappingExposure = 1.0;
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.renderer.localClippingEnabled = true;

        // Add the renderer to the DOM
        this.containerRef.appendChild(this.renderer.domElement);

        // Add click event listener to the renderer
        this.renderer.domElement.addEventListener(
            'click',
            this.handleBoxClick.bind(this),
        );

        // Setup lights
        this.lights = setupLights(this.scene);

        // Create the box model
        // this.boxModel = new BoxModel(this.scene);
        this.boxModel = new BoxModel(this.scene, {
            boxSize: this.options.boxSize || 1.4,
            boxPositionY: this.options.boxPositionY || 0.1,
        });

        // // Create the platform
        // this.platformModel = new PlatformModel(this.scene);
        // Check if platform should be shown (default to true if not specified)
        const showPlatform =
            this.options.showPlatform !== undefined
                ? this.options.showPlatform
                : true;

        // Create the platform only if it should be shown
        if (showPlatform) {
            this.platformModel = new PlatformModel(this.scene);
        } else {
            // Create an empty platform model with required methods to avoid errors
            this.platformModel = {
                update: () => {},
                dispose: () => {},
            };
        }

        // Add orbit controls
        this.controls = new OrbitControls(
            this.camera,
            this.renderer.domElement,
        );
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.05;
        this.controls.minDistance = 3;
        this.controls.maxDistance = 8;
        this.controls.maxPolarAngle = Math.PI / 2;

        // Set up clock for animations
        this.clock = new THREE.Clock();

        // Adjust for screen size
        adjustElementsForScreenSize(
            this.containerRef.clientWidth,
            this.containerRef.clientHeight,
            this.boxModel.boxGroup,
        );

        // Create a cube environment map for reflections
        const cubeRenderTarget = new THREE.WebGLCubeRenderTarget(256, {
            format: THREE.RGBFormat,
            generateMipmaps: true,
            minFilter: THREE.LinearMipmapLinearFilter,
            encoding: THREE.sRGBEncoding,
        });

        // Create a cube camera for environment mapping
        this.cubeCamera = new THREE.CubeCamera(0.1, 1000, cubeRenderTarget);
        this.cubeCamera.position.set(0, 0, 0);
        this.scene.add(this.cubeCamera);

        // Start animation loop
        this.animate();

        // Enhanced reveal animation
        this.animateReveal();

        // Notify that scene is initialized
        if (this.options.onSceneInitialized) {
            this.options.onSceneInitialized();
        }
    }

    animateReveal() {
        gsap.from(this.boxModel.boxGroup.position, {
            y: -3,
            duration: 1.8,
            ease: 'elastic.out(1, 0.5)',
        });

        // Also add a rotation effect
        gsap.from(this.boxModel.boxGroup.rotation, {
            y: Math.PI * 2,
            duration: 2.2,
            ease: 'power2.out',
        });

        // Dynamic camera movement
        gsap.to(this.camera.position, {
            x: 1.8,
            y: 2.2,
            z: 4.2,
            duration: 2.5,
            ease: 'power2.inOut',
        });
    }

    animate() {
        this.animationFrame = requestAnimationFrame(this.animate.bind(this));

        const delta = this.clock.getDelta();
        const elapsed = this.clock.getElapsedTime();

        if (this.autoRotationEnabled && !this.boxModel.isOpen) {
            // Replace the single line below:
            // this.boxModel.boxGroup.rotation.y += delta * this.rotationSpeed;

            // With this code to allow rotation on multiple axes:
            if (this.rotationAxis) {
                if (this.rotationAxis.x)
                    this.boxModel.boxGroup.rotation.x +=
                        delta * this.rotationSpeed;
                if (this.rotationAxis.y)
                    this.boxModel.boxGroup.rotation.y -=
                        delta * this.rotationSpeed;
                if (this.rotationAxis.z)
                    this.boxModel.boxGroup.rotation.z +=
                        delta * this.rotationSpeed;
            } else {
                // Default to y-axis rotation if no axis specified
                this.boxModel.boxGroup.rotation.y += delta * this.rotationSpeed;
            }
        }

        // Update box model animations
        this.boxModel.update(elapsed, delta, this.clock);

        // Update platform animations
        this.platformModel.update(elapsed);

        // Update controls
        this.controls.update();

        // Update environment map for reflections
        this.updateEnvironmentMap();

        // Render scene
        this.renderer.render(this.scene, this.camera);
    }

    updateEnvironmentMap() {
        if (
            this.cubeCamera &&
            this.boxModel.boxLid &&
            this.boxModel.boxBottom
        ) {
            // Temporarily hide the box parts to capture environment
            this.boxModel.boxLid.visible = false;
            this.boxModel.boxBottom.visible = false;

            if (this.boxModel.innerBoxLid)
                this.boxModel.innerBoxLid.visible = false;

            // Hide all inner box components
            if (this.boxModel.innerBoxBottom) {
                if (
                    typeof this.boxModel.innerBoxBottom === 'object' &&
                    !Array.isArray(this.boxModel.innerBoxBottom)
                ) {
                    // For the hollow box implementation
                    Object.values(this.boxModel.innerBoxBottom).forEach(
                        (part) => {
                            if (part) part.visible = false;
                        },
                    );
                } else {
                    // For the original implementation
                    this.boxModel.innerBoxBottom.visible = false;
                }
            }

            // Update the environment map
            this.cubeCamera.update(this.renderer, this.scene);

            // Show the box parts again
            this.boxModel.boxLid.visible = true;
            this.boxModel.boxBottom.visible = true;

            if (this.boxModel.innerBoxLid)
                this.boxModel.innerBoxLid.visible = true;

            // Show all inner box components
            if (this.boxModel.innerBoxBottom) {
                if (
                    typeof this.boxModel.innerBoxBottom === 'object' &&
                    !Array.isArray(this.boxModel.innerBoxBottom)
                ) {
                    // For the hollow box implementation
                    Object.values(this.boxModel.innerBoxBottom).forEach(
                        (part) => {
                            if (part) part.visible = true;
                        },
                    );
                } else {
                    // For the original implementation
                    this.boxModel.innerBoxBottom.visible = true;
                }
            }
        }
    }

    handleBoxClick(event) {
        // Skip if box is already open
        if (this.boxModel.isOpen) return;

        // Calculate mouse position in normalized device coordinates
        const rect = this.renderer.domElement.getBoundingClientRect();
        this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

        // Update the raycaster
        this.raycaster.setFromCamera(this.mouse, this.camera);

        // Check for intersections with the box
        const intersects = this.raycaster.intersectObject(
            this.boxModel.boxGroup,
            true,
        );

        // If the box was clicked, notify callback
        if (intersects.length > 0 && this.options.onBoxClick) {
            this.options.onBoxClick();
        }
    }

    openBox() {
        // // Set the start time for lid opening animation
        // this.lidOpeningStartTime = this.clock.getElapsedTime();
        // Set the start time for lid opening animation
        const currentTime = this.clock.getElapsedTime();
        this.boxModel.startOpeningAnimation(currentTime);

        // Tell the box model it's open
        this.boxModel.isOpen = true;

        // Start camera animation for opening
        gsap.to(this.camera.position, {
            x: 0.5,
            y: 4.5,
            z: 4.5,
            duration: 2.5,
            ease: 'sine.inOut',
        });

        // Add slight camera rotation for better cinematic effect
        gsap.to(this.controls.target, {
            y: 0.8,
            duration: 2,
            ease: 'sine.inOut',
        });
    }

    onWindowResize() {
        // Get container and calculate dimensions
        const width = this.containerRef.clientWidth;
        const height = this.containerRef.clientHeight;

        // Update camera and renderer
        handleScreenResize(width, height, this.camera, this.renderer);

        // Adjust elements based on screen size
        adjustElementsForScreenSize(width, height, this.boxModel.boxGroup);
    }

    cleanup() {
        // Stop animation loop
        if (this.animationFrame) {
            cancelAnimationFrame(this.animationFrame);
        }

        // Remove event listeners
        if (this.renderer && this.renderer.domElement) {
            this.renderer.domElement.removeEventListener(
                'click',
                this.handleBoxClick,
            );
        }

        // Clear scene if necessary
        if (
            this.renderer &&
            this.containerRef.contains(this.renderer.domElement)
        ) {
            this.containerRef.removeChild(this.renderer.domElement);
        }

        // Dispose of resources
        this.boxModel.dispose();
        this.platformModel.dispose();

        // Clear references
        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.controls = null;
        this.boxModel = null;
        this.platformModel = null;
    }

    handleInteractionClick(event) {
        // Only proceed if we have initialized everything
        if (
            !this.renderer ||
            !this.camera ||
            !this.boxModel ||
            !this.boxModel.boxGroup
        ) {
            return;
        }

        // Use the imported function with proper parameters
        const wasHandled = handleInteractionClick(
            event,
            this.renderer,
            this.camera,
            this.boxModel.boxGroup,
            {
                isBoxOpened: this.boxModel.isOpen,
                onBoxClick: this.options.onBoxClick,
            },
        );

        // eslint-disable-next-line consistent-return
        return wasHandled;
    }

    // Add this method to your ThreeService class

    resetBox() {
        // Reset animation properties
        this.lidOpeningStartTime = 0;
        this.particleStartTime = 0;

        // Reset box state if the model exists
        if (this.boxModel) {
            // Reset the box to closed state
            this.boxModel.isOpen = false;

            // Reset box position and rotation
            // this.boxModel.boxGroup.position.set(
            //     0,
            //     this.boxModel.initialBoxPosition || 0.1,
            //     0,
            // );
            this.boxModel.boxGroup.position.set(
                0,
                this.boxModel.initialBoxPosition,
                0,
            );
            this.boxModel.boxGroup.rotation.set(0, 0, 0);

            // Reset lid position and rotation
            if (this.boxModel.boxLid) {
                this.boxModel.boxLid.rotation.set(0, 0, 0);

                // Calculate and reset lid position based on box dimensions
                const boxSize = 1.4;
                const totalHeight = boxSize;
                const topHeight = totalHeight * 0.15;
                const bottomHeight = totalHeight * 0.85;

                this.boxModel.boxLid.position.set(
                    0,
                    bottomHeight + topHeight / 2,
                    0,
                );
            }

            // Reset inner lid position and rotation
            if (this.boxModel.innerBoxLid) {
                this.boxModel.innerBoxLid.rotation.set(0, 0, 0);

                const boxSize = 1.4;
                const totalHeight = boxSize;
                const topHeight = totalHeight * 0.15;
                const bottomHeight = totalHeight * 0.85;

                this.boxModel.innerBoxLid.position.set(
                    0,
                    bottomHeight + topHeight / 2,
                    0,
                );
            }

            // Reset inner glow
            if (this.boxModel.innerGlow && this.boxModel.innerGlow.material) {
                this.boxModel.innerGlow.material.opacity = 0;
            }

            // Reset point light
            if (this.boxModel.pointLight) {
                this.boxModel.pointLight.intensity = 0;
            }

            // Reset hex patterns visibility
            if (this.boxModel.hexPatterns) {
                this.boxModel.hexPatterns.forEach((pattern) => {
                    if (pattern) pattern.visible = true;
                });
            }

            // Reset question marks
            if (this.boxModel.questionMarks) {
                Object.values(this.boxModel.questionMarks).forEach((mark) => {
                    if (mark) {
                        mark.scale.set(1, 1, 1);
                        if (mark.material) mark.material.opacity = 1;
                    }
                });
            }

            // Re-enable bouncing
            this.boxModel.bouncingEnabled = true;

            // Reset lid opening animation time in the box model
            this.boxModel.lidOpeningStartTime = 0;

            // Enable auto rotation again
            this.autoRotationEnabled = true;
        }

        // Reset camera position
        if (this.camera) {
            this.camera.position.set(0, 1.5, 4);
            this.camera.lookAt(0, 0, 0);
        }

        // Reset controls target
        if (this.controls) {
            this.controls.target.set(0, 0, 0);
            this.controls.update();
        }

        // Animate reveal again
        this.animateReveal();
    }
}
