<template>
    <svg
        class="star-svg"
        :class="{ active }"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        :style="{ '--duration': `${duration}s`, '--delay': `${delay}s` }"
    >
        <path
            fill="currentColor"
            d="M9.153 5.408C10.42 3.136 11.053 2 12 2s1.58 1.136 2.847 3.408l.328.588c.36.646.54.969.82 1.182s.63.292 1.33.45l.636.144c2.46.557 3.689.835 3.982 1.776c.292.94-.546 1.921-2.223 3.882l-.434.507c-.476.557-.715.836-.822 1.18c-.107.345-.071.717.001 1.46l.066.677c.253 2.617.38 3.925-.386 4.506s-1.918.051-4.22-1.009l-.597-.274c-.654-.302-.981-.452-1.328-.452s-.674.15-1.328.452l-.596.274c-2.303 1.06-3.455 1.59-4.22 1.01c-.767-.582-.64-1.89-.387-4.507l.066-.676c.072-.744.108-1.116 0-1.46c-.106-.345-.345-.624-.821-1.18l-.434-.508c-1.677-1.96-2.515-2.941-2.223-3.882S3.58 8.328 6.04 7.772l.636-.144c.699-.158 1.048-.237 1.329-.45s.46-.536.82-1.182z"
        />
    </svg>
</template>

<script>
export default {
    name: 'AnimatableStar',
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        delay: {
            type: Number,
            default: 0.6,
        },
        duration: {
            type: Number,
            default: 0.6,
        },
    },
};
</script>

<style lang="scss" scoped>
.star-svg {
    filter: grayscale(1);
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    animation: fade-appear var(--duration) var(--delay) forwards;
    &.active {
        animation: active-star var(--duration) var(--delay) forwards;
    }
    @keyframes fade-appear {
        0% {
            transform: scale(0);
            opacity: 0;
        }
        16% {
            transform: scale(1.2);
            opacity: 1;
        }
        28% {
            transform: scale(0.8);
        }
        44% {
            transform: scale(1.05);
        }
        59% {
            transform: scale(0.97);
        }
        73% {
            transform: scale(1.01);
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
    @keyframes active-star {
        50% {
            transform: rotate(180deg) scale(0.4);
        }
        100% {
            transform: rotate(360deg) scale(1);
            filter: none;
        }
    }
}
</style>
