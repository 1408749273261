<template>
    <div class="esim-container">
        <!-- <h4>XNode / <span>CHOOSE AREA CODE</span></h4> -->
        <EsimPlan />
        <div class="heading-container">
            <router-link :to="{ name: 'LocalEsim' }" class="head">
                {{ $t('localEsim') }}
            </router-link>
            /
            <router-link :to="{ name: 'RegionalEsim' }" class="head">
                {{ $t('regionalEsim') }}
            </router-link>
            /
            <router-link :to="{ name: 'GlobalEsim' }" class="head">
                {{ $t('globalEsim') }}
            </router-link>
        </div>
        <keep-alive max="5">
            <router-view :key="$route.fullPath"></router-view>
        </keep-alive>
        <EsimHistory />
    </div>
</template>

<script>
import EsimHistory from './Esim/EsimHistory.vue';
import EsimPlan from './EsimPlan.vue';

export default {
    components: {
        EsimHistory,
        EsimPlan,
    },
    mounted() {
        this.$store.dispatch('nft/getEsimHistory');
    },
};
</script>

<style lang="scss" scoped>
.esim-container {
    h4 {
        font-size: 16px;
        color: #c0c0c0;
        font-weight: 600;

        span {
            color: #0087cc;
        }
    }

    .heading-container {
        display: flex;
        align-items: center;
        color: #c0c0c0;
        gap: 16px;

        .head {
            font-weight: 600;
            cursor: pointer;
        }

        .head.active {
            color: #0087cc;
        }
    }

    @media (max-width: 414px) {
        .heading-container {
            gap: unset;
            justify-content: space-between;

            a {
                font-size: 14px;
            }
        }
    }
}
</style>
