// src/components/MysteryBox/index.vue
<template>
    <!-- src/components/MysteryBox/MysteryBoxTemplate.vue -->
    <div class="mystery-box-container">
        <!-- Initial button to reveal the 3D box -->
        <box-reveal-button :class="{ hide: isBoxVisible }" @click="revealBox" />

        <!-- 3D scene container -->
        <div
            ref="threeContainer"
            :class="{ visible: isBoxVisible }"
            class="three-container"
        >
            <!-- Holographic effect overlay -->
            <div class="holo-overlay">
                <div class="holo holo-1"></div>
                <div class="holo holo-2"></div>
                <div class="holo holo-3"></div>
                <div class="holo holo-4"></div>
                <div class="holo holo-5"></div>
                <div class="holo holo-6"></div>
            </div>

            <!-- Floating particles background -->
            <div class="particles-background"></div>

            <!-- Clickable area for opening the box -->
            <div
                v-if="isBoxVisible && !isBoxOpened"
                class="click-interaction-area"
                @click="handleInteractionClick"
            >
                <!-- Loading state overlay -->
                <loading-overlay v-if="isLoading" />

                <!-- Error message overlay -->
                <error-message
                    v-else-if="errorMessage"
                    :message="errorMessage"
                    @retry="openBox"
                />

                <!-- Regular instruction when not loading -->
                <div v-else class="instruction-text">{{ $t('clickBoxToOpen') }}</div>

                <button class="close-button" @click.stop="closeBox">
                    {{ $t('close') }}
                </button>
            </div>

            <!-- Success message, items notification and close button -->
            <div v-if="isBoxOpened" class="overlay-message">
                <item-notification
                    v-if="showItemsNotification"
                    :gift="giftOpened"
                />

                <success-message />

                <button class="close-button animated" @click="closeBox">
                    {{ $t('close') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ThreeService from '@/components/shared/MysteryBox/services/three.service';
import { initializeTouchSupport } from '@/components/shared/MysteryBox/utils/touch.utils';
import BoxRevealButton from './components/BoxRevealButton.vue';
import LoadingOverlay from './components/LoadingOverlay.vue';
import ErrorMessage from './components/ErrorMessage.vue';
import SuccessMessage from './components/SuccessMessage.vue';
import ItemNotification from './components/ItemNotification.vue';

export default {
    name: 'NFTMysteryBox',

    components: {
        BoxRevealButton,
        LoadingOverlay,
        ErrorMessage,
        SuccessMessage,
        ItemNotification,
    },

    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },

    data() {
        return {
            isBoxVisible: false,
            isBoxOpened: false,
            isLoading: false,
            errorMessage: null,
            showItemsNotification: false,
            threeService: null,
            resizeTimeout: null,
            isThreeJsInitialized: false,
        };
    },

    computed: {
        ...mapGetters('mining', ['NodeList']),
        ...mapGetters({
            giftOpened: 'mining/GiftOpenedBox',
        }),
    },

    methods: {
        ...mapActions('mining', ['postOpenMysteryBox']),
        initializeThreeJs() {
            this.threeService = new ThreeService(this.$refs.threeContainer, {
                onBoxClick: this.handleBoxClick,
                onSceneInitialized: () => {
                    this.$emit('box-revealed', this.id);
                    this.isThreeJsInitialized = true;
                },
            });

            // Initialize touch support
            initializeTouchSupport(this.$refs.threeContainer, {
                onBoxClick: this.openBox,
                onClose: this.closeBox,
            });
        },
        handleInteractionClick(event) {
            // Only proceed if threeService is initialized
            if (!this.threeService || !this.threeService.renderer) {
                console.warn('Three.js not initialized yet');
                return;
            }

            // Continue with normal handling
            this.threeService.handleInteractionClick(event);
            // handleInteractionClick(event);
        },
        revealBox() {
            this.isBoxVisible = true;
            document.body.style.overflow = 'hidden';

            if (!this.isThreeJsInitialized) {
                // Initialize Three.js service only if not already initialized
                this.initializeThreeJs();
            } else {
                // Just reset and show the existing scene
                this.threeService.resetBox();
                this.$emit('box-revealed', this.id);
            }
        },

        async openBox(event) {
            // If this was triggered by a click on the close button, do nothing
            if (event && event.target.classList.contains('close-button')) {
                return;
            }

            // Only proceed if the box is not already opened
            if (this.isBoxOpened || this.isLoading) return;
            this.isLoading = true;

            try {
                const res = await this.postOpenMysteryBox(this.id);

                if (!res) {
                    throw new Error('Open box failed');
                }

                // Mark box as opened and trigger animations
                this.isBoxOpened = true;
                this.threeService.openBox();

                // Show items notification after delay
                setTimeout(() => {
                    this.showItemsNotification = true;
                }, 1800);

                this.$emit('box-opened');
            } catch (e) {
                this.errorMessage = 'Can not open box';
                this.$emit('box-error', { id: this.id, error: 'Error' });
            } finally {
                this.isLoading = false;
            }
        },

        closeBox() {
            if (this.threeService) {
                // Just reset the box state instead of full cleanup
                this.threeService.resetBox();
            }

            this.isBoxVisible = false;
            this.isBoxOpened = false;
            this.showItemsNotification = false;
            document.body.style.overflow = '';

            this.$emit('box-closed', this.id);
        },

        handleBoxClick() {
            if (!this.isBoxOpened) {
                this.openBox();
            }
        },

        handleResizeWithDebounce() {
            if (this.resizeTimeout) {
                clearTimeout(this.resizeTimeout);
            }

            this.resizeTimeout = setTimeout(() => {
                if (this.threeService) {
                    this.threeService.onWindowResize();
                }
            }, 250);
        },
    },

    mounted() {
        window.addEventListener('resize', this.handleResizeWithDebounce);

        setTimeout(() => {
            this.initializeThreeJs();
        }, 100);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResizeWithDebounce);

        if (this.threeService) {
            this.threeService.cleanup();
            this.threeService = null;
        }
    },
};
</script>

<style scoped src="./styles/mysteryBox.css"></style>

<style lang="scss" scoped>
.mystery-box-container {
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
}
</style>
