<template>
    <div class="map-container container pt-5">
        <div class="filter">
            <div class="filter-wrap">
                <div class="branch">{{ $t('xins-system') }}</div>
                <div class="category" v-if="CategoryList">
                    <div
                        v-for="item in CategoryList.results"
                        :key="item.id"
                        @click="selectedAddress(item.id)"
                        :class="{ active: selectedId === item.id }"
                    >
                        {{ $t(item.slug) }}
                    </div>
                </div>
                <div class="search">
                    <input
                        @input="debouncedInput"
                        type="text"
                        :placeholder="$t('searchByName')"
                    />
                </div>
                <div class="sort">
                    <!-- Sort by province -->
                    <b-form-select
                        v-model="selected.provinceId"
                        :required="true"
                        :placeholder="$t('chooseYourProvince')"
                        @change="handleChangeProvince"
                    >
                        <b-form-select-option
                            v-if="selected.provinceId === 0"
                            :value="0"
                        >
                            {{ $t('chooseYourProvince') }}
                        </b-form-select-option>
                        <b-form-select-option
                            v-for="province in ProvinceList"
                            :key="province.code"
                            :value="province.code"
                            >{{ province.name }}</b-form-select-option
                        >
                    </b-form-select>

                    <!-- Sort by district -->
                    <b-form-select
                        v-model="selected.districtId"
                        :required="true"
                        :placeholder="$t('chooseYourDistrict')"
                        @change="handleChangeDistrict"
                        :disabled="selected.provinceId === 0 ? true : false"
                    >
                        <b-form-select-option
                            v-if="selected.districtId === 0"
                            :value="0"
                        >
                            {{ $t('chooseYourDistrict') }}
                        </b-form-select-option>
                        <b-form-select-option
                            v-for="district in DistrictList"
                            :key="district.code"
                            :value="district.code"
                            >{{ district.name }}</b-form-select-option
                        >
                    </b-form-select>
                </div>
                <div
                    class="locations"
                    v-if="AddressList && AddressList.results"
                >
                    <div
                        class="address-wrap"
                        v-for="(location, index) in AddressList.results"
                        :key="location.id"
                        @click="currentLocation = index"
                    >
                        <div class="tit">
                            <h3 class="label">
                                {{ location.name }}
                            </h3>
                        </div>
                        <div class="address">
                            <img
                                src="../../assets/images/icons/location.svg"
                                alt=""
                            />
                            {{ location.physical_address }}
                        </div>
                        <div class="direction">
                            <a
                                :href="
                                    redirectMap(
                                        location.latitude,
                                        location.longitude,
                                    )
                                "
                                target="_blank"
                            >
                                <img
                                    src="../../assets/images/icons/direction.svg"
                                    alt=""
                                />
                                {{ $t('direction') }}
                            </a>
                            <DetailLocationModal :location="location" />
                        </div>
                    </div>
                    <div
                        class="no-address"
                        v-if="AddressList.results.length < 1"
                    >
                        {{ $t('noDataTryAgain') }}
                    </div>
                </div>
                <div v-else>{{ $t('loading') }}</div>
            </div>
            <!-- <div v-if="CheckMap">
                {{ CheckMap }}
            </div> -->
            <template
                v-if="this.Master !== null || this.MerchantList.length > 0"
            >
                <div class="add-location" v-if="this.CheckMap.length === 0">
                    <div @click="$bvModal.show('add-location')">
                        <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M5.5 12H19.5M12.5 5V19"
                                stroke="#3C3C3C"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <p>{{ $t('addYourLocation') }}</p>
                    </div>
                </div>
                <div class="note" v-else>
                    {{ $t('addressSubmitted') }}
                </div>
            </template>
        </div>
        <div class="map">
            <!-- <MapView
                v-if="AddressList"
                :poi="AddressList.results[currentLocation]"
                :pois="AddressList.results"
                :key="AddressList.results[currentLocation].name"
            /> -->
            <MapView
                v-if="AddressList && AddressList.results[currentLocation]"
                :poi="AddressList.results[currentLocation]"
                :pois="AddressList.results"
                :key="AddressList.results[currentLocation].id"
            />
        </div>
        <AddLocationModal
            :ListCategory="CategoryList"
            :ListProvince="ProvinceList"
            :ListDistrict="DistrictList"
            :ListWard="WardList"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AddLocationModal from '@/components/Map/AddLocationModal.vue';
import DetailLocationModal from '@/components/Map/DetailLocationModal.vue';
import { debounce } from 'lodash';
import MapView from './map/MapView.vue';

export default {
    components: {
        MapView,
        AddLocationModal,
        DetailLocationModal,
    },

    computed: {
        ...mapGetters({
            CategoryList: 'map/CategoryList',
            AddressList: 'map/AddressList',
            ProvinceList: 'map/ProvinceList',
            DistrictList: 'map/DistrictList',
            WardList: 'map/WardList',
            UserInfo: 'info/UserInfo',
            Master: 'info/Master',
            CheckMap: 'map/CheckMap',
        }),
    },

    data() {
        return {
            // locations: [
            //     {
            //         label: 'Enterprise Node Calmete',
            //         address:
            //             '4-8 Đ. Calmette, Phường Nguyễn Thái Bình, Quận 1, Hồ Chí Minh, Việt Nam',
            //         lat: 10.766720057280606,
            //         lng: 106.7005612890853,
            //     },
            //     {
            //         label: 'Enterprise Node Kontum',
            //         address: '446A Nguyễn Huệ, Thống Nhất, Kon Tum, Vietnam',
            //         lat: 14.345927560457309,
            //         lng: 108.01062553524261,
            //     },
            //     {
            //         label: 'Enterprise Node Hà nội',
            //         address:
            //             'A01-L60 An Vượng Villa, Dương Nội, Hà Đông, Hà Nội',
            //         lat: 20.98372620431982,
            //         lng: 105.75402610190959,
            //     },
            // ],
            type: [
                { value: 'Bussiness Node' },
                { value: 'Partnership' },
                { value: 'Master Node' },
            ],
            currentLocation: 0,

            selected: {
                provinceId: 0,
                districtId: 0,
            },

            valueSearch: '',

            types: '66f4df858a8ae458589ba74e',

            selectedId: '66f4df858a8ae458589ba74e',
        };
    },

    methods: {
        async selectedAddress(input) {
            this.selected = {
                provinceId: 0,
                districtId: 0,
            };
            this.selectedId = input;
            this.$store.dispatch('map/getAddressList', {
                category_id: input,
            });
            console.log('my el: ', el);
            el.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        },

        redirectMap(lat, lng) {
            return `https://www.google.com/maps?q=${lat},${lng}`;
        },

        handleChangeProvince(provinceId) {
            if (this.selectedId) {
                this.$store.dispatch('map/getAddressList', {
                    category_id: this.selectedId,
                    provinceId,
                });
            }
        },

        handleChangeDistrict(districtId) {
            this.$store.dispatch('map/getAddressList', {
                districtId,
            });
        },

        handleInput(value) {
            this.valueSearch = value;
        },
    },

    watch: {
        'selected.provinceId': function (newProvinceId, oldProvinceId) {
            if (newProvinceId !== 0 && newProvinceId !== oldProvinceId) {
                this.$store.dispatch('map/getDistrict', newProvinceId);
            }
        },

        'valueSearch': function (newVal) {
            this.$store.dispatch('map/getAddressList', {
                query: newVal,
                category_id: this.selectedId,
            });
        },
    },

    created() {
        this.debouncedInput = debounce((event) => {
            this.handleInput(event.target.value);
        }, 700);
    },

    mounted() {
        this.$store.dispatch('map/getCategoryList');
        this.$store.dispatch('map/getAddressList', {
            category_id: '66f4df858a8ae458589ba74e',
        });
        this.$store.dispatch('map/getMap');
        this.$store.dispatch('map/getProvince');
    },
};
</script>

<style lang="scss" scoped>
.map-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
    height: 100%;

    > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 16px;
    }

    .filter {
        height: 100%;
        width: 415px;

        .filter-wrap {
            border: 1px solid #d8d8d8;
            border-radius: 22px;
            padding: 15px;
            width: 100%;
            .branch {
                font-size: 24px;
                font-weight: 600;
                color: #0087cc;
                margin-bottom: 1rem;
            }

            .category {
                padding-bottom: 10px;

                > div {
                    border: 1px solid #d8d8d8;
                    border-radius: 8px;
                    padding: 9px 10px;
                    transition: 0.3s;
                }
            }

            .search {
                margin: 15px 0;

                input {
                    width: 100%;
                    border: 1px solid #d8d8d8;
                    padding: 7px 10px 7px 40px;
                    border-radius: 8px;
                    outline: none;
                    background-image: url('../../assets/images/icons/search-icon.svg');
                    background-repeat: no-repeat;
                    background-position: 10px;
                }
            }

            .sort {
                display: flex;
                justify-content: space-between;
                gap: 16px;

                select {
                    cursor: pointer;
                }

                .city-dropdown,
                .district-dropdown {
                    width: 180px;
                    border-radius: 8px;
                    border: 1px solid #d8d8d8;
                }
            }
        }

        .add-location {
            div {
                text-align: center;
                justify-content: center;
                align-items: center;
                padding: 9px 10px;
                border: 1px solid #0087cc;
                border-radius: 100px;
                cursor: pointer;
                font-weight: 600;
                display: flex;
                gap: 4px;

                p {
                    margin: 0;
                }
            }
        }

        .note {
            font-size: 18px;
            color: red;
        }
    }

    .locations {
        height: 450px;
        overflow-y: auto;
        margin-top: 1rem;
        padding-right: 15px;

        .address-wrap {
            background: #f2f2f2;
            outline: none;
            padding: 15px;
            position: relative;
            border-radius: 22px;
            margin-bottom: 10px;
            cursor: pointer;

            .tit {
                display: flex;
                align-items: center;
                gap: 5px;

                svg {
                    font-size: 16px;
                }

                h3 {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 0;
                }
            }

            .address {
                margin: 10px 0;
            }

            .direction {
                display: flex;
                justify-content: space-between;

                a {
                    display: inline-flex;
                    align-items: center;
                    gap: 8px;
                    color: #0087cc;
                    font-weight: 500;
                    transition: 0.3s;

                    &::after {
                        margin-top: 1px;
                        content: '';
                        border: solid #0087cc;
                        border-width: 0 2px 2px 0;
                        display: inline-block;
                        padding: 3px;
                        transform: rotate(-45deg);
                    }

                    &:hover {
                        opacity: 0.8;
                        text-decoration: underline;
                    }
                }
            }
        }

        .no-address {
            font-size: 40px;
            color: #0087cc;
            font-weight: 600;
        }
    }

    .category {
        overflow-x: auto;
        display: flex;
        justify-content: space-between;

        > div {
            .icon {
                border-radius: 50%;
                background: #e6e9f0;
                padding: 10px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
            }

            cursor: pointer;
        }

        div {
            white-space: nowrap;
        }
    }

    .map {
        width: 70%;
        height: 100%;
    }

    @media (max-width: 992px) {
        flex-direction: column;
        width: 100%;

        .filter {
            width: 100%;
            height: fit-content !important;
            gap: 12px;

            .filter-wrap {
                width: 100%;
                height: fit-content;
            }
        }

        .locations {
            height: auto;
        }

        .category {
            justify-content: initial;
            gap: 20px;
        }

        .map {
            width: 100%;
        }
    }

    @media (max-width: 769px) {
        display: block;

        .locations {
            height: 250px;
            overflow-y: auto;

            .address-wrap {
                max-width: 100%;
                min-height: 120px;
            }
        }

        .map {
            width: 100%;
            margin-top: 2rem;

            .vue-map-container {
                width: 100% !important;
                min-height: 400px !important;
            }
        }
    }
}

.location-form {
    > div {
        margin-bottom: 15px;

        input {
            width: 100%;
            padding: 0 1rem;
            border: 1px solid #e4e4e4;
            border-radius: 8px;
            height: 40px;
            color: #000;
        }

        input:focus-visible {
            outline: none;
        }

        input::placeholder {
            color: #a6a6a6;
            font-size: 18px;
            font-style: italic;
            font-weight: 400;
            text-align: left;
        }
    }
}

.active {
    background: #0087cc;
    color: #fff;
}
</style>

<style lang="scss">
#dropdown-1 {
    .dropdown-menu {
        height: 400px;
        overflow-y: auto;
    }
}

#add-location {
    justify-content: start;

    .modal-body {
        position: relative;

        svg {
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}
</style>
