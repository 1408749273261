<template>
    <b-modal
        id="blind-box"
        centered
        dialog-class="supa-modal within-screen"
        hide-footer
        hide-header
        @shown="onModalShown"
    >
        <div class="super-height">
            <div class="header">
                {{ $t('blindBox') }}
                <button>
                    <HelpIcon />
                </button>
            </div>
            <div class="blind-box-area">
                <div v-if="MysteryBoxList" class="blind-box-count">
                    <img
                        src="@/assets/images/blind-box-botan.png"
                        width="25px"
                    />
                    x {{ MysteryBoxList.totalResults }}
                </div>
                <MiniMysteryBox
                    :id="1"
                    :auto-initialize="true"
                    :auto-rotate="true"
                    :boxPositionY="-0.5"
                    :boxSize="3"
                    :rotation-speed="0.7"
                    :show-background="false"
                    :show-fog="false"
                    :show-platform="false"
                    @box-initialized="onBoxInitialized"
                />

                <MysteryBox
                    v-if="MysteryBoxList && MysteryBoxList.results[0]"
                    :id="MysteryBoxList.results[0]._id"
                    @box-revealed="onBoxInitialized"
                    @box-opened="onBoxOpened"
                    @box-closed="onBoxClosed"
                    @box-error="onBoxError"
                />
                <!-- <button class="open-botan">
<img
src="@/assets/images/blind-box-botan.png"
width="50px"
/>
{{ $t('openBlindBox') }}
</button> -->
            </div>
            <div class="history-table-area">
                <BlindBoxHistory />
            </div>
        </div>
        <button
            class="close"
            style="color: #0087cc"
            type="button"
            @click="$bvModal.hide('blind-box')"
        >
            <svg
                height="1em"
                viewBox="0 0 24 24"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                    fill="currentColor"
                />
            </svg>
        </button>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import MysteryBox from '@/components/shared/MysteryBox/MysteryBox.vue';
import MiniMysteryBox from '@/components/shared/MysteryBox/MiniMysteryBox.vue';
import HelpIcon from '../icons/HelpIcon.vue'; // import NFTMysteryBoxV2 from '@/components/shared/NFTMysteryBoxV2.vue';
import BlindBoxHistory from './blind-box/BlindBoxHistory.vue';

// import NFTMysteryBoxV2 from '@/components/shared/NFTMysteryBoxV2.vue';

export default {
    name: 'BlindBoxModal',
    components: {
        // NFTMysteryBoxV2,
        HelpIcon,
        MysteryBox,
        MiniMysteryBox,
        BlindBoxHistory,
    },
    data() {
        return {
            blindBoxConfig: {
                id: 1,
                color: '#4dabf7',
                glowColor: '#00e5ff',
                opened: false,
            },
        };
    },
    computed: {
        ...mapGetters({
            MysteryBoxList: 'blindbox/MysteryBoxList',
        }),
    },
    methods: {
        onBoxInitialized() {
            console.log('onBoxInitialized');
        },
        onBoxOpened() {
            console.log('onBoxOpened');
            this.$store.dispatch('blindbox/getMysteryBox');
            this.$store.dispatch('blindbox/getBlindBoxHistory');
        },
        onBoxClosed() {
            console.log('onBoxClosed');
        },
        onBoxError() {
            console.log('onBoxError');
        },
        onModalShown() {
            // Initialize the ThreeJS service when modal is fully visible
            // if (this.$refs.miniMysteryBox) {
            //     this.$nextTick(() => {
            //         this.$refs.miniMysteryBox.manualInitialize();
            //     });
            // }
        },
    },
};
</script>

<style lang="scss" scoped>
.super-height {
    height: 100%;
    display: flex;
    flex-direction: column;

    .header {
        margin-top: -0.5rem;
        padding-bottom: 0.5rem;
        font-size: 20px;
        font-weight: 700;
        color: #1478b0;

        > button {
            color: inherit;
        }
    }

    .blind-box-area,
    .history-table-area {
        flex-grow: 1;
        flex-basis: 10px;
    }

    .blind-box-area {
        margin: 0px -1rem;
        position: relative;
        background: radial-gradient(
            ellipse at bottom,
            #1b2735 0%,
            #090a0f 100%
        );
        padding-bottom: 1rem;

        .blind-box-count {
            background: linear-gradient(
                135deg,
                #0a1a35 0%,
                #1e3a7b 50%,
                #0a1a35 100%
            );
            color: #00ffff;
            border: 1px solid #00ffff;
            border-radius: 4px;
            padding: 4px;
            width: fit-content;
            margin: 8px 0px 0px 8px;
        }

        > img,
        canvas {
            width: 100%;
        }

        .open-botan {
            position: relative;
            display: block;
            margin: auto;
            color: #fff;
            padding-left: 30px;
            background: linear-gradient(
                135deg,
                #0a1a35 0%,
                #1e3a7b 50%,
                #0a1a35 100%
            );
            outline: none;
            color: #00ffff;
            border: 2px solid #00ffff;
            border-radius: 6px;

            > img {
                position: absolute;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 0px;
                width: 50px;
            }
        }
    }

    .history-table-area {
        padding-top: 1rem;
        overflow: hidden;

        
    }
}
</style>
