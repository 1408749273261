// eslint-disable-next-line import/extensions
import rankingData from './utils.js';

const generateStars = (count) => {
    return '⭐'.repeat(count);
};

const formatF1Requirement = (data) => {
    if (
        !data.nOfF1Ambassadors ||
        Object.keys(data.nOfF1Ambassadors).length === 0
    ) {
        return '-';
    }

    const rank = Object.keys(data.nOfF1Ambassadors)[0];
    const count = data.nOfF1Ambassadors[rank];

    // eslint-disable-next-line radix
    return `${count} F1 ${generateStars(parseInt(rank))}`;
};

const formatCurrency = (value) => {
    if (!value || value === 0) {
        return '-';
    }
    return `${value.toLocaleString()}$`;
};

// eslint-disable-next-line import/prefer-default-export
export const formatAmbassadorData = () => {
    return Object.entries(rankingData).map(([rank, data]) => {
        return {
            // eslint-disable-next-line radix
            ambassador: generateStars(parseInt(rank)),
            personalNode: formatCurrency(data.personalBestNodeValue),
            totalStaking: formatCurrency(data.totalStaking),
            f1MonthlyKPIs: formatCurrency(data.f1MonthlyKPIs),
            groupMonthlyKPIs: formatCurrency(data.groupMonthlyKPIs),
            ambassadorF1: formatF1Requirement(data),
        };
    });
};
