// src/components/MysteryBox/components/ErrorMessage.vue
<template>
    <div class="error-overlay">
        <div class="error-icon"></div>
        <div class="error-text">{{ message }}</div>
        <button class="retry-button" @click.stop="$emit('retry')">
            {{ $t('tryAgain') }}
        </button>
    </div>
</template>

<script>
export default {
    name: 'ErrorMessage',
    props: {
        message: {
            type: String,
            default: 'An error occurred',
        },
    },
};
</script>

<style scoped>
.error-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(40, 0, 0, 0.8);
    padding: 20px 30px;
    border-radius: 10px;
    border: 2px solid #ff5555;
    z-index: 20;
}

.error-icon {
    width: 40px;
    height: 40px;
    background-color: #ff5555;
    border-radius: 50%;
    position: relative;
    margin-bottom: 15px;
}

.error-icon:before,
.error-icon:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 4px;
    background-color: white;
    transform: translate(-50%, -50%) rotate(45deg);
}

.error-icon:after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.error-text {
    color: #ff5555;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
    max-width: 250px;
}

.retry-button {
    padding: 8px 20px;
    background-color: rgba(100, 0, 0, 0.7);
    color: white;
    border: 1px solid #ff5555;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.retry-button:hover {
    background-color: rgba(150, 0, 0, 0.9);
    transform: translateY(-2px);
}
</style>
