<template>
    <div>
        <div class="filter">
            <div class="input-search">
                <div class="search">
                    <input
                        @input="debouncedInput"
                        type="text"
                        :placeholder="$t('idOrEmail')"
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        class="magnifying"
                    >
                        <path
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m21 21l-4.343-4.343m0 0A8 8 0 1 0 5.343 5.343a8 8 0 0 0 11.314 11.314"
                        />
                    </svg>
                </div>

                <b-form-datepicker
                    :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                    }"
                    :date-formatter="{
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                    }"
                    :placeholder="$t('startTime')"
                    v-model="params.startDate"
                    class="bg-white custom-select-day"
                    tabindex="2"
                ></b-form-datepicker>

                <b-form-datepicker
                    :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                    }"
                    :placeholder="$t('endTime')"
                    v-model="params.endDate"
                    class="bg-white custom-select-day"
                ></b-form-datepicker>
            </div>

            <div v-if="HistoryScan.results.length > 0" class="export">
                <button
                    @click="handleExport"
                    v-b-tooltip.hover
                    :title="$t('export')"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <g fill="currentColor">
                            <path
                                d="M20 15.25a.75.75 0 0 1 .75.75v1A3.75 3.75 0 0 1 17 20.75H7A3.75 3.75 0 0 1 3.25 17v-.996a.75.75 0 1 1 1.5 0V17A2.25 2.25 0 0 0 7 19.25h10A2.25 2.25 0 0 0 19.25 17v-1a.75.75 0 0 1 .75-.75"
                            />
                            <path
                                d="M12.75 4.5a.75.75 0 0 0-1.5 0v6.97H7.97a.75.75 0 0 0 0 1.06l3.5 3.5a.75.75 0 0 0 1.06 0l3.5-3.5a.75.75 0 0 0 0-1.06h-3.28z"
                            />
                        </g>
                    </svg>
                </button>
            </div>
        </div>

        <b-table
            striped
            hover
            :items="HistoryScan.results"
            :fields="fields"
            show-empty
            responsive
            thead-class="custom-header-scan"
            tbody-class="custom-body-scan"
        >
            <template #empty>
                <h4 class="text-center my-4">{{ $t('noReferral') }}</h4>
            </template>
            <template #head()="{ label }">
                {{ $t(label) }}
            </template>
            <template #cell(percentIncrement)="data">
                <span> {{ Number(data.item.percentIncrement) * 100 }}% </span>
            </template>

            <template #cell(status)="data">
                <div class="status">
                    <span :class="renderClassStatus(data.item.status)">
                        {{
                            data.item.status === 0
                                ? $t('success')
                                : data.item.status === 1
                                ? $t('expired')
                                : data.item.status === 2
                                ? $t('pending')
                                : $t('failed')
                        }}
                    </span>
                </div>
            </template>

            <template #cell(createdAt)="data">
                <span>
                    {{ getDateTime3(data.item.createdAt) }}
                </span>
            </template>

            <template #cell(endDate)="data">
                <span>
                    {{ getDateTime3(data.item.endDate) }}
                </span>
            </template>
        </b-table>

        <EmptyData :list="HistoryScan.result" />

        <div
            class="pagination"
            v-if="HistoryScan && HistoryScan.totalPages > 1"
        >
            <Pagination
                :page="HistoryScan.page"
                :totalPages="HistoryScan.totalPages"
                :handlePrevPage="handlePrevPage"
                :handleNextPage="handleNextPage"
            />
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';
import Pagination from '../marketplace/share/Pagination.vue';
import EmptyData from '../marketplace/share/EmptyData.vue';

export default {
    name: 'ManageMerchant',

    components: {
        Pagination,
        EmptyData,
    },

    data() {
        return {
            fields: [
                {
                    key: 'userId',
                    label: 'userScan',
                    class: 'custom-scan text-center',
                },
                {
                    key: 'email',
                    label: 'Email',
                    class: 'custom-scan text-center',
                },
                {
                    key: 'percentIncrement',
                    sortable: true,
                    label: 'percentIncrement',
                    class: 'custom-scan text-center',
                },
                {
                    key: 'createdAt',
                    sortable: true,
                    label: 'createdAt',
                    class: 'custom-scan text-center',
                },
                {
                    key: 'endDate',
                    sortable: true,
                    label: 'expiredAt',
                    class: 'custom-scan text-center',
                },
                {
                    key: 'status',
                    label: 'status',
                    class: 'custom-scan text-center',
                },
            ],

            params: {
                page: 1,
                limit: 10,
                userId: undefined,
                email: undefined,
                startDate: undefined,
                endDate: undefined,
            },
        };
    },

    methods: {
        renderClassStatus(state) {
            if (state === 0) {
                return 'success';
            }

            if (state === 1) {
                return 'expired';
            }

            if (state === 2) {
                return 'pending';
            }

            return 'cancel';
        },

        handleExport() {
            this.$store.dispatch('merchant/getHistoryScan', {
                ...this.params,
                isExportAll: true,
            });
        },

        handleNextPage() {
            this.$store.dispatch('merchant/getHistoryScan', {
                ...this.params,
                page: this.HistoryScan.page + 1,
            });
        },

        handlePrevPage() {
            this.$store.dispatch('merchant/getHistoryScan', {
                ...this.params,
                page: this.HistoryScan.page - 1,
            });
        },

        handleInput(value) {
            if (value === '') {
                this.params.email = '';
                this.params.userId = '';
                this.$store.dispatch('merchant/getHistoryScan', this.params);
                return;
            }

            const isEmail = value
                .toLowerCase()
                .trim()
                .match(/^[^\s@]+(\+[^\s@]+)?@[^\s@]+\.[^\s@]+$/);

            if (isEmail) {
                this.params.email = value;
            } else {
                this.params.userId = value;
            }
        },
    },

    created() {
        this.debouncedInput = debounce((event) => {
            this.handleInput(event.target.value);
        }, 700);
    },

    computed: {
        ...mapGetters({
            HistoryScan: 'merchant/HistoryScan',
        }),
    },

    watch: {
        'params.email': {
            // eslint-disable-next-line object-shorthand, func-names
            handler: function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.$store.dispatch('merchant/getHistoryScan', {
                        ...this.params,
                        userId: undefined,
                    });
                }
            },
        },

        'params.userId': {
            // eslint-disable-next-line object-shorthand, func-names
            handler: function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.$store.dispatch('merchant/getHistoryScan', {
                        ...this.params,
                        email: undefined,
                    });
                }
            },
        },

        'params.startDate': {
            // eslint-disable-next-line object-shorthand, func-names
            handler: function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    if (this.params.endDate) {
                        this.$store.dispatch('merchant/getHistoryScan', {
                            ...this.params,
                            startDate: newVal,
                            endDate: this.params.endDate,
                        });
                    }
                }
            },
        },

        'params.endDate': {
            // eslint-disable-next-line object-shorthand, func-names
            handler: function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    if (this.params.startDate) {
                        this.$store.dispatch('merchant/getHistoryScan', {
                            ...this.params,
                            startDate: this.params.startDate,
                            endDate: newVal,
                        });
                    }
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.pagination {
    justify-content: end;
}
</style>

<style lang="scss">
.filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    gap: 16px;

    .input-search {
        display: grid;
        gap: 16px;
        align-items: center;
        grid-template-columns: repeat(4, 1fr);

        .search {
            height: 40px;
            position: relative;

            input {
                width: 100%;
                height: 100%;
                padding: 12px;
                padding-left: 36px;
                border: 1px solid #d8d8d8;
                border-radius: 8px;

                &:focus-visible {
                    outline: none;
                }

                &::placeholder {
                    font-size: 16px;
                    color: #667085;
                }
            }

            .magnifying {
                position: absolute;
                left: 3%;
                top: 50%;
                transform: translateY(-50%);
                color: #667085;
            }
        }

        .custom-select-day {
            border-radius: 8px;
            height: 40px;
            border: 1px solid #aaaaaa6b;
            z-index: 1;
        }

        @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 450px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .export {
        display: flex;
        justify-content: end;

        button {
            border: 1px solid #107fb9;
            border-radius: 8px;
            color: #107fb9;
            transition: all 0.2s ease-in-out;
            padding: 2px 6px;

            &:hover {
                background-color: #107fb9;
                color: white;
                transform: scale(0.98);
            }

            &:focus {
                outline: none;
            }
        }
    }
}

.custom-header-scan {
    .custom-scan {
        min-width: 200px;
        border-top: none;
    }
}

.custom-body-scan {
    .custom-scan {
        vertical-align: middle;
    }

    .status {
        display: flex;
        justify-content: center;

        .success,
        .pending,
        .cancel,
        .expired {
            display: flex;
            align-items: center;
            width: 150px;
            border-radius: 9999px;
            height: 36px;
            justify-content: center;
            gap: 12px;
        }

        .expired {
            border: 1px solid #98a6b2;
            background: #ecfdf3;
            color: #6c757d;

            &::before {
                content: '';
                width: 8px;
                height: 8px;
                background-color: #6c757d;
                border-radius: 50%;
                display: inline-block;
            }
        }

        .success {
            border: 1px solid #abefc6;
            background: #ecfdf3;
            color: #067647;

            &::before {
                content: '';
                width: 8px;
                height: 8px;
                background-color: #067647;
                border-radius: 50%;
                display: inline-block;
            }
        }

        .pending {
            border: 1px solid #efd8ab;
            background: #fdf8ec;
            color: #d4a60f;

            &::before {
                content: '';
                width: 10px;
                height: 10px;
                background-color: #d4a60f;
                border-radius: 50%;
                display: inline-block;
            }
        }

        .cancel {
            border: 1px solid #efabab;
            background: #fdecec;
            color: #d51111;

            &::before {
                content: '';
                width: 10px;
                height: 10px;
                background-color: #d51111;
                border-radius: 50%;
                display: inline-block;
            }
        }
    }
}
</style>
