// src/components/MysteryBox/models/platform.model.js
import * as THREE from 'three';
import textureDiffuse from '@/assets/images/texture/diffuse-platform.jpg';
import textureNormal from '@/assets/images/texture/normal.jpg';
import textureRough from '@/assets/images/texture/roughness.jpg';
import textureMetal from '@/assets/images/texture/metalness.jpg';

export default class PlatformModel {
    constructor(scene) {
        this.scene = scene;
        this.hexPatterns = [];

        this.platformTexture = {
            diffuse: null,
            normal: null,
            roughness: null,
            metalness: null,
        };

        this.loadTextures().then(() => {
            // Create the digital platform
            this.createPlatform();
            this.addDataFlowEffects();
        });
    }

    loadTextures() {
        this.textureLoader = new THREE.TextureLoader();
        const promises = [];

        // README: TEXTURE
        const defaultTextures = {
            diffuse: textureDiffuse,
            normal: textureNormal,
            roughness: textureRough,
            metalness: textureMetal,
        };

        // Merge default textures with provided options
        const texturePaths = { ...defaultTextures };

        // Helper function to load a texture
        const loadTexture = (type, path) => {
            if (!path) return Promise.resolve();

            return new Promise((resolve) => {
                this.textureLoader.load(
                    path,
                    (texture) => {
                        texture.wrapS = THREE.RepeatWrapping;
                        texture.wrapT = THREE.RepeatWrapping;
                        texture.repeat.set(1, 1);
                        this.platformTexture[type] = texture;
                        resolve();
                    },
                    undefined,
                    () => {
                        console.warn(`Failed to load ${type} texture: ${path}`);
                        resolve();
                    },
                );
            });
        };

        // Load each texture type if path is provided
        Object.entries(texturePaths).forEach(([type, path]) => {
            if (path) {
                promises.push(loadTexture(type, path));
            }
        });

        return Promise.all(promises);
    }

    createPlatform() {
        // Base platform with hexagonal shape
        const platformGeo = new THREE.CylinderGeometry(2.2, 2.6, 0.2, 6);
        const platformMat = new THREE.MeshStandardMaterial({
            color: 0x868e96,
            roughness: 0.2,
            metalness: 0.9,
            emissive: 0x070720,
            emissiveIntensity: 0.3,
        });

        this.platform = new THREE.Mesh(platformGeo, platformMat);
        this.platform.position.y = -0.15;
        this.platform.receiveShadow = true;
        this.scene.add(this.platform);

        // Add more elaborate glowing edge to platform
        const edgeGeo = new THREE.TorusGeometry(2.2, 0.1, 16, 6);
        edgeGeo.rotateX(Math.PI / 2);

        const edgeMat = new THREE.MeshStandardMaterial({
            roughness: 0.9,
            metalness: 0.1,
            emissiveIntensity: 0.2,
            transparent: true,
            depthWrite: false,
            dithering: true,
        });

        if (this.platformTexture.diffuse) {
            edgeMat.map = this.platformTexture.diffuse;
        }

        if (this.platformTexture.normal) {
            edgeMat.normalMap = this.platformTexture.normal;
            edgeMat.normalScale = new THREE.Vector2(0.5, 0.5);

            edgeGeo.normalMap = this.platformTexture.normal;
            edgeGeo.normalScale = new THREE.Vector2(0.5, 0.5);
        }

        if (this.platformTexture.roughness) {
            edgeMat.roughnessMap = this.platformTexture.roughness;
            edgeGeo.roughnessMap = this.platformTexture.roughness;
        }

        if (this.platformTexture.metalness) {
            edgeMat.metalnessMap = this.platformTexture.metalness;
            edgeGeo.metalnessMap = this.platformTexture.metalness;
        }

        this.edge = new THREE.Mesh(edgeGeo, edgeMat);
        this.edge.position.y = 0;
        this.scene.add(this.edge);
        this.hexPatterns.push(this.edge);

        // Add secondary inner hex
        const innerEdgeGeo = new THREE.TorusGeometry(1.8, 0.06, 16, 6);
        innerEdgeGeo.rotateX(Math.PI / 2);

        this.innerEdge = new THREE.Mesh(innerEdgeGeo, edgeMat);
        this.innerEdge.position.y = 0.02;
        this.scene.add(this.innerEdge);
        this.hexPatterns.push(this.innerEdge);
    }

    addDataFlowEffects() {
        // Create data flow lines that circle the platform
        const createDataFlow = (radius, segments, height, speed, color) => {
            const points = [];

            for (let i = 0; i <= segments; i++) {
                const angle = (i / segments) * Math.PI * 2;
                const x = Math.sin(angle) * radius;
                const z = Math.cos(angle) * radius;
                const y = i % 2 === 0 ? 0 : height;

                points.push(new THREE.Vector3(x, y, z));
            }

            const lineGeo = new THREE.BufferGeometry().setFromPoints(points);
            const lineMat = new THREE.LineBasicMaterial({
                color: color,
                transparent: true,
                opacity: 0.7,
            });

            const line = new THREE.Line(lineGeo, lineMat);
            line.userData = { rotationSpeed: speed };

            this.hexPatterns.push(line);
            return line;
        };

        // Add multiple data flows at different heights, speeds, and colors
        this.flow1 = createDataFlow(2.8, 48, 0.1, 0.001, 0x00ffff);
        this.flow2 = createDataFlow(3.2, 36, 0.2, 0.0015, 0x0088ff);
        this.flow3 = createDataFlow(3.6, 60, 0.15, 0.0008, 0x00ff88);

        this.scene.add(this.flow1);
        this.scene.add(this.flow2);
        this.scene.add(this.flow3);
    }

    update(elapsed) {
        // Animate hex patterns and data flow lines
        this.hexPatterns.forEach((pattern, index) => {
            // Rotate data flow lines
            if (pattern.userData && pattern.userData.rotationSpeed) {
                pattern.rotation.y += pattern.userData.rotationSpeed;
            }
        });

        // Add specific rotation for the hexagonal edges
        if (this.edge) {
            this.edge.rotation.y = elapsed * 0.1; // Outer hexagon rotates at this speed
        }

        // if (this.innerEdge) {
        //     this.innerEdge.rotation.y = -elapsed * 0.2; // Inner hexagon rotates in opposite direction at a different speed
        // }
    }

    dispose() {
        // Remove platform components
        if (this.platform) {
            if (this.platform.geometry) this.platform.geometry.dispose();
            if (this.platform.material) this.platform.material.dispose();
            this.scene.remove(this.platform);
        }

        // Remove edge components
        if (this.edge) {
            if (this.edge.geometry) this.edge.geometry.dispose();
            if (this.edge.material) this.edge.material.dispose();
            this.scene.remove(this.edge);
        }

        if (this.innerEdge) {
            if (this.innerEdge.geometry) this.innerEdge.geometry.dispose();
            if (this.innerEdge.material) this.innerEdge.material.dispose();
            this.scene.remove(this.innerEdge);
        }

        // Remove data flow lines
        this.hexPatterns.forEach((pattern) => {
            if (pattern.geometry) pattern.geometry.dispose();
            if (pattern.material) pattern.material.dispose();
            this.scene.remove(pattern);
        });

        // Clear arrays
        this.hexPatterns = [];
    }
}
