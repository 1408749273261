// eslint-disable-next-line import/no-cycle
import sseService from '@/services/sseService';

const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        merchantStatical: null,
        historyScan: null,
        isMerchant: false,
        sseConnected: false, // Add SSE connection status
    }),
    getters: {
        MyMerchantStatical: (state) => state.merchantStatical,
        HistoryScan: (state) => state.historyScan,
        IsMerchant: (state) => state.isMerchant,
        SSEConnected: (state) => state.sseConnected,
    },
    actions: {
        async post_merchantCode(context, input) {
            return axios.post('merchant/scan', input).then((res) => {
                return res;
            });
        },

        async getStatical({ commit }) {
            return axios.get('merchant/statical').then((res) => {
                if (res.status) {
                    commit('SET_MERCHANT_STATICAL', res.data);
                } else {
                    commit('SET_MERCHANT_STATICAL', null);
                }

                return res;
            });
        },

        async getAccountMerchant({ commit }) {
            return axios.get('merchant').then((res) => {
                commit('SET_IS_MERCHANT', res.status);
                return res;
            });
        },

        async getHistoryScan({ commit }, input) {
            const { page, limit, userId, email, startDate, endDate } = input;

            return axios
                .get('merchant/history', {
                    params: {
                        page,
                        limit,
                        userId: userId === '' ? undefined : userId,
                        email: email === '' ? undefined : email,
                        startDate,
                        endDate,
                    },
                })
                .then((res) => {
                    if (input.isExportAll) {
                        window.location.href = res.data;
                    } else {
                        commit('SET_HISTORY_SCAN', res.data);
                    }

                    return res;
                });
        },
        // New actions for SSE handling
        setupSSE({ commit, dispatch }) {
            // Handle connection status changes
            sseService.on('statusChange', (connected) => {
                // console.log('SSE status changed:', connected);
                commit('SET_SSE_CONNECTED', connected);
                if (connected) {
                    dispatch('getStatical');
                }
            });

            // Start the connection
            sseService.connect();
        },

        disconnectSSE({ commit, dispatch }) {
            console.log('Disconnecting SSE from Vuex');
            sseService.disconnect();

            commit('SET_SSE_CONNECTED', false);
        },

        // Action to refresh merchant data upon receiving an update
        refreshMerchantData({ dispatch }) {
            console.log('Refreshing merchant data after update');

            // Refresh statistical data
            dispatch('getStatical');

            // Refresh history data - use current pagination settings
            dispatch('getHistoryScan', {
                page: 1,
                limit: 10,
                userId: undefined,
                email: undefined,
                startDate: undefined,
                endDate: undefined,
            });
        },
    },
    mutations: {
        SET_MERCHANT_STATICAL(state, data) {
            state.merchantStatical = data;
        },
        SET_HISTORY_SCAN(state, data) {
            state.historyScan = data;
        },
        SET_IS_MERCHANT(state, data) {
            state.isMerchant = data;
        },
        SET_SSE_CONNECTED(state, status) {
            state.sseConnected = status;
        },
    },
};
