<script>
export default {
    data() {
        return {
            showPassword: false,
            showConfirmPassword: false,
            password: '',
            confirmPassword: '',
            email: null,
            sponsor: null,
            pending: false,
            phone: null,
            userInput: null,
        };
    },
    computed: {
        warningText() {
            let myString = '';
            const lowerCaseLetters = /[a-z]/g;
            const upperCaseLetters = /[A-Z]/g;
            const numbers = /[0-9]/g;
            const special = /[!@#$%^&*]/g;
            let firstTime = true;
            if (this.password.length < 8) {
                myString += `${this.$t('least8CharactersLong')} `;
            }
            if (!this.password.match(lowerCaseLetters)) {
                if (firstTime) {
                    myString += `${this.$t('mustContain')} `;
                    firstTime = false;
                    myString += `${this.$t('lowercaseLetter')}`;
                } else {
                    myString += `, ${this.$t('lowercaseLetter')}`;
                }
            }
            if (!this.password.match(upperCaseLetters)) {
                if (firstTime) {
                    myString += `${this.$t('mustContain')} `;
                    firstTime = false;
                    myString += `${this.$t('uppercaseLetter')}`;
                } else {
                    myString += `, ${this.$t('uppercaseLetter')}`;
                }
            }
            if (!this.password.match(numbers)) {
                if (firstTime) {
                    myString += `${this.$t('mustContain')} `;
                    firstTime = false;
                    myString += `${this.$t('aNumber')}`;
                } else {
                    myString += `, ${this.$t('aNumber')}`;
                }
            }
            if (!this.password.match(special)) {
                if (firstTime) {
                    myString += `${this.$t('mustContain')} `;
                    firstTime = false;
                    myString += `${this.$t('aSpecialCharacter')}`;
                } else {
                    myString += `, ${this.$t('aSpecialCharacter')}`;
                }
            }
            return myString;
        },
    },

    methods: {
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        toggleConfirm() {
            this.showConfirmPassword = !this.showConfirmPassword;
        },

        registerEmail() {
            // const isEmail = this.userInput
            //     .toLowerCase()
            //     .trim()
            //     .match(/^[^\s@]+(\+[^\s@]+)?@[^\s@]+\.[^\s@]+$/);
            // const isPhone = this.userInput
            //     .trim()
            //     .match(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/);
            if (this.pending) {
                return;
            }
            this.pending = true;
            if (this.password === this.confirmPassword) {
                this.$store
                    .dispatch('auth/RegisterEmail', {
                        email: this.userInput,
                        password: this.password,
                        sponsor: this.sponsor ? this.sponsor : undefined,
                    })
                    .then((res) => {
                        if (res.status !== false) {
                            this.$router.push({
                                name: 'Active-mail',
                                query: { email: this.userInput },
                            });
                        }
                    })
                    .finally(() => {
                        this.pending = false;
                    });

                // if (isEmail) {
                //     this.$store
                //         .dispatch('auth/RegisterEmail', {
                //             email: this.userInput,
                //             password: this.password,
                //             sponsor: this.sponsor ? this.sponsor : undefined,
                //         })
                //         .then((res) => {
                //             if (res.status !== false) {
                //                 this.$router.push({
                //                     name: 'Active-mail',
                //                     query: { email: this.userInput },
                //                 });
                //             }
                //         })
                //         .finally(() => {
                //             this.pending = false;
                //         });
                //     console.log('is Email');
                // } else if (isPhone) {
                //     this.$store
                //         .dispatch('auth/registerPhone', {
                //             phone: this.userInput,
                //             password: this.password,
                //             sponsor: this.sponsor ? this.sponsor : undefined,
                //         })
                //         .then((res) => {
                //             if (res.status !== false) {
                //                 this.$router.push({
                //                     name: 'Active-mail',
                //                     query: { email: this.userInput },
                //                 });
                //             }
                //         })
                //         .finally(() => {
                //             this.pending = false;
                //         });
                // } else {
                //     this.$toastr.e('Invalid input, try again!!', 'Oops!');
                // }
            } else {
                this.$toastr.e('Confirm your password again', 'Oops!');
                this.pending = false;
            }
        },
    },
    mounted() {
        if (this.$route.query.sponsor) {
            this.sponsor = this.$route.query.sponsor;
        }
    },
};
</script>

<template>
    <div class="register-container">
        <form @submit.prevent="registerEmail">
            <div class="img">
                <img src="../../../assets/images/logo/logoV3.png" alt="" />
            </div>
            <div class="inputs">
                <input
                    type="text"
                    :placeholder="$t('email')"
                    v-model="userInput"
                    autocomplete="userInput"
                    required
                />
                <div class="input-pw">
                    <input
                        :type="showPassword ? 'text' : 'password'"
                        :placeholder="$t('password')"
                        v-model="password"
                        id="password"
                        name="password"
                        required
                        autocomplete="new-password"
                        pattern="(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    />
                    <img
                        v-if="!showPassword"
                        @click="toggleShow"
                        class="eye-open"
                        src="../../../assets/images/icons/ph--eye-fill.svg"
                        alt=""
                    />
                    <img
                        v-else
                        @click="toggleShow"
                        class="eye-close"
                        src="../../../assets/images/icons/ph--eye-slash-fill.svg"
                        alt=""
                    />
                </div>
                <div v-if="warningText.length > 0" class="note">
                    {{ warningText }}
                </div>
                <div class="input-confirm">
                    <input
                        class="confirm-password"
                        :type="showConfirmPassword ? 'text' : 'password'"
                        :placeholder="$t('confirmPassword')"
                        v-model="confirmPassword"
                        id="confirmPassword"
                        name="confirmPassword"
                        required
                        autocomplete="new-password"
                    />
                    <img
                        v-if="!showConfirmPassword"
                        @click="toggleConfirm"
                        class="eye-open"
                        src="../../../assets/images/icons/ph--eye-fill.svg"
                        alt=""
                    />
                    <img
                        v-else
                        @click="toggleConfirm"
                        class="eye-close"
                        src="../../../assets/images/icons/ph--eye-slash-fill.svg"
                        alt=""
                    />
                </div>
                <input
                    type="text"
                    :placeholder="$t('sponsorOrNft')"
                    v-model="sponsor"
                    id="sponsor"
                />
            </div>
            <div class="buttons">
                <button :class="{ pending: pending }" :disabled="pending">
                    {{ $t('register') }}
                </button>
            </div>
            <p>
                {{ $t('alreadyHaveAccount') }}
                <router-link class="router-link" to="/"
                    ><strong>{{ $t('login') }}</strong></router-link
                >
            </p>
            <div class="buttons">
                <a class="button" href="https://xintravel.app.link" target="_blank">
                    {{ $t('goToApp') }}
                    <strong style="color: #8bcff1"> Xintel </strong>
                </a>
            </div>
        </form>
    </div>
</template>
<style lang="scss" scoped>
.register-container {
    background: linear-gradient(#3e84c6, #1a446b);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    form {
        flex-basis: 400px;
    }

    .img {
        display: flex;
        justify-content: center;

        > img {
            max-width: 100%;
            width: 200px;
        }
    }

    .inputs {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 0 auto;
        margin-top: 1.5rem;

        .input-pw,
        .input-confirm {
            position: relative;

            input {
                width: 100%;
            }

            img {
                position: absolute;
                top: 50%;
                right: 1rem;
                transform: translateY(-50%);
                width: 24px;
                cursor: pointer;
            }
        }

        input {
            padding: 0 1rem;
            border: 1px solid #e4e4e4;
            border-radius: 8px;
            height: 40px;
            background-color: #6a93b9;
            color: #fff;
        }

        input:focus-visible {
            outline: none;
        }

        input::placeholder {
            color: #fff;
            font-size: 18px;
            font-style: italic;
            font-weight: 400;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
        gap: 5px;
        color: #fff;

        button,
        .button {
            padding: 8px;
            width: 100%;
            border-radius: 72px;
            text-transform: uppercase;
            font-size: 18px;
            transition: 0.5s;
            outline: none;

            &:hover {
                opacity: 0.5;
            }

            &.pending {
                filter: grayscale(1);
            }
        }

        button:nth-child(1) {
            background: linear-gradient(to right, #1775b8, #91c4e9);
            color: #fff;
            font-weight: 600;
        }

        button:nth-child(2),
        .button {
            border: 1px solid #8bcff1;
            color: #6dceff;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 7px;

            font-weight: 600;
        }
    }

    .note {
        color: #ffc107;
        font-size: 14px;
        padding: 0px 5px;
    }

    p {
        margin-top: 1rem;
        text-align: center;
        font-size: 18px;
        color: #fff;

        .router-link {
            transition: 0.5s;

            &:hover {
                color: #fff;
                opacity: 0.5;
            }
        }
    }

    @media (max-width: 1201px) {
        form {
            .inputs {
                width: 100%;
            }
        }
    }

    @media (max-width: 990px) {
        padding: 20px;

        form {
            padding-bottom: 2rem;
            overflow-y: hidden;

            .inputs {
                margin-top: 0;

                input::placeholder {
                    font-size: 16px;
                }

                .input-pw,
                .input-confirm {
                    img {
                        width: 25px;
                    }
                }
            }

            .buttons {
                button {
                    width: 100%;
                    font-size: 16px;
                }
            }

            p {
                font-size: 16px;
            }
        }
    }

    @media (max-width: 376px) {
        padding: 5rem 1rem 3rem 1rem;
    }
}
</style>
