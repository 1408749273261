// src/components/MysteryBox/components/SuccessMessage.vue
<template>
    <div class="success-message">
        <div class="success-icon"></div>
        <span>{{ $t('boxUnlocked') }}!</span>
    </div>
</template>

<script>
export default {
    name: 'SuccessMessage',
};
</script>

<style scoped>
.success-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    pointer-events: none;
}

.success-icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: radial-gradient(
        circle,
        rgba(0, 255, 255, 0.8) 0%,
        rgba(0, 150, 255, 0.4) 70%
    );
    margin-bottom: 10px;
    position: relative;
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.6);
    animation: iconPulse 2s infinite alternate;
}

.success-icon:before {
    content: '✓';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: white;
    text-shadow: 0 0 10px rgba(0, 255, 255, 1);
}

.success-message span {
    display: inline-block;
    padding: 15px 30px;
    background: rgba(10, 30, 50, 0.7);
    color: #00ffff;
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 3px;
    border: 2px solid rgba(0, 255, 255, 0.6);
    border-radius: 12px;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.7);
    animation: glowPulse 2s infinite alternate;
}

@keyframes iconPulse {
    0% {
        box-shadow: 0 0 20px rgba(0, 255, 255, 0.6);
    }
    100% {
        box-shadow: 0 0 40px rgba(0, 255, 255, 0.9);
    }
}

@keyframes glowPulse {
    from {
        box-shadow: 0 0 15px rgba(0, 255, 255, 0.5);
        text-shadow: 0 0 5px rgba(0, 255, 255, 0.7);
    }
    to {
        box-shadow: 0 0 25px rgba(0, 255, 255, 0.8),
            0 0 40px rgba(0, 255, 255, 0.4);
        text-shadow: 0 0 10px rgba(0, 255, 255, 1);
    }
}

@media (max-width: 480px) {
    .success-message span {
        font-size: 16px;
    }
}
</style>
