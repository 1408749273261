export default {
    1: {
        personalBestNodeValue: 500,
        totalStaking: 0,
        f1MonthlyKPIs: 5000,
        groupMonthlyKPIs: 0,
        nOfF1Ambassadors: {},
        miscellaneous: {
            trainingCourses: [
                {
                    id: 1,
                    name: 'Từ A-Z về Xintel',
                    duration: 'thời gian 2 buổi 6 tiếng',
                },
                {
                    id: 2,
                    name: '8 bước thành công cùng Xintel',
                    duration: 'thời gian 2 buổi 6 tiếng',
                },
            ],
            meetings: {
                description:
                    'Có mặt trong các buổi zoom và các sự kiện của Xintel',
                minimumAttendance:
                    'Một tháng có mặt trong ít nhất 3 buổi họp core leader',
            },
        },
        reward: {
            eventTicket: {
                description: '01 vé tham dự sự kiện lãnh đạo (hoặc du lịch)',
                frequency: '1 tháng 1 lần',
            },
        },
    },
    2: {
        personalBestNodeValue: 1000,
        totalStaking: 0,
        f1MonthlyKPIs: 0,
        groupMonthlyKPIs: 20000,
        nOfF1Ambassadors: {
            1: 3,
        },
        miscellaneous: {
            trainingCourses: [
                {
                    id: 1,
                    name: 'Kiến thức cơ bản về thị trường Blockchain và Crypto',
                    duration: '',
                },
                {
                    id: 2,
                    name: 'Xu hướng xác định dòng tiền, quản trị tài chính cá nhân',
                    duration: '',
                },
            ],
            meetings: {
                description:
                    'Có mặt trong các buổi zoom và các sự kiện của Xintel',
                minimumAttendance:
                    'Một tháng có mặt trong buổi họp core leader, hỗ trợ các sự kiện theo khả năng và phân công của tổ chức',
            },
        },
        reward: {
            revenuePercentage: {
                description: '1% DOANH SỐ',
                details: '',
            },
        },
    },
    3: {
        personalBestNodeValue: 5000,
        totalStaking: 0,
        f1MonthlyKPIs: 0,
        groupMonthlyKPIs: 60000,
        nOfF1Ambassadors: {
            2: 2,
        },
        miscellaneous: {
            trainingCourses: [
                {
                    id: 1,
                    name: 'Train the trainer',
                    duration: '',
                },
                {
                    id: 2,
                    name: 'Quản trị cộng đồng',
                    duration: '',
                },
            ],
            meetings: {
                description:
                    'Có mặt trong các buổi zoom và các sự kiện của Xintel',
                minimumAttendance:
                    'Một tháng có mặt trong buổi họp core leader, hỗ trợ các sự kiện theo khả năng và phân công của tổ chức',
            },
        },
        reward: {
            revenuePercentage: {
                description: '1% DOANH SỐ',
                details: '',
            },
            eventTicket: {
                description: '01 vé tham dự sự kiện lãnh đạo (hoặc du lịch)',
                frequency: '1 tháng 1 lần',
            },
        },
    },
    4: {
        personalBestNodeValue: 5000,
        totalStaking: 1000,
        f1MonthlyKPIs: 0,
        groupMonthlyKPIs: 200000,
        nOfF1Ambassadors: {
            3: 2,
        },
        miscellaneous: {
            trainingCourses: [
                {
                    id: 1,
                    name: '',
                    duration: '',
                },
                {
                    id: 2,
                    name: '',
                    duration: '',
                },
            ],
            meetings: {
                description:
                    'Có mặt trong các buổi zoom và các sự kiện của Xintel',
                minimumAttendance:
                    'Một tháng có mặt trong buổi họp core leader, hỗ trợ các sự kiện theo khả năng và phân công của tổ chức',
            },
        },
        reward: {
            revenuePercentage: {
                description: '1.5% DOANH SỐ',
                details: '',
            },
            eventTicket: {
                description: '02 vé tham dự sự kiện lãnh đạo (hoặc du lịch)',
                frequency: '1 tháng 1 lần',
            },
        },
    },
    5: {
        personalBestNodeValue: 5000,
        totalStaking: 3000,
        f1MonthlyKPIs: 0,
        groupMonthlyKPIs: 500000,
        nOfF1Ambassadors: {
            4: 2,
        },
        miscellaneous: {
            trainingCourses: [
                {
                    id: 1,
                    name: '',
                    duration: '',
                },
                {
                    id: 2,
                    name: '',
                    duration: '',
                },
            ],
            meetings: {
                description:
                    'Có mặt trong các buổi zoom và các sự kiện của Xintel',
                minimumAttendance:
                    'Một tháng có mặt trong buổi họp core leader, hỗ trợ các sự kiện theo khả năng và phân công của tổ chức',
            },
        },
        reward: {
            revenuePercentage: {
                description: '2% DOANH SỐ',
                details: '',
            },
            eventTicket: {
                description: '03 vé tham dự sự kiện lãnh đạo (hoặc du lịch)',
                frequency: '1 tháng 1 lần',
            },
        },
    },

    6: {
        personalBestNodeValue: 10000,
        totalStaking: 5000,
        f1MonthlyKPIs: 0,
        groupMonthlyKPIs: 1250000,
        nOfF1Ambassadors: {
            5: 2,
        },
        miscellaneous: {
            trainingCourses: [
                {
                    id: 1,
                    name: '',
                    duration: '',
                },
                {
                    id: 2,
                    name: '',
                    duration: '',
                },
            ],
            meetings: {
                description:
                    'Có mặt trong các buổi zoom và các sự kiện của Xintel',
                minimumAttendance:
                    'Một tháng có mặt trong buổi họp core leader, hỗ trợ các sự kiện theo khả năng và phân công của tổ chức',
            },
        },
        reward: {
            revenuePercentage: {
                description: '2.5% DOANH SỐ',
                details: '',
            },
            eventTicket: {
                description: '04 vé tham dự sự kiện lãnh đạo (hoặc du lịch)',
                frequency: '1 tháng 1 lần',
            },
        },
    },
};
