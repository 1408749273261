<template>
    <div class="container">
        <div class="ambassador-stat">
            <button class="info">
                <InfoIcon />
            </button>
            <div class="condition-container">
                <template v-if="Ambassador">
                    <div
                        v-for="(item, index) in Ambassador.conditions"
                        :key="`${item.type}${index}`"
                        class="condition"
                    >
                        <span v-b-tooltip.hover :title="item.description">
                            {{ $t(`ambassadorPrefix.${item.type}`) }}:
                        </span>
                        <template v-if="item.type !== 'f1AmbCondition'">
                            <span
                                class="value"
                                v-b-tooltip.hover
                                :title="`${
                                    item.met
                                        ? $t('done')
                                        : `${$t('required')} ${Number(
                                              item.required,
                                          ).toLocaleString('en-US')}`
                                }`"
                            >
                                {{
                                    Number(item.current).toLocaleString('en-US')
                                }}
                                {{ PostFix(item.type) }}
                                /
                                <span class="required">
                                    {{
                                        Number(item.required).toLocaleString(
                                            'en-US',
                                        )
                                    }}
                                    {{ PostFix(item.type) }}
                                </span>
                                <TickIcon class="tick" v-if="item.met" />
                                <InfoIcon class="notice-icon" v-else />
                            </span>
                        </template>
                        <template v-else>
                            <span
                                class="value"
                                v-b-tooltip.hover
                                :title="`${
                                    item.met ? $t('done') : item.required
                                }`"
                            >
                                {{ item.current }}
                                /
                                <span class="required">
                                    {{
                                        rankingData[Ambassador.nextStar]
                                            .nOfF1Ambassadors[
                                            Ambassador.currentStar
                                        ]
                                    }}
                                </span>
                                Ambassador
                                <span class="star-tag">
                                    <AnimatableStar
                                        v-for="i in Ambassador.nextStar - 1"
                                        :key="i"
                                        active
                                        :duration="0"
                                    />
                                </span>
                                <TickIcon class="tick" v-if="item.met" />
                                <InfoIcon class="notice-icon" v-else />
                            </span>
                        </template>
                    </div>
                </template>
                <!-- <div class="condition">
                    {{ $t('totalStakingAmount') }}
                    <span class="value"> Kek </span>
                </div>
                <div class="condition">
                    {{ $t('totalF1Sales') }}
                    <span class="value"> Kek </span>
                </div>
                <div class="condition">
                    {{ $t('totalGroupSales') }}
                    <span class="value"> Kek </span>
                </div>
                <div class="condition">
                    {{ $t('totalF1Sales') }}
                    <span class="value"> Kek </span>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InfoIcon from '../icons/InfoIcon.vue';
import TickIcon from '../icons/TickIcon.vue';
import AnimatableStar from '../icons/AnimatableStar.vue';
import rankingData from './utils';

export default {
    name: 'AmbassadorStat',
    components: { InfoIcon, TickIcon, AnimatableStar },
    data() {
        return {
            rankingData,
        };
    },
    computed: {
        ...mapGetters({
            Ambassador: 'ambassador/CurrentStar',
        }),
    },
    methods: {
        PostFix(string) {
            return string === 'f1Live' ||
                string === 'monthlyRevenue' ||
                string === 'personalNode' ||
                string === 'stakingMinimum'
                ? 'USDT'
                : '';
        },
    },
};
</script>

<style lang="scss" scoped>
.ambassador-stat {
    background-color: #fff;
    border: 1px solid #d8d8d8;
    border-top: 1px solid #d5f1ff;

    border-radius: 0px 0px 6px 6px;
    padding: 1rem;

    .condition-container {
        display: flex;
        flex-wrap: wrap;
    }
    .condition {
        flex-basis: 100%;
        @media (min-width: 992px) {
            flex-basis: 50%;
        }
        color: #a1a1a1;
        font-weight: 500;
        .value {
            color: #111111;
            cursor: pointer;
            display: block;
            @media (min-width: 576px) {
                display: inline-block;
            }
            .required {
                color: #f97316;
            }
        }
        .tick {
            color: #00c811;
            font-size: 1.25em;
        }
    }
    .info {
        display: block;
        width: fit-content;
        margin-left: auto;
        padding: 0;
        border-radius: 100vw;
        line-height: 1;
        color: #0087c8;
    }
    .star-tag {
        display: inline-flex;
        // background-color: #0087c8;
        border-radius: 4px;
        padding: 3px 8px;
        vertical-align: middle;
        > svg {
            width: 20px;
            color: #fdd835;
            // background-color: #fff;
            // border-radius: 100vw;
            // padding: 1px;
        }
    }
    .notice-icon {
        color: #fdd835;
        font-size: 16px;
    }
}
</style>
