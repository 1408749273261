<template>
    <div class="manage-merchant">
        <!--        <div class="merchant-header">-->
        <!--            <h2>{{ $t('merchantDashboard') }}</h2>-->
        <!--            <SSEStatusIndicator />-->
        <!--        </div>-->

        <div class="top-qr">
            <div class="my-qr">
                <div class="name-node">
                    {{ MyMerchantStatical.nodeInfo.name }} -
                    {{ MyMerchantStatical.nodeInfo.priceUSDT }} USDT
                </div>
                <QrMerchant />
            </div>
            <div class="total-merchant">
                <div class="item">
                    <div class="tit">
                        <p>
                            {{ $t('numberOfScan') }}
                        </p>
                    </div>
                    <div class="content">
                        <div
                            class="flex items-center gap-[10px] text-[24px] text-[var(--c-text-primary)] font-medium"
                        >
                            {{ MyMerchantStatical.scanCount }} /
                            {{ MyMerchantStatical.nodeInfo.scanCount }}
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="tit">
                        <p>
                            {{ $t('totalHZ') }}
                        </p>
                    </div>
                    <div class="content">
                        <div
                            class="flex items-center gap-[10px] text-[24px] text-[var(--c-text-primary)] font-medium"
                        >
                            {{ MyMerchantStatical.totalAmount }} Hz
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="tit">
                        <p>
                            {{ $t('totalAllScan') }}
                        </p>
                    </div>
                    <div class="content">
                        <div
                            class="flex items-center gap-[10px] text-[24px] text-[var(--c-text-primary)] font-medium"
                        >
                            {{ MyMerchantStatical.totalScan }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-history-scan">
            <TableHistoryScan />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import QrMerchant from './QrMerchant.vue';
import TableHistoryScan from './TableHistoryScan.vue';

export default {
    name: 'ManageMerchant',

    components: {
        QrMerchant,
        TableHistoryScan,
    },

    computed: {
        ...mapGetters({
            MyMerchantStatical: 'merchant/MyMerchantStatical',
            sseConnected: 'merchant/SSEConnected',
        }),
    },
    methods: {
        ...mapActions({
            setupSSE: 'merchant/setupSSE',
            disconnectSSE: 'merchant/disconnectSSE',
        }),
    },

    // mounted() {
    //     // Initialize SSE connection when component is mounted
    //     console.log(
    //         'ManageMerchant component mounted, setting up SSE connection',
    //     );
    //     this.setupSSE();
    // },
    //
    // beforeDestroy() {
    //     // Clean up SSE connection when component is destroyed
    //     console.log(
    //         'ManageMerchant component being destroyed, cleaning up SSE connection',
    //     );
    //     this.disconnectSSE();
    // },
};
</script>

<style lang="scss" scoped>
.manage-merchant {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    .top-qr {
        display: flex;
        justify-content: space-between;

        .name-node {
            font-size: 24px;
            font-weight: 700;
        }

        .my-qr {
            width: 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #d8d8d8;
            border-radius: 20px;
            background-color: white;
            flex-direction: column;
            padding: 20px 0;
        }

        .total-merchant {
            width: 55%;
            display: grid;
            grid-template-rows: repeat(3, 1fr);
            gap: 16px;

            .item {
                border: 1px solid #d8d8d8;
                border-radius: 20px;
                padding: 12px;
                padding-left: 20px;
                background-color: white;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .tit {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    p {
                        font-size: 18px;
                        font-weight: 700;
                    }
                }

                .content {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    div {
                        font-size: 24px;
                        font-weight: 700;
                        color: #107fb9;
                    }
                }
            }
        }
    }

    .table-history-scan {
        margin-top: 1rem;
        border: 1px solid #d8d8d8;
        border-radius: 20px;
        background-color: white;
        padding: 20px;
    }
}

@media (max-width: 1024px) {
    .manage-merchant {
        .top-qr {
            flex-direction: column-reverse;
            gap: 16px;

            .my-qr {
                width: 100%;
            }

            .total-merchant {
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: unset;
                width: 100%;

                .item {
                    justify-content: space-between;
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .manage-merchant {
        .top-qr {
            .total-merchant {
                gap: 4px;

                .item {
                    padding: 8px;

                    .tit {
                        p {
                            font-size: 14px;
                        }
                    }

                    .content {
                        div {
                            font-size: 16px;
                            overflow-wrap: break-word;
                        }
                    }
                }
            }
        }
    }
}
</style>
