<template>
    <div>
        <div class="icon">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-focus"
                @click="$bvModal.show('qrscan')"
            >
                <circle cx="12" cy="12" r="3" />
                <path d="M3 7V5a2 2 0 0 1 2-2h2" />
                <path d="M17 3h2a2 2 0 0 1 2 2v2" />
                <path d="M21 17v2a2 2 0 0 1-2 2h-2" />
                <path d="M7 21H5a2 2 0 0 1-2-2v-2" />
            </svg>
        </div>
        <b-modal id="qrscan" hide-header hide-footer centered>
            <qrcode-stream
                @decode="onDecode"
                @init="onInit"
                class="camera-zone"
            >
                <div v-if="!ready" class="overlay">
                    <h3>Please wait</h3>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="none"
                            stroke="currentColor"
                            stroke-dasharray="15"
                            stroke-dashoffset="15"
                            stroke-linecap="round"
                            stroke-width="2"
                            d="M12 3C16.9706 3 21 7.02944 21 12"
                        >
                            <animate
                                fill="freeze"
                                attributeName="stroke-dashoffset"
                                dur="0.3s"
                                values="15;0"
                            />
                            <animateTransform
                                attributeName="transform"
                                dur="1.5s"
                                repeatCount="indefinite"
                                type="rotate"
                                values="0 12 12;360 12 12"
                            />
                        </path>
                    </svg>
                </div>
                <h3 v-if="ready">Scan QR</h3>
            </qrcode-stream>
            <!-- <div v-if="decodedResult">
                <p>Scanned QR Code: {{ decodedResult }}</p>
            </div> -->
            <button class="close" @click="closeModal" type="button">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                    />
                </svg>
            </button>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
    components: { QrcodeStream },
    data() {
        return {
            decodedResult: null,
            ready: false,
            openCamera: false,
        };
    },
    computed: {
        ...mapGetters({
            UserInfo: 'info/UserInfo',
            ScanHistory: 'merchant/HistoryScan',
        }),
    },
    methods: {
        onDecode(result) {
            this.decodedResult = result;

            console.log(result);
            if (this.decodedResult) {
                this.$store
                    .dispatch('merchant/post_merchantCode', {
                        qrManual: this.decodedResult,
                    })
                    .then((res) => {
                        if (res.status) {
                            this.$bvModal.hide('qrscan');
                        }
                        this.decodedResult = null;
                        this.$bvModal.hide('boost-wattage');
                    });
            }
        },
        onInit(promise) {
            promise
                .then(() => {
                    this.ready = true;
                })
                .catch((error) => {
                    console.error(error);
                    this.ready = false;
                });
        },

        // onBoostMining() {
        //     if()
        // },

        closeModal() {
            this.$bvModal.hide('qrscan');
            this.decodedResult = null;
            this.ready = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.lucide-focus {
    position: absolute;
    right: 10px;
    top: 8px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        opacity: 0.8;
    }
}

.camera-zone {
    &::before {
        content: '';
        position: absolute;
        --b: 5px; /* thickness of the border */
        --c: #fff; /* color of the border */
        --w: 20px; /* width of border */

        border: var(--b) solid #0000; /* space for the border */
        --_g: #0000 90deg, var(--c) 0;
        --_p: var(--w) var(--w) border-box no-repeat;
        background: conic-gradient(
                    from 90deg at top var(--b) left var(--b),
                    var(--_g)
                )
                0 0 / var(--_p),
            conic-gradient(
                    from 180deg at top var(--b) right var(--b),
                    var(--_g)
                )
                100% 0 / var(--_p),
            conic-gradient(
                    from 0deg at bottom var(--b) left var(--b),
                    var(--_g)
                )
                0 100% / var(--_p),
            conic-gradient(
                    from -90deg at bottom var(--b) right var(--b),
                    var(--_g)
                )
                100% 100% / var(--_p);

        max-width: 250px;
        max-height: 250px;
        width: 90%;
        height: 90%;
        box-sizing: border-box;
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-flex;
        font-size: 30px;
        justify-content: center;
        align-items: center;
    }

    .overlay {
        svg {
            font-size: 100px;
            position: absolute;
            margin: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #4bb8f0;
        }
    }
}

.close {
    position: absolute;
    top: 6px;
    right: 6px;

    svg {
        color: #fff;
    }
}

.qrcode-stream-overlay {
    backdrop-filter: blur(15px);
}
</style>

<style>
#qrscan {
    .modal-body {
        border-radius: 22px;
        padding: 0;
    }

    h3 {
        position: absolute;
        margin: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        font-size: 30px;
        font-weight: 600;
        color: #4bb8f0;
    }

    .qrcode-stream-camera {
        min-height: 450px;
    }
}
</style>
