<template>
    <div>
        <div v-if="EsimData" class="esim-container">
            <div
                v-for="(plan, index) in EsimData.results"
                :key="index"
                class="esim-card"
            >
                <div class="esim w-full h-full absolute z-[1] rounded-xl"></div>
                <div class="z-10 relative p-4 content-esim rounded-xl">
                    <div class="plan-name">
                        {{ plan.name }}
                    </div>
                    <div class="plan-price">
                        <div class="text-lg py-1 leading-5 laptop:leading-6">
                            <span> {{ $t('pricing') }}: </span>
                            <span class="font-medium">
                                {{ plan.priceUSDT }} USDT
                            </span>
                        </div>
                        <div class="text-lg py-1 leading-5 laptop:leading-6">
                            <span> {{ $t('topUp') }}: </span>
                            <span class="font-medium inline-block">
                                {{ plan.maintenanceFee }} USDT /
                                {{ plan.duration }}
                                {{ $t('monthEsim') }}
                            </span>
                        </div>
                    </div>
                    <button class="buy" @click="openModal(plan)">
                        {{ $t('getPlan') }}
                    </button>
                </div>
            </div>
        </div>

        <b-modal id="esimplan-confirm" centered hide-footer hide-header>
            <svg
                @click="$bvModal.hide('esimplan-confirm')"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"
                />
            </svg>
            <div v-if="selectedPlan">
                <h2>{{ $t('getPlan') }}</h2>
                <!-- <h3>Are you sure {{ chosenPlan.name }}?</h3> -->
                <h3>{{ $t('areYouSure') }} {{ selectedPlan.name }} ?</h3>
                <div class="payment">
                    <p>{{ $t('selectcurrency') }}:</p>
                    <div class="unit">
                        <div
                            :class="{
                                active: currency === 'xinCoin',
                                inactive: currency !== 'xinCoin',
                            }"
                            @click="setCurrency('xinCoin')"
                        >
                            XIN
                        </div>
                        <div
                            :class="{
                                active: currency === 'USDT',
                                inactive: currency !== 'USDT',
                            }"
                            @click="setCurrency('USDT')"
                        >
                            USDT
                        </div>
                    </div>
                </div>

                <div class="content">
                    {{ $t('proceedToBuy') }}
                    <strong>{{ selectedPlan.name }}</strong> {{ $t('for') }}

                    <strong v-if="currency === 'USDT'"
                        >{{ selectedPlan.priceUSDT }}
                        <img src="@/assets/images/logo/usdt.png" alt="" />
                    </strong>

                    <strong v-if="currency === 'xinCoin'"
                        >{{ selectedPlan.priceXIN }}
                        <img src="@/assets/images/logo/xin.png" alt=""
                    /></strong>
                </div>
            </div>

            <div class="buttons">
                <span @click="buyPlan(selectedPlan.id)">{{
                    $t('confirm')
                }}</span>
                <span @click="$bvModal.hide('esimplan-confirm')">{{
                    $t('cancel')
                }}</span>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            chosenPlan: null,
            selectedPlan: null,
            currency: 'xinCoin',
            pending: false,
        };
    },

    props: {
        EsimData: Object,
    },

    methods: {
        setCurrency(input) {
            this.currency = input;
            console.log('currency: ', this.currency);
        },

        openModal(input) {
            this.$bvModal.show('esimplan-confirm');
            this.selectedPlan = input;
        },

        async buyPlan(subscriptionId) {
            if (this.pending) {
                return;
            }

            this.pending = true;
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('buyPlan');

            this.$store
                .dispatch('nft/buySubscription', {
                    subscriptionId: subscriptionId,
                    currency: this.currency,
                    token,
                })
                .then((res) => {
                    if (res && res.status) {
                        this.$bvModal.hide('esimplan-confirm');
                    }
                })
                .finally(() => {
                    this.pending = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.esim-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @media (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 575px) {
        grid-template-columns: repeat(1, 1fr);
    }

    .esim-card {
        margin-top: 20px;
        background: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 22px;
        position: relative;
        z-index: 1;

        .esim {
            background-image: url('../../../../assets/images/mockup/esim.png');
            background-size: cover;
            filter: brightness(0.5);
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            border-radius: 22px;
        }

        .plan-name,
        .plan-price {
            color: #fff;
            font-weight: 700;
        }

        button {
            margin-top: 1.5rem;
            background: #0087cc;
            border-radius: 100px;
            color: #fff;
            font-weight: 600;
            padding: 7px 14px;
            outline: none;
            transition: 0.2s;
            &:hover {
                background: #1b9bdb;
            }
        }
    }
}
</style>

<style lang="scss">
#esimplan-confirm {
    @media (min-width: 320px) {
        .modal-content {
            position: relative;
            width: 100%;
            margin: 0 auto;

            .modal-body {
                border-radius: 12px;

                h2 {
                    color: #0087cc;
                    text-align: center;
                    font-size: 30px;
                    margin-bottom: 15px;
                }

                h3 {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                }

                p {
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;
                }

                svg {
                    position: absolute;
                    font-size: 30px;
                    top: 10px;
                    right: 10px;
                    cursor: pointer;
                }

                .content {
                    text-align: center;
                    font-weight: 500;
                    span {
                        font-size: 20px;
                        font-weight: 700;
                        color: #0087cc;
                    }

                    img {
                        width: 20px;
                    }
                }

                .payment {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    margin: 15px 0;

                    p {
                        font-size: 20px;
                        font-weight: 600;
                        color: #0087cc;
                        margin-bottom: 0;
                    }

                    .unit {
                        display: flex;
                        transition: 0.2s;

                        > div {
                            padding: 5px 20px;
                            color: #fff;
                            cursor: pointer;
                            font-weight: 700;
                            flex-shrink: 0;
                            white-space: nowrap;

                            &:nth-child(1) {
                                border-top-left-radius: 6px;
                                border-bottom-left-radius: 6px;
                            }

                            &:nth-child(2) {
                                border-top-right-radius: 6px;
                                border-bottom-right-radius: 6px;
                            }

                            // &:nth-child(3) {
                            //     border-top-right-radius: 6px;
                            //     border-bottom-right-radius: 6px;
                            // }
                        }
                    }

                    .unit > div.inactive {
                        background: #ccc;
                        color: #000;
                        opacity: 0.3;
                    }

                    .unit > div.active {
                        background: #1775b8;
                        color: #fff;
                    }

                    @media (max-width: 991px) {
                        .unit {
                            > div {
                                font-size: 14px;
                            }
                        }
                    }

                    @media (max-width: 418px) {
                        flex-direction: column;

                        .unit {
                            margin-bottom: 15px;
                        }
                    }
                }

                > .buttons {
                    display: flex;
                    align-content: center;
                    justify-content: space-around;
                    gap: 15px;
                    margin-top: 1rem;

                    span {
                        cursor: pointer;
                        max-width: 200px;
                        width: 100%;
                        color: white;
                        border-radius: 72px;
                        padding: 4px 10px;
                        font-size: 18px;
                        text-transform: uppercase;
                        font-weight: 600;
                        text-align: center;
                        transition: 0.2s;

                        &:nth-child(1) {
                            background-image: linear-gradient(
                                to right,
                                #075d9b,
                                #1775b8,
                                #91c4e9,
                                #d2e9fa
                            );
                            background-position: center;
                            background-size: 200%;

                            &:hover {
                                background-position: 0%;
                            }
                        }

                        &:nth-child(2) {
                            color: #0087cc;
                            border: 1px solid #0087cc;
                        }
                    }
                }
            }
        }
    }
}
</style>
