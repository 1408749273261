// src/components/MysteryBox/utils/math.utils.js

/**
 * Cubic Bezier curve implementation for smoother easing
 *
 * @param {number} p0 - First control point
 * @param {number} p1 - Second control point
 * @param {number} p2 - Third control point
 * @param {number} p3 - Fourth control point
 * @param {number} t - Time parameter (0-1)
 * @returns {number} - Interpolated value
 */
export const cubicBezier = (p0, p1, p2, p3, t) => {
    const oneMinusT = 1 - t;
    const oneMinusTSquared = oneMinusT * oneMinusT;
    const oneMinusTCubed = oneMinusTSquared * oneMinusT;
    const tSquared = t * t;
    const tCubed = tSquared * t;

    return (
        oneMinusTCubed * p0 +
        3 * oneMinusTSquared * t * p1 +
        3 * oneMinusT * tSquared * p2 +
        tCubed * p3
    );
};

/**
 * Generate a random number between min and max
 *
 * @param {number} min - Minimum value (inclusive)
 * @param {number} max - Maximum value (exclusive)
 * @returns {number} - Random number
 */
export const random = (min, max) => {
    return Math.random() * (max - min) + min;
};

/**
 * Clamp a value between min and max
 *
 * @param {number} value - Value to clamp
 * @param {number} min - Minimum allowable value
 * @param {number} max - Maximum allowable value
 * @returns {number} - Clamped value
 */
export const clamp = (value, min, max) => {
    return Math.max(min, Math.min(max, value));
};

/**
 * Linear interpolation between two values
 *
 * @param {number} a - Start value
 * @param {number} b - End value
 * @param {number} t - Interpolation factor (0-1)
 * @returns {number} - Interpolated value
 */
export const lerp = (a, b, t) => {
    return a + (b - a) * t;
};
