// src/components/MysteryBox/models/box.model.js
import * as THREE from 'three';
import textureNormal from '@/assets/images/texture/normal.jpg';
import textureMetal from '@/assets/images/texture/metalness.jpg';
import textureRough from '@/assets/images/texture/roughness.jpg';
import textureDiffuse from '@/assets/images/texture/scifi.jpg';
// import textureDiffuse from '@/assets/images/texture/diffuse.jpg';
import { createQuestionMark } from './questionMark.model';
import { cubicBezier } from '../utils/math.utils';

export default class BoxModel {
    constructor(scene, options = {}) {
        this.scene = scene;

        this.boxSize = options.boxSize || 1.4;
        this.initialBoxPosition = options.boxPositionY || 0.1;

        // Box group
        this.boxGroup = new THREE.Group();
        this.boxGroup.position.y = 0;
        this.boxGroup.userData = { clickable: true };
        this.scene.add(this.boxGroup);

        // Box parts
        this.boxLid = null;
        this.boxBottom = null;
        this.innerBoxLid = null;
        this.innerBoxBottom = null;
        this.innerGlow = null;

        // Initialize texture container
        this.boxTextures = {
            diffuse: null,
            normal: null,
            roughness: null,
            metalness: null,
        };

        // Decoration elements
        this.hexPatterns = [];
        this.holographicPlanes = [];
        this.questionMarks = {
            front: null,
            right: null,
            back: null,
            left: null,
        };

        // Animation properties
        this.isOpen = false;
        // this.initialBoxPosition = 0.1;
        this.bouncingEnabled = true;
        this.bouncingHeight = 0.1;
        this.bouncingSpeed = 1.2;
        this.bouncingRotation = 0.02;
        this.lidOpeningStartTime = 0;
        this.lidOpeningDuration = 2.2;

        // Load textures before creating the box
        this.loadTextures().then(() => {
            // Create the box
            this.createBox();
            this.createInnerGlow();
        });
    }

    loadTextures() {
        this.textureLoader = new THREE.TextureLoader();
        const promises = [];

        // README: TEXTURE
        const defaultTextures = {
            diffuse: textureDiffuse,
            normal: textureNormal,
            roughness: textureRough,
            metalness: textureMetal,
        };

        // Merge default textures with provided options
        const texturePaths = { ...defaultTextures };

        // Helper function to load a texture
        const loadTexture = (type, path) => {
            if (!path) return Promise.resolve();

            return new Promise((resolve) => {
                this.textureLoader.load(
                    path,
                    (texture) => {
                        texture.wrapS = THREE.RepeatWrapping;
                        texture.wrapT = THREE.RepeatWrapping;
                        texture.repeat.set(1, 1);
                        this.boxTextures[type] = texture;
                        resolve();
                    },
                    undefined,
                    () => {
                        console.warn(`Failed to load ${type} texture: ${path}`);
                        resolve();
                    },
                );
            });
        };

        // Load each texture type if path is provided
        Object.entries(texturePaths).forEach(([type, path]) => {
            if (path) {
                promises.push(loadTexture(type, path));
            }
        });

        return Promise.all(promises);
    }

    // Modifications to the BoxModel class to increase spacing between inner and outer layers

    createBox() {
        // Box dimensions
        // const boxSize = 1.4;
        const { boxSize } = this;
        const totalHeight = boxSize;
        const topHeight = totalHeight * 0.15;
        const bottomHeight = totalHeight * 0.85;

        // Increase the wall thickness for more noticeable spacing
        const wallThickness = 0.05;

        // Additional inset for the inner walls to create more visible gap
        const innerInset = 0.02;

        // Create the glass material for the box
        const glassTopMaterial = new THREE.MeshPhysicalMaterial({
            metalness: 0.1,
            roughness: 0.1,
            transmission: 0.85,
            transparent: true,
            opacity: 0.85,
            reflectivity: 0.8,
            clearcoat: 1.0,
            clearcoatRoughness: 0.1,
            envMapIntensity: 1.5,
            ior: 1.5,
            side: THREE.DoubleSide,
            depthWrite: false,
        });

        const glassBottomMaterial = new THREE.MeshPhysicalMaterial({
            metalness: 0.4,
            roughness: 0.1,
            transmission: 0.8,
            transparent: true,
            opacity: 0.85,
            reflectivity: 0.7,
            clearcoat: 0.8,
            clearcoatRoughness: 0.2,
            envMapIntensity: 1.2,
            ior: 1.5,
            side: THREE.DoubleSide,
            depthWrite: false,
        });

        // Create interior material
        const interiorMaterial = new THREE.MeshStandardMaterial({
            metalness: 0.1,
            roughness: 0.1,
            envMapIntensity: 0.6,
            side: THREE.DoubleSide,
        });

        // Apply textures to materials if available
        if (this.boxTextures.diffuse) {
            interiorMaterial.map = this.boxTextures.diffuse;
        }

        if (this.boxTextures.normal) {
            interiorMaterial.normalMap = this.boxTextures.normal;
            interiorMaterial.normalScale = new THREE.Vector2(0.5, 0.5);
        }

        if (this.boxTextures.roughness) {
            interiorMaterial.roughnessMap = this.boxTextures.roughness;
        }

        if (this.boxTextures.metalness) {
            interiorMaterial.metalnessMap = this.boxTextures.metalness;
        }

        // 1. Create the top lid (outer)
        const boxTopGeometry = new THREE.BoxGeometry(
            boxSize,
            topHeight,
            boxSize,
        );
        this.boxLid = new THREE.Mesh(boxTopGeometry, glassTopMaterial);
        this.boxLid.position.y = bottomHeight + topHeight / 2;
        this.boxLid.castShadow = true;
        this.boxLid.receiveShadow = true;
        this.boxGroup.add(this.boxLid);

        // 2. Create the inner lid
        const innerBoxTop = new THREE.BoxGeometry(
            boxSize - wallThickness * 2,
            topHeight - wallThickness,
            boxSize - wallThickness * 2,
        );
        this.innerBoxLid = new THREE.Mesh(innerBoxTop, interiorMaterial);
        this.innerBoxLid.position.y = bottomHeight + topHeight / 2;
        this.innerBoxLid.castShadow = true;
        this.boxGroup.add(this.innerBoxLid);

        // 3. Create the outer bottom shell
        const boxBottomGeometry = new THREE.BoxGeometry(
            boxSize,
            bottomHeight,
            boxSize,
        );
        this.boxBottom = new THREE.Mesh(boxBottomGeometry, glassBottomMaterial);
        this.boxBottom.position.y = bottomHeight / 2;
        this.boxBottom.castShadow = true;
        this.boxBottom.receiveShadow = true;
        this.boxGroup.add(this.boxBottom);

        // 4. Create the hollow inner box (floor and walls)
        this.innerBoxWalls = new THREE.Group();
        this.boxGroup.add(this.innerBoxWalls);

        // Calculate inner dimensions with increased spacing
        const innerWidth = boxSize - (wallThickness * 2 + innerInset * 2);
        const innerDepth = boxSize - (wallThickness * 2 + innerInset * 2);

        // Floor of the box - make it thicker for better visibility
        const floorThickness = wallThickness * 1.2; // Thicker floor
        const floorGeometry = new THREE.BoxGeometry(
            innerWidth,
            floorThickness,
            innerDepth,
        );
        const floor = new THREE.Mesh(floorGeometry, interiorMaterial);
        floor.position.y = floorThickness / 2 + innerInset; // Raised slightly from the bottom
        this.innerBoxWalls.add(floor);

        // Wall height calculation - ensure they don't touch the lid
        const wallHeight = bottomHeight - floorThickness - innerInset * 1.5;

        // Left wall
        const leftWallGeometry = new THREE.BoxGeometry(
            wallThickness,
            wallHeight,
            innerDepth,
        );
        const leftWall = new THREE.Mesh(leftWallGeometry, interiorMaterial);
        leftWall.position.set(
            -(innerWidth / 2 + wallThickness / 2),
            wallHeight / 2 + floorThickness + innerInset,
            0,
        );
        this.innerBoxWalls.add(leftWall);

        // Right wall
        const rightWallGeometry = new THREE.BoxGeometry(
            wallThickness,
            wallHeight,
            innerDepth,
        );
        const rightWall = new THREE.Mesh(rightWallGeometry, interiorMaterial);
        rightWall.position.set(
            innerWidth / 2 + wallThickness / 2,
            wallHeight / 2 + floorThickness + innerInset,
            0,
        );
        this.innerBoxWalls.add(rightWall);

        // Front wall
        const frontWallGeometry = new THREE.BoxGeometry(
            innerWidth,
            wallHeight,
            wallThickness,
        );
        const frontWall = new THREE.Mesh(frontWallGeometry, interiorMaterial);
        frontWall.position.set(
            0,
            wallHeight / 2 + floorThickness + innerInset,
            innerDepth / 2 + wallThickness / 2,
        );
        this.innerBoxWalls.add(frontWall);

        // Back wall
        const backWallGeometry = new THREE.BoxGeometry(
            innerWidth,
            wallHeight,
            wallThickness,
        );
        const backWall = new THREE.Mesh(backWallGeometry, interiorMaterial);
        backWall.position.set(
            0,
            wallHeight / 2 + floorThickness + innerInset,
            -(innerDepth / 2 + wallThickness / 2),
        );
        this.innerBoxWalls.add(backWall);

        // Add frosted edge appearance
        this.addGlassFrostedEdge();

        // Add question marks to all sides with increased offset
        this.addQuestionMarks(boxSize, bottomHeight);

        // Add spotlight specifically for the box
        this.spotLight = new THREE.SpotLight(
            0xffffff,
            1.5,
            15,
            Math.PI / 4,
            0.5,
            1,
        );
        this.spotLight.position.set(0, 8, 0);
        this.spotLight.target = this.boxGroup;
        this.spotLight.castShadow = true;
        this.scene.add(this.spotLight);
    }

    addGlassFrostedEdge() {
        const boxSize = 1.4;

        // Create a highlighted edge effect
        const edgeGeometry = new THREE.BoxGeometry(
            boxSize + 0.05,
            0.03,
            boxSize + 0.05,
        );

        // Glowing edge material
        const glowEdgeMaterial = new THREE.MeshPhysicalMaterial({
            color: 0x00ccff,
            metalness: 0.2,
            roughness: 0.3,
            transmission: 0.6,
            transparent: true,
            opacity: 0.9,
            reflectivity: 0.4,
            emissive: 0x00ccff,
            emissiveIntensity: 0.6,
            depthWrite: false,
            side: THREE.DoubleSide,
        });

        this.glowMaterial = glowEdgeMaterial; // Store for animations

        const edgeMesh = new THREE.Mesh(edgeGeometry, glowEdgeMaterial);
        const totalHeight = 1.4;
        const bottomHeight = totalHeight * 0.85;
        edgeMesh.position.y = bottomHeight;
        edgeMesh.renderOrder = 1;
        this.boxGroup.add(edgeMesh);

        // Add to the animation cycle
        this.hexPatterns.push(edgeMesh);
    }

    createInnerGlow() {
        // Create inner glow effect for when box is opened
        const glowGeometry = new THREE.SphereGeometry(0.6, 32, 32);
        const glowMaterial = new THREE.MeshBasicMaterial({
            color: 0x40e0ff,
            transparent: true,
            opacity: 0,
            blending: THREE.AdditiveBlending,
        });

        this.innerGlow = new THREE.Mesh(glowGeometry, glowMaterial);
        this.innerGlow.position.y = 0.8;
        this.boxGroup.add(this.innerGlow);

        // Add volumetric light inside the box
        this.pointLight = new THREE.PointLight(0x40e0ff, 0, 5);
        this.pointLight.position.y = 0.5;
        this.boxGroup.add(this.pointLight);
    }

    // Modified question mark placement to ensure they appear correctly on each side
    addQuestionMarks(boxSize, bottomHeight) {
        // Increased offset to ensure question marks are not z-fighting with walls
        const offset = 0.02;

        // Create front question mark - adjusted position to be more visible
        this.questionMarks.front = createQuestionMark(
            0,
            bottomHeight / 2,
            boxSize / 2 + offset,
            0,
            boxSize,
        );
        this.boxGroup.add(this.questionMarks.front);

        // Create right side question mark
        this.questionMarks.right = createQuestionMark(
            boxSize / 2 + offset,
            bottomHeight / 2,
            0,
            Math.PI / 2,
            boxSize,
        );
        this.boxGroup.add(this.questionMarks.right);

        // Create back side question mark
        this.questionMarks.back = createQuestionMark(
            0,
            bottomHeight / 2,
            -boxSize / 2 - offset,
            Math.PI,
            boxSize,
        );
        this.boxGroup.add(this.questionMarks.back);

        // Create left side question mark
        this.questionMarks.left = createQuestionMark(
            -boxSize / 2 - offset,
            bottomHeight / 2,
            0,
            -Math.PI / 2,
            boxSize,
        );
        this.boxGroup.add(this.questionMarks.left);
    }

    // Add this method to BoxModel
    startOpeningAnimation(currentTime) {
        this.isOpen = true;
        this.lidOpeningStartTime = currentTime;
    }

    update(elapsed, delta, clock) {
        // Animate hex patterns - only if box is not open
        if (!this.isOpen) {
            this.hexPatterns.forEach((pattern, index) => {
                const pulseRate = 0.5 + (index % 7) * 0.15;
                const opacity = 0.8 + Math.sin(elapsed * pulseRate) * 0.4;

                if (pattern.material) {
                    pattern.material.opacity = opacity;

                    // Add color shifting for holographic effect
                    if (index % 3 === 0) {
                        const hue = (elapsed * 0.1 + index * 0.05) % 1;
                        pattern.material.color.setHSL(hue, 1, 0.5);
                    }
                }
            });
        } else {
            // When box is open, hide all hex patterns (including edge mesh)
            this.hexPatterns.forEach((pattern) => {
                if (pattern && pattern.material) {
                    pattern.visible = false;
                }
            });
        }

        // Animate question marks
        Object.values(this.questionMarks).forEach((questionMark, index) => {
            if (questionMark) {
                const time = elapsed;
                const speedOffset = index * 0.1;

                // Subtle pulsing effect with slightly different timing for each mark
                const pulseScale =
                    1.0 + Math.sin(time * (1.0 + speedOffset)) * 0.05;
                questionMark.scale.set(pulseScale, pulseScale, 1);

                // Subtle opacity pulsing
                if (questionMark.material) {
                    // Fixed: constrain the opacity range to avoid blinking
                    questionMark.material.opacity =
                        0.95 + Math.sin(time * (0.7 + speedOffset)) * 0.05;
                }
            }
        });

        // Animate bouncing if enabled and box is not open
        // if (this.bouncingEnabled && !this.isOpen) {
        //     const bounceY =
        //         Math.sin(elapsed * this.bouncingSpeed) * this.bouncingHeight;
        //
        //     // Set the box position for bounce effect
        //     this.boxGroup.position.y = this.initialBoxPosition + bounceY;
        //
        //     // Add subtle rotation during bounce
        //     this.boxGroup.rotation.x =
        //         Math.sin(elapsed * this.bouncingSpeed * 1.5) *
        //         this.bouncingRotation;
        //     this.boxGroup.rotation.z =
        //         Math.cos(elapsed * this.bouncingSpeed * 1.7) *
        //         this.bouncingRotation;
        // } else if (this.isOpen) {
        //     // Reset box position and rotation when open
        //     this.boxGroup.position.y = this.initialBoxPosition;
        //     this.boxGroup.rotation.x = 0;
        //     this.boxGroup.rotation.z = 0;
        // }
        if (this.bouncingEnabled && !this.isOpen) {
            const bounceY =
                Math.sin(elapsed * this.bouncingSpeed) * this.bouncingHeight;

            // Set the box position for bounce effect
            this.boxGroup.position.y = this.initialBoxPosition + bounceY;

            // ... rest of the method ...
        } else if (this.isOpen) {
            // Reset box position and rotation when open
            this.boxGroup.position.y = this.initialBoxPosition;
            // ... rest of the method ...
        }

        // Animate box lid opening if box is open
        if (this.isOpen) {
            // Get time since opening began
            const lidElapsed = elapsed - this.lidOpeningStartTime;

            // Update inner glow
            if (this.innerGlow) {
                this.innerGlow.material.opacity = 0.9;
            }
            if (this.pointLight) {
                this.pointLight.intensity = 4;
            }

            if (lidElapsed < this.lidOpeningDuration) {
                // Calculate progress ratio with smoother easing
                const openRatio = Math.min(
                    1,
                    lidElapsed / this.lidOpeningDuration,
                );

                // Use cubic bezier for natural movement
                const easedRatio = cubicBezier(0.4, 0.05, 0.1, 1, openRatio);

                // Calculate rotation angle
                const rotationAngle = -Math.PI * 0.7 * easedRatio;

                // Apply rotation to lid and inner lid
                this.boxLid.rotation.x = rotationAngle;
                this.innerBoxLid.rotation.x = rotationAngle;

                // Calculate base position values
                const boxSize = 1.4;
                const totalHeight = boxSize;
                const topHeight = totalHeight * 0.15;
                const bottomHeight = totalHeight * 0.85;
                const baseYPosition = bottomHeight + topHeight / 2;

                // Calculate lift and slide distances
                const liftHeight = 1.5 * easedRatio;
                const slideDistance = 0.8 * easedRatio;

                // Apply position transformations for both lids
                this.boxLid.position.y = baseYPosition + liftHeight;
                this.boxLid.position.z = -slideDistance;

                this.innerBoxLid.position.y = baseYPosition + liftHeight;
                this.innerBoxLid.position.z = -slideDistance;

                // Add natural wobble effect
                if (openRatio > 0.2 && openRatio < 0.8) {
                    const wobblePhase = lidElapsed * 5;
                    const wobbleIntensity = 0.01 * (1 - openRatio);

                    // Apply wobble to both lids
                    this.boxLid.rotation.x +=
                        Math.sin(wobblePhase) * wobbleIntensity;
                    this.boxLid.rotation.z =
                        Math.sin(wobblePhase * 0.7) * wobbleIntensity * 0.3;

                    this.innerBoxLid.rotation.x +=
                        Math.sin(wobblePhase) * wobbleIntensity;
                    this.innerBoxLid.rotation.z =
                        Math.sin(wobblePhase * 0.7) * wobbleIntensity * 0.3;
                }
            } else {
                // Final position once fully opened
                const boxSize = 1.4;
                const totalHeight = boxSize;
                const topHeight = totalHeight * 0.15;
                const bottomHeight = totalHeight * 0.85;
                const baseYPosition = bottomHeight + topHeight / 2;

                // Set final position for both lids
                this.boxLid.rotation.x = -Math.PI * 0.7;
                this.boxLid.position.y = baseYPosition + 1.5;
                this.boxLid.position.z = -0.8;

                this.innerBoxLid.rotation.x = -Math.PI * 0.7;
                this.innerBoxLid.position.y = baseYPosition + 1.5;
                this.innerBoxLid.position.z = -0.8;

                // Add subtle floating motion in final position
                const floatPhase = elapsed * 1.2;
                const floatY = Math.sin(floatPhase) * 0.01;
                const floatRotX = Math.sin(floatPhase * 0.8) * 0.005;
                const floatRotZ = Math.sin(floatPhase * 0.5) * 0.005;

                // Apply floating to both lids
                this.boxLid.position.y += floatY;
                this.boxLid.rotation.x += floatRotX;
                this.boxLid.rotation.z = floatRotZ;

                this.innerBoxLid.position.y += floatY;
                this.innerBoxLid.rotation.x += floatRotX;
                this.innerBoxLid.rotation.z = floatRotZ;
            }
        }
    }

    dispose() {
        // Dispose of geometries and materials to prevent memory leaks
        if (this.boxLid) this.disposeObject(this.boxLid);
        if (this.boxBottom) this.disposeObject(this.boxBottom);
        if (this.innerBoxLid) this.disposeObject(this.innerBoxLid);

        // Dispose inner walls components
        if (this.innerBoxWalls) {
            this.innerBoxWalls.children.forEach((child) => {
                this.disposeObject(child);
            });
            this.innerBoxWalls.clear();
        }

        if (this.innerGlow) this.disposeObject(this.innerGlow);

        // Dispose of question marks
        Object.values(this.questionMarks).forEach((mark) => {
            if (mark) this.disposeObject(mark);
        });

        // Dispose of hex patterns
        this.hexPatterns.forEach((pattern) => {
            if (pattern) this.disposeObject(pattern);
        });

        // Clear references
        this.boxLid = null;
        this.boxBottom = null;
        this.innerBoxLid = null;
        this.innerBoxWalls = null;
        this.innerGlow = null;
        this.questionMarks = {};
        this.hexPatterns = [];

        // Remove from scene
        if (this.boxGroup && this.scene) {
            this.scene.remove(this.boxGroup);
        }
    }

    disposeObject(obj) {
        if (obj.geometry) obj.geometry.dispose();
        if (obj.material) {
            if (Array.isArray(obj.material)) {
                obj.material.forEach((mat) => mat.dispose());
            } else {
                obj.material.dispose();
            }
        }
    }
}
