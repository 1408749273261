// src/components/MysteryBox/components/BoxRevealButton.vue
<template>
    <button class="reveal-button" @click="$emit('click')">
        <span class="reveal-text">{{ $t('openBlindBox') }}</span>
        <div class="button-glow"></div>
    </button>
</template>

<script>
export default {
    name: 'BoxRevealButton',
};
</script>

<style scoped>
/* Button styles from the original CSS */
.reveal-button {
    padding: 8px 12px;
    font-size: 12px;
    background: linear-gradient(135deg, #0a1a35 0%, #1e3a7b 50%, #0a1a35 100%);
    color: #00ffff;
    border: 2px solid #00ffff;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.4);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    z-index: 2;
}

.reveal-button.hide {
    opacity: 0;
    pointer-events: none;
}

.reveal-text {
    position: relative;
    z-index: 2;
}

.button-glow {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(0, 255, 255, 0.3),
        transparent
    );
    animation: buttonGlow 3s infinite;
}

@keyframes buttonGlow {
    0% {
        left: -100%;
    }
    50% {
        left: 100%;
    }
    100% {
        left: 100%;
    }
}

.reveal-button:before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: rgba(0, 255, 255, 0.1);
    transform: rotate(30deg);
    transition: transform 0.3s;
    z-index: 1;
}

.reveal-button:hover {
    transform: translateY(-3px) scale(1.03);
    box-shadow: 0 0 30px rgba(0, 255, 255, 0.6);
    background: linear-gradient(135deg, #0a1a35 0%, #2a4eb3 50%, #0a1a35 100%);
}

.reveal-button:hover:before {
    transform: rotate(30deg) translate(10%, 10%);
}

.reveal-button:active {
    transform: translateY(1px);
}
@media (min-height: 600px) {
    .reveal-button {
        padding: 12px 16px;
        font-size: 12px;
    }
}
@media (min-height: 700px) {
    .reveal-button {
        padding: 16px 32px;
        font-size: 14px;
    }
}
</style>
