var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table-container"},[(_vm.myHistory && _vm.myHistory.results)?_c('div',[_c('b-table',{staticClass:"esim-table",attrs:{"responsive":"","striped":"","hover":"","items":_vm.myHistory.results,"fields":_vm.fields},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('h4',{staticClass:"text-center my-3"},[_vm._v(" "+_vm._s(_vm.$t('noData'))+" ")])]},proxy:true},{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(createdAt)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(new Date(value).toLocaleString())+" ")]}},{key:"cell(amount)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"currency"},[_vm._v(" "+_vm._s(item.amount)+" "),(item.currency === 'xinCoin')?_c('span',[_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"alt":""}})]):_c('span',[_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"alt":""}})])])]}},{key:"cell(numberInfo)",fn:function(ref){
var value = ref.value;
var toggleDetails = ref.toggleDetails;
return [(value)?_c('div',{staticClass:"number-info"},[_c('div',[_vm._v(" Esim number: "),_c('span',[_vm._v(_vm._s(value.esimNumber))])]),_c('div',[_vm._v(" Seri code: "),_c('span',[_vm._v(_vm._s(value.serialCode))])]),_c('div',{staticClass:"qr"},[_vm._v(" QR: "),_c('b-form-checkbox',{on:{"change":toggleDetails},model:{value:(value.detailsShowing),callback:function ($$v) {_vm.$set(value, "detailsShowing", $$v)},expression:"value.detailsShowing"}},[_vm._v(" "+_vm._s(_vm.$t('showDetails'))+" ")])],1)]):_c('div',[_vm._v("No data")])]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"img-qr"},[_c('img',{attrs:{"src":item.numberInfo.qrCode,"alt":""}})])]}},{key:"cell(subscriptionInfo)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.code)+" ")]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
return [(value === 'ACTIVE')?_c('div',{staticClass:"text-active"},[_vm._v(" "+_vm._s(_vm.$t('activeEsimPlan'))+" ")]):_vm._e()]}}],null,false,1145367739)})],1):_vm._e(),(_vm.myHistory)?_c('Paginate',{attrs:{"totalPages":_vm.myHistory.totalPages},on:{"current":_vm.onChangePage}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }