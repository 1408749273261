// src/components/MysteryBox/MiniMysteryBox.vue
<template>
    <div class="mini-mystery-box-container">
        <!-- 3D scene container with explicit dimensions -->
        <div ref="threeMiniContainer" class="mini-three-container"></div>
    </div>
</template>

<script>
import ThreeService from '@/components/shared/MysteryBox/services/three.service';

export default {
    name: 'MiniMysteryBox',

    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        autoInitialize: {
            type: Boolean,
            default: true,
        },
        autoRotate: {
            type: Boolean,
            default: true,
        },
        rotationSpeed: {
            type: Number,
            default: 0.5,
        },
        width: {
            type: String,
            default: '100%',
        },
        height: {
            type: String,
            default: '100%',
        },
        showPlatform: {
            type: Boolean,
            default: true,
        },
        showBackground: {
            type: Boolean,
            default: true,
        },
        showFog: {
            type: Boolean,
            default: true,
        },
        boxSize: {
            type: Number,
            default: 1.4,
        },
        boxPositionY: {
            type: Number,
            default: 0.1,
        },
    },

    data() {
        return {
            threeService: null,
            resizeTimeout: null,
            isThreeJsInitialized: false,
        };
    },

    methods: {
        initializeThreeJs() {
            if (this.isThreeJsInitialized) return;

            // Create ThreeJS service with empty objects config
            this.threeService = new ThreeService(
                this.$refs.threeMiniContainer,
                {
                    onSceneInitialized: () => {
                        this.isThreeJsInitialized = true;
                        this.$emit('box-initialized', this.id);
                        this.$emit('box-revealed', this.id);
                    },
                    showPlatform: this.showPlatform,
                    showBackground: this.showBackground,
                    showFog: this.showFog,
                    boxSize: this.boxSize,
                    boxPositionY: this.boxPositionY,
                    onBoxClick: () => {
                        // Just an empty function since we don't need click handling
                    },
                },
            );

            // Configure auto-rotation settings
            if (this.threeService) {
                this.threeService.autoRotationEnabled = this.autoRotate;
                this.threeService.rotationSpeed = this.rotationSpeed;

                // Force a resize event to ensure proper dimensions
                this.threeService.onWindowResize();
            }
        },
        manualInitialize() {
            this.initializeThreeJs();
        },

        resetBox() {
            if (this.threeService) {
                this.threeService.resetBox();
            }
        },

        handleResizeWithDebounce() {
            if (this.resizeTimeout) {
                clearTimeout(this.resizeTimeout);
            }

            this.resizeTimeout = setTimeout(() => {
                // Ensure container size and then resize Three.js
                this.ensureContainerSize();
                if (this.threeService) {
                    this.threeService.onWindowResize();
                }
            }, 250);
        },
    },

    mounted() {
        window.addEventListener('resize', this.handleResizeWithDebounce);

        // Delay initialization slightly to ensure DOM is ready
        if (this.autoInitialize) {
            setTimeout(() => {
                this.initializeThreeJs();
            }, 100);
        }
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResizeWithDebounce);

        if (this.threeService) {
            this.threeService.cleanup();
            this.threeService = null;
        }
    },
};
</script>

<style scoped src="./styles/mysteryBox.css"></style>
