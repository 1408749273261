<template>
    <div class="container py-2 relative ambassador">
        <div class="title-card">Ambassador</div>
        <div class="star-container">
            <div class="star" v-for="star in 6" :key="star">
                <AnimatableStar
                    :active="star <= CurrentStar"
                    :duration="0.5"
                    :delay="0.2 * star"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AnimatableStar from '../icons/AnimatableStar.vue';

export default {
    name: 'AmbassadorHero',
    components: { AnimatableStar },
    computed: {
        ...mapGetters({
            Ambassador: 'ambassador/CurrentStar',
        }),
        CurrentStar() {
            return this.Ambassador?.currentStar ?? 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.title-card {
    text-align: center;
    font-size: clamp(14px, 10vw, calc(1920px / 10));
    font-family: 'ExtraBoldFutura';
    color: #f1f1f1;
    pointer-events: none;
    user-select: none;
    animation: fade-up 1s forwards;
    line-height: 1;
    @keyframes fade-up {
        0% {
            transform: translateY(100px);
            opacity: 0;
        }
        100% {
            transform: translateY(0px);
            opacity: 1;
        }
    }
}
.ambassador {
    background-image: radial-gradient(
        ellipse 100% 60% at center bottom,
        #d5f1ff 6%,
        transparent 40%
    );
    background-position: center 50%;
    background-size: 100%;
}
.star-container {
    color: #fdd835;
    display: flex;
    justify-content: center;
    .star {
        flex-basis: 80px;
    }
}
</style>
