<template>
    <div class="XIN bg-main-container">
        <div class="container-effect2">
            <!-- <div id="stars20"></div>
<div id="stars21"></div>
<div id="stars22"></div>
<div id="stars32"></div> -->
        </div>
        <Teleport to="body">
            <loader />
        </Teleport>

        <div class="grid-layout">
            <main id="mainContent">
                <StringBanner></StringBanner>
                <router-view />
            </main>
            <MyHeader id="header" />
            <MyNavigation id="navigation" />
        </div>
        <NotificationModal />
        <ManaCenter />
        <ManaTopup />
        <ManaTransfer />
        <UpdateSponsor></UpdateSponsor>
        <UpdateEmail></UpdateEmail>
        <UpdatePhone></UpdatePhone>
        <CancelBill />
        <BlindBoxModal />
        <FreeNFT />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
// import Web3 from 'web3';
import loader from '@/components/shared/Loader.vue';
import Teleport from 'vue2-teleport';
import MyHeader from '@/components/Layouts/MyHeader.vue';
import MyNavigation from '@/components/Layouts/MyNavigation.vue';
import NotificationModal from '@/components/shared/NotificationModal.vue';
import ManaCenter from '@/components/modal/ManaCenter.vue';
import ManaTopup from '@/components/modal/ManaTopup.vue';
import ManaTransfer from '@/components/modal/ManaTransfer.vue';
import UpdateSponsor from '@/components/account/UpdateSponsor.vue';
import UpdatePhone from '@/components/account/UpdatePhone.vue';
import UpdateEmail from '@/components/account/UpdateEmail.vue';
import StringBanner from '@/components/shared/StringBanner.vue';
import CancelBill from '@/components/utilities/CancelBill.vue';
import BlindBoxModal from '@/components/shared/BlindBoxModal.vue';
import FreeNFT from '../../components/shared/FreeNFT.vue';

export default {
    components: {
        loader,
        Teleport,
        MyHeader,
        MyNavigation,
        NotificationModal,
        UpdateSponsor,
        StringBanner,
        ManaCenter,
        ManaTopup,
        ManaTransfer,
        CancelBill,
        UpdatePhone,
        UpdateEmail,
        FreeNFT,
        BlindBoxModal,
    },
    computed: {
        ...mapGetters({
            isNetWork: 'contract/isNetWork',
            UnKnown: 'info/UnKnown',
            UserInfo: 'info/UserInfo',
            IsMerchant: 'merchant/IsMerchant',
            SSEConnected: 'merchant/SSEConnected',
        }),

        shouldSetupSSE() {
            return this.IsMerchant === true && this.SSEConnected === false;
        },

        ChainID() {
            if (this.isNetWork === 'testnet') {
                return ['0x61', '97'];
            }
            return ['0x38', '56'];
        },
        netWorkConfig() {
            const testNet = {
                chainId: '0x61',
                chainName: 'Smart Chain - Testnet',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://data-seed-prebsc-2-s2.binance.org:8545/',
                    'https://data-seed-prebsc-1-s1.binance.org:8545/',
                    'https://data-seed-prebsc-5-s5.binance.org:8545/',
                    'https://data-seed-prebsc-3-s3.binance.org:8545/',
                    'https://data-seed-prebsc-4-s4.binance.org:8545/',
                ],
                blockExplorerUrls: ['https://testnet.bscscan.com'],
            };
            const mainNet = {
                chainId: '0x38',
                chainName: 'Smart Chain',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://bsc-dataseed.binance.org/',
                    'https://bsc-dataseed1.defibit.io/',
                    'https://bsc-dataseed1.ninicoin.io/',
                ],
                blockExplorerUrls: ['https://bscscan.com'],
            };
            if (this.isNetWork === 'testnet') {
                return testNet;
            }
            return mainNet;
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        ...mapActions({
            setupSSE: 'merchant/setupSSE',
            disconnectSSE: 'merchant/disconnectSSE',
        }),
    },
    // watch: {
    //     IsMerchant(newValue) {
    //         console.log(this.SSEConnected);
    //         if (newValue) {
    //             this.setupSSE();
    //         }
    //     },
    // },
    // watch: {
    //     shouldSetupSSE(newValue) {
    //         console.log('SSE connection status check:', {
    //             IsMerchant: this.IsMerchant,
    //             SSEConnected: this.SSEConnected,
    //         });

    //         if (newValue) {
    //             this.setupSSE();
    //         }
    //     },
    // },
    mounted() {
        // if (this.IsMerchant) {
        //     this.setupSSE();
        // }
        // this.$watch('IsMerchant', function (newValue) {
        //     console.log(newValue);
        //     if (newValue) {
        //         this.setupSSE();
        //     }
        // });
        this.$store.dispatch('wallet/get_rates');
        this.$store.dispatch('blindbox/getMysteryBox');

        const userRaw = this.$store.getters['auth/loggedIn'];
        if (userRaw !== undefined && userRaw !== null) {
            this.$store.dispatch('info/req_getInfo').then((res) => {
                if (res.status) {
                    this.$store.dispatch('missions/get_missions');
                    this.$store.dispatch('info/get_masterXNode');
                    this.$store.dispatch('merchant/getAccountMerchant').then(res => {
                        if(res.status) {
                            this.setupSSE();
                        }
                    });
                    if (
                        this.UserInfo &&
                        this.UserInfo.sponsors &&
                        this.UserInfo.sponsors.length === 1
                    ) {
                        this.$bvModal.show('update-sponsor');
                    } else {
                        console.log('Already have sponsor');
                    }
                }
            });
            this.$store.dispatch('info/get_aboutXnode');
            this.$store.dispatch('mining/get_nodeInfo');
            this.$store.dispatch('promotion/getPromotion', {
                type: 'nft',
                page: 1,
                limit: 10,
            });
            this.$store.dispatch('promotion/getPromotion', {
                type: 'node',
                page: 1,
                limit: 10,
            });
        }
    },
    // beforeDestroy() {
    //     if (this.IsMerchant) {
    //         this.disconnectSSE();
    //     }
    // },
};
</script>

<style lang="scss">
.XIN.bg-main-container {
    background-color: #fdfdfd;
}

.grid-layout {
    display: grid;
    grid-template-areas:
        'header'
        'content';
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto minmax(0, 1fr);

    min-height: 100vh;

    #header {
        grid-area: header;
    }

    #mainContent {
        grid-area: content;
        padding-bottom: 80px;
        overflow: hidden;
        background-image: url('~@/assets/images/mockup/pattern.png');
    }

    // @media (min-width: 768px) {

    //     grid-template-columns: minmax(0, 250px) minmax(0, 1fr);

    // }

    @media (min-width: 1200px) {
        grid-template-areas:
            'navi header'
            'navi content';

        #navigation {
            grid-area: navi;
        }

        grid-template-columns: minmax(0, 250px) minmax(0, 1fr);
    }
}
</style>
