var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
        _vm.MyPromotion &&
        _vm.MyPromotion.results &&
        _vm.MyPromotion.results.length > 0 &&
        _vm.BannerAvailable
    )?_c('div',{staticClass:"event-banners"},[_c('VueSlickCarousel',_vm._b({scopedSlots:_vm._u([{key:"prevArrow",fn:function(){return [_c('button',{staticClass:"arrow-botan"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"currentColor","d":"M15.5 5H11l5 7l-5 7h4.5l5-7z"}}),_c('path',{attrs:{"fill":"currentColor","d":"M8.5 5H4l5 7l-5 7h4.5l5-7z"}})])])]},proxy:true},{key:"nextArrow",fn:function(){return [_c('button',{staticClass:"arrow-botan"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"currentColor","d":"M15.5 5H11l5 7l-5 7h4.5l5-7z"}}),_c('path',{attrs:{"fill":"currentColor","d":"M8.5 5H4l5 7l-5 7h4.5l5-7z"}})])])]},proxy:true},{key:"customPaging",fn:function(){return [_c('button',{staticClass:"my-dot"})]},proxy:true}],null,false,2910718925)},'VueSlickCarousel',_vm.settings,false),[[(_vm.$root.$i18n.locale === 'vi')?[_c('div',{key:"vietBlindBox",staticClass:"my-slide",on:{"click":function($event){return _vm.$router.push({ name: 'CorpoPackage' })}}},[_c('img',{staticClass:"d-none d-md-inline event-banner",attrs:{"draggable":"false","src":require("@/assets/images/mockup/blindBoxPromoVietLong.png")}}),_c('img',{staticClass:"d-md-none event-banner",attrs:{"draggable":"false","src":require("@/assets/images/mockup/blindBoxPromoViet.png")}})])]:[_c('div',{key:"blindBox",staticClass:"my-slide",on:{"click":function($event){return _vm.$router.push({ name: 'CorpoPackage' })}}},[_c('img',{staticClass:"d-none d-md-inline event-banner",attrs:{"draggable":"false","src":require("@/assets/images/mockup/blindBoxPromoLong.png")}}),_c('img',{staticClass:"d-md-none event-banner",attrs:{"draggable":"false","src":require("@/assets/images/mockup/blindBoxPromo.png")}})])]]],2),(
            _vm.MyPromotion &&
            _vm.MyPromotion.results &&
            _vm.MyPromotion.results.length > 0
        )?_c('AirdropModal',{attrs:{"promotion":_vm.chosenPromotion}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }