const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        currentStar: undefined,
    }),
    getters: {
        CurrentStar: (state) => state.currentStar,
    },
    actions: {
        async getAmbassadorStar({ commit }) {
            return axios.get('ambassador/current-star').then((res) => {
                if (res && res.status) {
                    commit('SET_CURRENT_STAR', res.data);
                }
            });
        },
    },
    mutations: {
        SET_CURRENT_STAR(state, data) {
            state.currentStar = data;
        },
    },
};
