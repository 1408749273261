<template>
    <div class="py-2 lmao">
        <AmbassadorRankingTable />
        <AmbassadorHero />
        <AmbassadorStat />
        <AmbassadorCommissionHistory />
    </div>
</template>

<script>
import AmbassadorCommissionHistory from '@/components/ambassador/AmbassadorCommissionHistory.vue';
import AmbassadorHero from '../../components/ambassador/AmbassadorHero.vue';
import AmbassadorStat from '../../components/ambassador/AmbassadorStat.vue';
import AmbassadorRankingTable from '../../components/ambassador/AmbassadorRankingTable.vue';

export default {
    name: 'AmbassadorView',
    // eslint-disable-next-line vue/no-unused-components
    components: {
        AmbassadorHero,
        AmbassadorStat,
        AmbassadorRankingTable,
        AmbassadorCommissionHistory,
    },
    mounted() {
        this.$store.dispatch('ambassador/getAmbassadorStar');
    },
};
</script>

<style lang="scss" scoped></style>
