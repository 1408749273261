<template>
    <div class="ambassador-ranking-container container py-2">
        <div class="ambassador-header">{{ $t('ambassadorInformation') }}</div>
        <div class="base-table">
            <div v-if="dataAmbassador.length > 0">
                <b-table
                    :fields="fields"
                    :items="dataAmbassador"
                    class="ambassador-table"
                    hover
                    responsive
                    striped
                >
                    <template #empty>
                        <h4 class="text-center my-3">
                            {{ $t('noData') }}
                        </h4>
                    </template>

                    <template #head()="{ label }">
                        {{ $t(label) }}
                    </template>

                    <template #cell(other)="data">
                        <div
                            class="icon-container"
                            @click="
                                showMiscellaneousModal(data.item.ambassadorRank)
                            "
                        >
                            <IconEye />
                        </div>
                    </template>

                    <template #cell(reward)="data">
                        <div
                            class="icon-container"
                            @click="showRewardModal(data.item.ambassadorRank)"
                        >
                            <IconTrophy />
                        </div>
                    </template>

                    <template #cell(ambassador)="data">
                        <div v-html="data.value"></div>
                    </template>

                    <template #cell(ambassadorF1)="data">
                        <div v-html="data.value"></div>
                    </template>
                </b-table>
            </div>
            <div v-else>
                <b-table
                    class="ambassador-table"
                    hover
                    responsive
                    show-empty
                    striped
                >
                    <template #empty>
                        <div class="no-data">{{ $t('noData') }}</div>
                    </template>
                </b-table>
            </div>
        </div>

        <!-- Miscellaneous Modal -->
        <b-modal
            id="modal-miscellaneous"
            centered
            hide-footer
            title="Training & Meetings"
        >
            <div v-if="selectedRankData">
                <h5>Khóa đào tạo</h5>
                <ul class="pl-3">
                    <li
                        v-for="course in selectedRankData.miscellaneous
                            .trainingCourses"
                        :key="course.id"
                        class="mb-2"
                    >
                        <strong>{{ course.name }}</strong>
                        <div v-if="course.duration" class="text-muted">
                            {{ course.duration }}
                        </div>
                    </li>
                </ul>

                <h5 class="mt-4">Tham gia Zoom</h5>
                <div>
                    <div>
                        {{
                            selectedRankData.miscellaneous.meetings.description
                        }}
                    </div>
                    <div>
                        <strong>Bắt buộc tham gia:</strong>
                        {{
                            selectedRankData.miscellaneous.meetings
                                .minimumAttendance
                        }}
                    </div>
                </div>
            </div>
        </b-modal>

        <!-- Reward Modal -->
        <b-modal id="modal-reward" centered hide-footer title="Rewards">
            <div v-if="selectedRankData && selectedRankData.reward">
                <div v-if="selectedRankData.reward.eventTicket" class="mb-3">
                    <h5>Vé tham gia sự kiện</h5>
                    <div>
                        {{ selectedRankData.reward.eventTicket.description }}
                    </div>
                    <div class="text-muted">
                        {{ selectedRankData.reward.eventTicket.frequency }}
                    </div>
                </div>

                <div
                    v-if="selectedRankData.reward.revenuePercentage"
                    class="mb-3"
                >
                    <h5>Phần trăm doanh thu</h5>
                    <div>
                        {{
                            selectedRankData.reward.revenuePercentage
                                .description
                        }}
                    </div>
                    <div
                        v-if="selectedRankData.reward.revenuePercentage.details"
                        class="text-muted"
                    >
                        {{ selectedRankData.reward.revenuePercentage.details }}
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import IconEye from '@/components/ambassador/icons/IconEye.vue';
import IconTrophy from '@/components/ambassador/icons/IconTrophy.vue';
// eslint-disable-next-line import/extensions
import rankingData from './utils.js';
import { formatAmbassadorData } from './ambassadorDataFormat';

export default {
    name: 'AmbassadorRankingTable',
    data() {
        return {
            fields: [
                {
                    key: 'ambassador',
                    label: 'ambassador',
                    thClass: 'text-center text-white',
                    tdClass: 'text-center',
                },
                {
                    key: 'personalNode',
                    label: 'personalNode',
                    thClass: 'text-center text-white',
                    tdClass: 'text-center',
                },
                {
                    key: 'totalStaking',
                    label: 'totalStaking',
                    thClass: 'text-center text-white',
                    tdClass: 'text-center',
                },
                {
                    key: 'f1MonthlyKPIs',
                    label: 'kpiF1Month',
                    thClass: 'text-center text-white',
                    tdClass: 'text-center',
                },
                {
                    key: 'groupMonthlyKPIs',
                    label: 'kpiGroupMonth',
                    thClass: 'text-center text-white',
                    tdClass: 'text-center',
                },
                {
                    key: 'ambassadorF1',
                    label: 'ambassadorF1',
                    thClass: 'text-center text-white',
                    tdClass: 'text-center',
                },
                {
                    key: 'other',
                    label: 'otherCondition',
                    thClass: 'text-center text-white',
                    tdClass: 'text-center',
                },
                {
                    key: 'reward',
                    label: 'reward',
                    thClass: 'text-center text-white',
                    tdClass: 'text-center',
                },
            ],
            dataAmbassador: [],
            selectedRankData: null,
            originalRankingData: rankingData,
        };
    },
    components: {
        IconEye,
        IconTrophy,
    },
    created() {
        const formattedData = formatAmbassadorData();

        this.dataAmbassador = Object.entries(rankingData).map(
            // eslint-disable-next-line no-unused-vars
            ([rank, _], index) => {
                return {
                    ...formattedData[index],
                    ambassadorRank: rank, // Store the original rank for accessing data
                };
            },
        );
    },
    methods: {
        showMiscellaneousModal(rank) {
            this.selectedRankData = this.originalRankingData[rank];
            this.$bvModal.show('modal-miscellaneous');
        },
        showRewardModal(rank) {
            this.selectedRankData = this.originalRankingData[rank];
            this.$bvModal.show('modal-reward');
        },
    },
};
</script>

<style lang="scss" scoped>
.ambassador-ranking-container {
    // padding: 16px 32px;

    .ambassador-header {
        font-size: 24px;
        font-weight: bold;
        color: #107fb9;
        margin-bottom: 8px;
    }
}

.ambassador-table th {
    font-weight: bold;
}
// .base-table .ambassador-table table tbody tr td > div:not(.details){
//     height: auto;
// }
// .base-table .ambassador-table table tbody tr {
//     height: auto;
// }

.no-data {
    text-align: center;
    padding: 20px;
}

.icon-container {
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.icon-container:hover {
    opacity: 0.8;
}
</style>
