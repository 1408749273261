// src/components/MysteryBox/models/questionMark.model.js
import * as THREE from 'three';

/**
 * Create a question mark mesh for the box
 *
 * @param {number} x - X position
 * @param {number} y - Y position
 * @param {number} z - Z position
 * @param {number} rotationY - Y-axis rotation in radians
 * @param {number} boxSize - Size of the box for scaling
 * @returns {THREE.Mesh} - Question mark mesh
 */
export const createQuestionMark = (x, y, z, rotationY, boxSize) => {
    // Create canvas for the question mark texture
    const canvas = document.createElement('canvas');
    canvas.width = 1024;
    canvas.height = 1024;
    const context = canvas.getContext('2d');

    // Fill with transparent background
    context.clearRect(0, 0, 1024, 1024);

    // Enhanced glow effect for better visibility
    context.shadowColor = '#40ffff';
    context.shadowBlur = 100;

    // Draw question mark - larger and brighter
    context.font = 'bold 520px Arial';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillStyle = '#ffffff';
    context.fillText('?', 512, 512);

    // Multiple passes for enhanced brightness
    for (let i = 0; i < 4; i++) {
        context.shadowBlur = 50 - i * 10;
        context.fillStyle = '#ffffff';
        context.fillText('?', 512, 512);
    }

    // Create texture from canvas
    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true;

    // Create material with higher opacity
    const questionMarkMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        opacity: 1.0,
        depthWrite: false,
        blending: THREE.AdditiveBlending,
        side: THREE.DoubleSide,
    });

    // Create a question mark with proper size
    const planeSize = boxSize * 0.7;
    const plane = new THREE.PlaneGeometry(planeSize, planeSize);
    const questionMark = new THREE.Mesh(plane, questionMarkMaterial);

    // Position
    questionMark.position.set(x, y, z);

    // Apply rotation if provided
    if (rotationY !== 0) {
        questionMark.rotation.y = rotationY;
    }

    // Add point light for enhanced visibility
    const light = new THREE.PointLight(0x00ffff, 2.0, 1.0);
    light.position.set(x, y, z);
    questionMark.userData.light = light;
    questionMark.add(light);

    return questionMark;
};
