// src/components/MysteryBox/components/LoadingOverlay.vue
<template>
    <div class="loading-overlay">
        <div class="loading-spinner"></div>
        <div class="loading-text">{{ $t('openingBox') }}...</div>
    </div>
</template>

<script>
export default {
    name: 'LoadingOverlay',
};
</script>

<style scoped>
.loading-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 20, 40, 0.8);
    padding: 20px 30px;
    border-radius: 10px;
    border: 2px solid #00aaff;
    z-index: 20;
    pointer-events: none;
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #00ffff;
    animation: spin 1s linear infinite;
    margin-bottom: 15px;
}

.loading-text {
    color: #00ffff;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    text-shadow: 0 0 8px rgba(0, 255, 255, 0.6);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
