import { render, staticRenderFns } from "./AmbassadorCommissionHistory.vue?vue&type=template&id=d36d804a&scoped=true&"
import script from "./AmbassadorCommissionHistory.vue?vue&type=script&lang=js&"
export * from "./AmbassadorCommissionHistory.vue?vue&type=script&lang=js&"
import style0 from "./AmbassadorCommissionHistory.vue?vue&type=style&index=0&id=d36d804a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d36d804a",
  null
  
)

export default component.exports