<template>
    <div class="pagination-container">
        <button
            class="prev-btn"
            @click="handlePrevPage"
            :disabled="!checkEnablePrevPage"
        >
            <ArrowLeftIcon
                :color="checkEnablePrevPage ? '#0087CC' : '#A4A4A4'"
            />
        </button>
        <p class="pagination-page">{{ page }}</p>
        <button
            class="next-btn"
            @click="handleNextPage"
            :disabled="!checkEnableNextPage"
        >
            <ArrowRightIcon
                :color="checkEnableNextPage ? '#0087CC' : '#A4A4A4'"
            />
        </button>
    </div>
</template>
<script>
import ArrowLeftIcon from '../icons/ArrowLeftIcon.vue';
import ArrowRightIcon from '../icons/ArrowRightIcon.vue';

export default {
    name: 'Pagination',
    components: { ArrowLeftIcon, ArrowRightIcon },
    props: {
        page: Number,
        totalPages: Number,
        totalResults: Number,
        handlePrevPage: Function,
        handleNextPage: Function,
    },
    computed: {
        checkEnableNextPage() {
            return Number(this.totalPages) > Number(this.page);
        },

        checkEnablePrevPage() {
            return Number(this.page) > 1;
        },
    },
};
</script>
<style lang="scss">
.pagination-container {
    display: flex;
    gap: 10px;
    align-items: center;

    .pagination-page {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    .prev-btn:disabled,
    .next-btn:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
</style>
