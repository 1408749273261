// src/components/MysteryBox/components/ItemNotification.vue
<template>
    <div class="items-notification">
        <div class="items-container">
            <div v-if="gift" class="item-card item-card-highlight">
                <div class="item-image-container">
                    <img
                        :alt="gift.name"
                        :src="gift.photoUrl"
                        class="item-image"
                    />
                    <div class="item-shine"></div>
                </div>
                <div class="item-details">
                    <div class="item-name">
                        {{ gift.name }}
                        <div class="item-value">
                            {{
                                Number(gift.giftValue).toLocaleString('en-US')
                            }}
                            USDT
                        </div>
                    </div>
                    <div class="item-rarity rarity-ultra-rare">XNODE</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap';

export default {
    name: 'ItemNotification',
    props: {
        gift: {
            type: Object,
            default: null,
        },
    },
    mounted() {
        this.$nextTick(() => {
            // Clear any existing animations on the cards that might be stuck
            gsap.killTweensOf('.item-card');

            // Apply new animations with proper completion values
            const itemCards = document.querySelectorAll('.item-card');
            itemCards.forEach((card, index) => {
                gsap.fromTo(
                    card,
                    {
                        y: -50,
                        opacity: 0,
                    },
                    {
                        y: 0,
                        opacity: 1, // Ensure it ends at full opacity
                        delay: 0.2 + index * 0.15,
                        duration: 0.8,
                        ease: 'back.out(1.7)',
                        clearProps: 'all', // Very important - clears inline styles after animation completes
                    },
                );
            });
        });
    },
};
</script>

<style src="../styles/items.css"></style>
