<template>
    <div>
        <div class="table-container">
            <div v-if="myHistory && myHistory.results">
                <b-table
                    class="esim-table"
                    responsive
                    striped
                    hover
                    :items="myHistory.results"
                    :fields="fields"
                >
                    <template #empty>
                        <h4 class="text-center my-3">
                            {{ $t('noData') }}
                        </h4>
                    </template>
                    <template #head()="{ label }">
                        {{ $t(label) }}
                    </template>
                    <template #cell(createdAt)="{ value }">
                        {{ new Date(value).toLocaleString() }}
                    </template>
                    <!-- <template #cell(currency)="{ value }">
                        <div v-if="value === 'xinCoin'">XIN</div>
                        <div v-else>USDT</div>
                    </template> -->
                    <template #cell(amount)="{ item }">
                        <div class="currency">
                            {{ item.amount }}
                            <span v-if="item.currency === 'xinCoin'">
                                <img
                                    src="@/assets/images/logo/xin.png"
                                    alt=""
                                />
                            </span>
                            <span v-else>
                                <img
                                    src="@/assets/images/logo/usdt.png"
                                    alt=""
                                />
                            </span>
                        </div>
                    </template>
                    <template #cell(numberInfo)="{ value, toggleDetails }">
                        <div class="number-info" v-if="value">
                            <div>
                                Esim number: <span>{{ value.esimNumber }}</span>
                            </div>
                            <div>
                                Seri code: <span>{{ value.serialCode }}</span>
                            </div>
                            <div class="qr">
                                QR:
                                <b-form-checkbox
                                    v-model="value.detailsShowing"
                                    @change="toggleDetails"
                                >
                                    {{ $t('showDetails') }}
                                </b-form-checkbox>
                            </div>
                        </div>
                        <div v-else>No data</div>
                    </template>
                    <template #row-details="{ item }">
                        <div class="img-qr">
                            <img :src="item.numberInfo.qrCode" alt="" />
                        </div>
                    </template>
                    <template #cell(subscriptionInfo)="{ value }">
                        {{ value.code }}
                    </template>
                    <template #cell(status)="{ value }">
                        <div class="text-active" v-if="value === 'ACTIVE'">
                            {{ $t('activeEsimPlan') }}
                        </div>
                    </template>
                </b-table>
            </div>

            <Paginate
                v-if="myHistory"
                @current="onChangePage"
                :totalPages="myHistory.totalPages"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Paginate from '@/components/shared/Paginate.vue';

export default {
    components: {
        Paginate,
    },
    data() {
        return {
            page: 1,
            limit: 10,
            fields: [
                {
                    title: 'Order ID',
                    dataIndex: 'refId',
                    key: 'refId',
                    label: 'refId',
                },
                {
                    title: 'Package name',
                    dataIndex: 'subscriptionInfo',
                    key: 'subscriptionInfo',
                    label: 'packageName',
                },
                {
                    title: 'Price',
                    dataIndex: 'amount',
                    key: 'amount',
                    label: 'amount',
                },
                {
                    title: 'Number Info',
                    dataIndex: 'numberInfo',
                    key: 'numberInfo',
                    label: 'numberInfo',
                },
                {
                    title: 'Created At',
                    key: 'createdAt',
                    dataIndex: 'createdAt',
                    label: 'createdAt',
                },
                {
                    title: 'Status',
                    key: 'status',
                    dataIndex: 'status',
                    label: 'status',
                },
                {
                    title: 'Detail',
                    key: 'detail',
                    dataIndex: 'detail',
                    label: 'detail',
                },
            ],

            myHistory: null,
        };
    },

    computed: {
        ...mapGetters({
            EsimDataHistory: 'nft/EsimDataHistory',
        }),
    },

    methods: {
        reqList() {
            this.$store
                .dispatch('nft/getHistorySubscription', {
                    page: this.page,
                    limit: this.limit,
                })
                .then((res) => {
                    if (res) {
                        this.myHistory = res;
                    }
                });
        },

        onChangePage(payload) {
            this.page = payload;
            this.reqList();
        },
    },

    mounted() {
        this.reqList();
    },
};
</script>

<style lang="scss" scoped>
.table-container {
    background: #1478b0;
    border: 1px solid #d8d8d8;
    border-radius: 20px;
    padding: 1.5rem 0.5rem;
    margin-top: 1rem;
    color: #fff;

    @media (min-width: 768px) {
        padding: 2rem;
    }

    h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 1.5rem;
    }

    table {
        @media (max-width: 769px) {
            overflow-x: auto;
        }

        .currency {
            img {
                width: 20px;
            }
        }

        .number-info {
            .qr {
                display: flex;
                gap: 20px;
            }
        }

        .img-qr {
            display: flex;
            justify-content: center;

            img {
                width: 240px;
            }
        }
    }

    .text-active {
        font-weight: 600;
        color: #78dc78;
    }

    .btn-info {
        color: #fff;
    }

    .no-data {
        font-size: 30px;
        text-align: center;
        font-weight: 600;
    }
}
</style>

<style lang="scss">
.esim-table {
    th {
        white-space: nowrap;
    }
    tbody {
        tr:hover {
            color: inherit;
        }
    }
    .status {
        background: #dc2626;
        padding: 2px 8px;
        border-radius: 6px;
        font-size: 16px;
        svg {
            vertical-align: text-bottom;
        }

        &.active {
            background: #22c55e;
            color: #fff;
        }
    }

    thead,
    tbody {
        color: #fff;
    }
    tbody td {
        vertical-align: middle;
    }
}

.default-nft {
    .modal-content {
        border-radius: 22px;
        .modal-body {
            padding: 2rem 1rem;
            border-radius: 19px;

            @media (max-width: 414px) {
                padding: 1rem;
            }
        }
    }
}

#active-esim {
    .modal-body {
        border-radius: 12px;
        img {
            width: 100%;
        }

        // svg {
        //     font-size: 30px;
        //     color: black;
        // }
    }
}
</style>
