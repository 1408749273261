<template>
    <div
        v-if="
            MyPromotion &&
            MyPromotion.results &&
            MyPromotion.results.length > 0 &&
            BannerAvailable
        "
        class="event-banners"
    >
        <VueSlickCarousel v-bind="settings">
            <!-- <template v-for="(promotion, index) in MyPromotion.results">
                <div
                    v-if="promotion.promotionId === 11"
                    class="my-slide"
                    @click="goToNFT"
                    :key="`11${index}`"
                >
                    <img
                        draggable="false"
                        class="d-none d-md-inline event-banner"
                        src="@/assets/images/mockup/xnftPromoLong.png"
                    />
                    <img
                        draggable="false"
                        class="d-md-none event-banner"
                        src="@/assets/images/mockup/xnftPromo.png"
                    />
                </div>
                <div
                    v-else-if="promotion.promotionId === 10"
                    class="my-slide"
                    @click="goToCorpoNode"
                    :key="`12${index}`"
                >
                    <img
                        draggable="false"
                        class="d-none d-md-inline event-banner"
                        src="@/assets/images/mockup/corpoPromoLong.png"
                    />
                    <img
                        draggable="false"
                        class="d-md-none event-banner"
                        src="@/assets/images/mockup/corpoPromo.png"
                    />
                </div>
                <div
                    v-else-if="promotion.promotionId === 17"
                    class="my-slide"
                    @click="$router.push({ name: 'BuyNFT' })"
                    :key="`17${index}`"
                >
                    <img
                        draggable="false"
                        class="d-none d-md-inline event-banner"
                        src="@/assets/images/mockup/esimXmasLong.png"
                    />
                    <img
                        draggable="false"
                        class="d-md-none event-banner"
                        src="@/assets/images/mockup/esimXmas.png"
                    />
                </div>
                <div
                    v-else-if="promotion.promotionId === 20"
                    class="my-slide"
                    @click="$router.push({ name: 'CorpoPackage' })"
                    :key="`20${index}`"
                >
                    <img
                        draggable="false"
                        class="d-none d-md-inline event-banner"
                        src="@/assets/images/mockup/businessPromoLong.png"
                    />
                    <img
                        draggable="false"
                        class="d-md-none event-banner"
                        src="@/assets/images/mockup/businessPromo.png"
                    />
                </div>
            </template> -->
            <template>
                <template v-if="$root.$i18n.locale === 'vi'">
                    <div
                        class="my-slide"
                        @click="$router.push({ name: 'CorpoPackage' })"
                        :key="`vietBlindBox`"
                    >
                        <img
                            draggable="false"
                            class="d-none d-md-inline event-banner"
                            src="@/assets/images/mockup/blindBoxPromoVietLong.png"
                        />
                        <img
                            draggable="false"
                            class="d-md-none event-banner"
                            src="@/assets/images/mockup/blindBoxPromoViet.png"
                        />
                    </div>
                </template>

                <template v-else>
                    <div
                        class="my-slide"
                        @click="$router.push({ name: 'CorpoPackage' })"
                        :key="`blindBox`"
                    >
                        <img
                            draggable="false"
                            class="d-none d-md-inline event-banner"
                            src="@/assets/images/mockup/blindBoxPromoLong.png"
                        />
                        <img
                            draggable="false"
                            class="d-md-none event-banner"
                            src="@/assets/images/mockup/blindBoxPromo.png"
                        />
                    </div>
                </template>
            </template>
            <template #prevArrow>
                <button class="arrow-botan">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M15.5 5H11l5 7l-5 7h4.5l5-7z"
                        />
                        <path
                            fill="currentColor"
                            d="M8.5 5H4l5 7l-5 7h4.5l5-7z"
                        />
                    </svg>
                </button>
            </template>
            <template #nextArrow>
                <button class="arrow-botan">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M15.5 5H11l5 7l-5 7h4.5l5-7z"
                        />
                        <path
                            fill="currentColor"
                            d="M8.5 5H4l5 7l-5 7h4.5l5-7z"
                        />
                    </svg>
                </button>
            </template>
            <template #customPaging>
                <button class="my-dot"></button>
            </template>
        </VueSlickCarousel>
        <AirdropModal
            v-if="
                MyPromotion &&
                MyPromotion.results &&
                MyPromotion.results.length > 0
            "
            :promotion="chosenPromotion"
        ></AirdropModal>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import AirdropModal from '@/components/shared/AirdropModal.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'EventBanners',
    components: {
        VueSlickCarousel,
        AirdropModal,
    },
    computed: {
        ...mapGetters({
            Promotion: 'promotion/Promotion',
            MyPromotion: 'promotion/MyPromotion',
        }),
        RenderedPromotions() {
            return this.Promotion.toReversed();
        },
        BannerAvailable() {
            return !!this.MyPromotion?.results?.find(
                (el) => el.type === 'mystery_box',
            );
        },
    },
    data: () => ({
        settings: {
            dots: true,
            autoplay: true,
            draggable: false,
            autoplaySpeed: 10000,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                    },
                },
            ],
        },
        promotionIndex: 0,
        chosenPromotion: null,

        manualPromo: undefined,
    }),
    methods: {
        openPromotion(promo) {
            this.chosenPromotion = promo;
            this.$bvModal.show('airdrop');
        },
        async getManualPromotion() {
            const res = await this.$store.dispatch('promotion/get5kPromo');

            if (res.status) {
                this.manualPromo = res;
            }
        },
        async handleManualPromotion() {
            await this.$router.push({ name: 'BuyXnode' });
            this.$store.commit('promotion/action5kPromo');
        },
        async goToCorpoNode() {
            await this.$router.push({ name: 'CorpoPackage' });
        },

        async goToNFT() {
            await this.$router.push({ name: 'BuyNFT' });
        },
    },
    mounted() {
        this.$store.dispatch('promotion/getPromotionInfo');
        this.$store.dispatch('promotion/getPromotion');
        this.getManualPromotion();
    },
};
</script>

<style lang="scss" scoped>
.event-banners {
    border-radius: 12px;
    overflow: hidden;
    margin: 1rem 0px;
}
.arrow-botan {
    background-color: #828e9c6e;
    top: 50%;
    width: 40px;
    height: auto;
    padding: 5px;
    border-radius: 100vw;
    z-index: 1;
    transform: translate(0, -50%);
    color: white;
    &.slick-prev {
        left: 5px;
        > svg {
            transform: rotate(180deg);
        }
    }
    &.slick-next {
        right: 5px;
    }
    > svg {
        width: 100%;
        aspect-ratio: 1;
    }
    &::before {
        display: none;
    }
    &:hover {
        background-color: #828e9c8f;
        color: white;
    }
    &:focus {
        background-color: #828e9c8f;
        color: white;
    }

    @media (min-width: 576px) {
        width: 50px;
    }
}
</style>
<style lang="scss">
.my-slide {
    vertical-align: bottom;
    line-height: 0;
    cursor: pointer;
    .event-banner {
        width: 100%;
        object-fit: contain;
        border-radius: 12px;
    }
}
.event-banners {
    .slick-dots {
        bottom: 0;
        line-height: 0px;
        padding: 5px 0px;
        li {
            width: fit-content;
            height: auto;
        }
        .my-dot {
            width: 18px;
            height: 18px;
            background-color: white;
            transition: 0.2s;
            border-radius: 100vw;
            opacity: 0.6;
            &:hover {
                opacity: 0.8;
            }
            &::before {
                display: none;
            }
        }
        .slick-active {
            .my-dot {
                background-color: #1478b0;
                opacity: 1;
            }
        }
    }
}
</style>
