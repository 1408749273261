// src/components/MysteryBox/services/interaction.service.js
import * as THREE from 'three';

/**
 * Setup raycasting for box interaction
 *
 * @param {THREE.WebGLRenderer} renderer - Three.js renderer
 * @param {THREE.Camera} camera - Three.js camera
 * @param {THREE.Group} boxGroup - The box group for intersection testing
 * @param {Function} onBoxClick - Callback when box is clicked
 * @returns {Function} - Function to dispose interaction handlers
 */
export const setupBoxInteraction = (renderer, camera, boxGroup, onBoxClick) => {
    // Create raycaster for interaction
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    const handleClick = (event) => {
        // Calculate mouse position in normalized device coordinates
        const rect = renderer.domElement.getBoundingClientRect();
        mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        mouse.y =
            -((event.clientY - rect.top) / rect.height) * 2 +
            // Update the raycaster
            raycaster.setFromCamera(mouse, camera);

        // Check for intersections with the box
        const intersects = raycaster.intersectObject(boxGroup, true);

        // If the box was clicked, notify callback
        if (intersects.length > 0 && onBoxClick) {
            onBoxClick();
        }
    };

    // Add click event listener
    renderer.domElement.addEventListener('click', handleClick);

    // Return cleanup function
    return () => {
        renderer.domElement.removeEventListener('click', handleClick);
    };
};

/**
 * Handle box click interaction from touch/click area
 *
 * @param {Event} event - The click/touch event
 * @param {THREE.WebGLRenderer} renderer - Three.js renderer
 * @param {THREE.Camera} camera - Three.js camera
 * @param {THREE.Group} boxGroup - The box group for intersection testing
 * @param {Object} options - Options
 * @param {boolean} options.isBoxOpened - Whether box is already opened
 * @param {Function} options.onBoxClick - Callback when box is clicked
 * @returns {boolean} - Whether interaction was handled
 */
export const handleInteractionClick = (
    event,
    renderer,
    camera,
    boxGroup,
    options = {},
) => {
    // If renderer or camera are not initialized, exit early
    if (!renderer || !camera || !boxGroup) {
        console.warn('Required Three.js objects not initialized');
        return false;
    }

    // If this was triggered by a click on the close button, do nothing
    if (event && event.target.classList.contains('close-button')) {
        return false;
    }

    // Skip if box is already open
    if (options.isBoxOpened) return false;

    // Create raycaster for the interaction
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    // Calculate mouse position in normalized device coordinates
    const rect = renderer.domElement.getBoundingClientRect();
    mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    // Update the raycaster
    raycaster.setFromCamera(mouse, camera);

    // Check for intersections with the box
    const intersects = raycaster.intersectObject(boxGroup, true);

    // If the box was clicked, handle the interaction
    if (intersects.length > 0 && options.onBoxClick) {
        options.onBoxClick(event);
        return true;
    }

    return false;
};
