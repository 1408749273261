import Vue from 'vue';
// eslint-disable-next-line import/no-cycle
import VueCookies from 'vue-cookies';
import { EventSourcePolyfill } from 'event-source-polyfill';
// eslint-disable-next-line import/no-cycle
import store from '@/store';

Vue.use(VueCookies);

class SSEService {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
        this.eventSource = null;
        this.reconnectTimeout = 10000;
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 15;
        this.eventHandlers = {
            statusChange: [],
            merchantUpdate: [],
            error: [],
        };
    }

    /**
     * Add event listener
     * @param {string} eventName - Name of the event to listen for
     * @param {Function} callback - Function to call when the event occurs
     * @returns {SSEService} - Returns this for method chaining
     */
    on(eventName, callback) {
        if (this.eventHandlers[eventName]) {
            this.eventHandlers[eventName].push(callback);
        }
        return this;
    }

    /**
     * Trigger event for all registered handlers
     * @param {string} eventName - Name of the event to trigger
     * @param {*} data - Data to pass to the event handlers
     */
    trigger(eventName, data) {
        if (this.eventHandlers[eventName]) {
            this.eventHandlers[eventName].forEach((callback) => callback(data));
        }
    }

    /**
     * Connect to the SSE endpoint
     */
    connect() {
        if (this.eventSource) {
            // console.log('Closing existing connection before creating new one');
            this.disconnect();
        }

        // Get token from cookies
        const token = VueCookies.get('xintel_user');

        if (!token) {
            this.handleError(new Error('Authentication token not found'));
            return;
        }

        // console.log(token.key);
        const headers = {
            'authorization': `Bearer ${token.key}`,
            'Content-Type': 'application/json',
            'heartbeatTimeout': 10 * 60 * 1000,
        };

        try {
            // this.eventSource = new EventSourcePolyfill(
            //     `${this.baseUrl}/merchant/events`,
            //     {
            //         headers: {
            //             Authorization: `Bearer ${token.key}`,
            //             // 'Content-Type': 'text/event-stream',
            //         },
            //         // heartbeatTimeout: 30 * 60 * 1000, // 30 minutes
            //         // withCredentials: true,
            //         heartbeatTimeout: 60000, // 30 minutes
            //     },
            // );

            this.eventSource = new EventSourcePolyfill(
                `${this.baseUrl}/merchant/events`,
                { headers },
            );

            this.setupEventListeners();
        } catch (error) {
            this.handleError(error);
        }
    }

    /**
     * Set up event listeners for the SSE connection
     */
    setupEventListeners() {
        if (!this.eventSource) return;

        // Handle connection open
        this.eventSource.onopen = () => {
            this.reconnectAttempts = 0;
            this.trigger('statusChange', true);
        };

        // Handle generic messages
        this.eventSource.onmessage = (event) => {
            // console.log(event);

            try {
                const data = JSON.parse(event.data);
                // console.warn('Parsed message data:', data);

                if (data.type === 'merchant_update') {
                    this.trigger('merchantUpdate', data);
                    store.dispatch('merchant/refreshMerchantData');
                }
            } catch (error) {
                this.handleError(
                    new Error(`Error parsing SSE message: ${error.message}`),
                );
            }
        };

        // Handle errors
        this.eventSource.onerror = (error) => {
            this.handleError(error);
            // this.attemptReconnect();
            // this.connect();
            this.connect();
        };
    }

    /**
     * Handle errors in a consistent way
     * @param {Error} error - The error that occurred
     */
    handleError(error) {
        this.trigger('statusChange', false);
        this.trigger('error', error);
    }

    /**
     * Attempt to reconnect to the SSE endpoint
     */
    attemptReconnect() {
        // Close the connection
        if (this.eventSource) {
            this.eventSource.close();
            this.eventSource = null;
        }

        // Attempt to reconnect if under max attempts
        if (this.reconnectAttempts < this.maxReconnectAttempts) {
            // eslint-disable-next-line no-plusplus
            this.reconnectAttempts++;
            setTimeout(() => this.connect(), this.reconnectTimeout);
        }
    }

    /**
     * Disconnect from the SSE endpoint
     */
    disconnect() {
        if (this.eventSource) {
            this.eventSource.close();
            this.eventSource = null;
            this.trigger('statusChange', false);
        }
    }

    /**
     * Check if the SSE connection is currently open
     * @returns {boolean} - True if connected, false otherwise
     */
    isConnected() {
        return (
            this.eventSource !== null &&
            this.eventSource.readyState === EventSourcePolyfill.OPEN
        );
    }
}

// Create a singleton instance
const baseUrl = process.env.VUE_APP_REST_API_URL || '';
const sseService = new SSEService(baseUrl);

export default sseService;
