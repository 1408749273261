<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
    >
        <path
            fill="none"
            :stroke="color"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m14 7l-5 5l5 5"
        />
    </svg>
</template>
<script>
export default {
    name: 'ArrowLeftIcon',
    props: {
        color: {
            type: String,
        },
    },
};
</script>
<style lang=""></style>
