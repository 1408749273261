<template>
    <div class="esim-plan">
        <div class="banner">
            <carousel
                :per-page="1"
                :autoplay="true"
                :loop="true"
                :paginationEnabled="false"
                :autoplayTimeout="5000"
            >
                <slide>
                    <img
                        class="desktop"
                        src="@/assets/images/mockup/banner_100.jpg"
                        alt=""
                    />
                    <img
                        class="mobile"
                        src="@/assets/images/mockup/banner_100_mobile.jpg"
                        alt=""
                    />
                </slide>
                <slide>
                    <img
                        class="desktop"
                        src="@/assets/images/mockup/banner_155.jpg"
                        alt=""
                    />
                    <img
                        class="mobile"
                        src="@/assets/images/mockup/banner_155_mobile.jpg"
                        alt=""
                    />
                </slide>
            </carousel>
        </div>

        <div>
            <div class="title">
                <span>{{
                    tab === 'plan' ? $t('eSimPlans') : $t('history')
                }}</span>
                <div class="change-tab">
                    <div @click="changeTab">
                        {{ tab === 'plan' ? $t('history') : $t('eSimPlans') }}
                    </div>
                </div>
            </div>
            <EsimPlanBuy :EsimData="EsimData" v-if="tab === 'plan'" />
            <EsimPlanHistory v-if="tab === 'history'" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import EsimPlanBuy from './Esim/EsimPlanBuy.vue';
import EsimPlanHistory from './Esim/EsimPlanHistory.vue';
import { Carousel, Slide } from 'vue-carousel';
export default {
    components: {
        EsimPlanBuy,
        EsimPlanHistory,
        Carousel,
        Slide,
    },

    data() {
        return {
            tab: 'plan',
        };
    },

    computed: {
        ...mapGetters({
            EsimData: 'nft/EsimData',
        }),
    },

    methods: {
        changeTab() {
            this.tab = this.tab === 'plan' ? 'history' : 'plan';
        },
    },

    mounted() {
        this.$store.dispatch('nft/getEsimData');
    },
};
</script>

<style lang="scss" scoped>
.esim-plan {
    margin-bottom: 50px;
    .banner {
        img {
            border-radius: 22px;
            width: 100%;
        }

        .mobile {
            display: none;
        }

        @media (max-width: 991px) {
            .desktop {
                display: none;
            }

            .mobile {
                display: block;
            }
        }
    }

    .title {
        margin-top: 15px;
        font-weight: 700;
        font-size: 24px;
        color: #0087cc;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .change-tab {
            text-align: right;
            padding-right: 15px;
            padding-bottom: 15px;
            div {
                font-size: 18px;
                font-weight: 600;
                color: #fff;
                padding: 0px 10px;
                position: relative;
                line-height: 1.6;
                display: inline-block;
                background-color: #0087cc;
                filter: drop-shadow(1px 1px 2px #a1a1a1);
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
                &::before {
                    content: '';
                    position: absolute;
                    border: 0.8em solid #0087cc;
                    border-left-color: transparent;
                    right: 100%;
                    top: 0;
                    bottom: 0;
                }
                &::after {
                    content: '';
                    position: absolute;
                    border: 0.8em solid transparent;
                    border-color: transparent transparent transparent #0087cc;
                    left: 100%;
                    top: 0;
                    bottom: 0;
                }
            }
        }
    }
}
</style>
