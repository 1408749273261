// src/components/MysteryBox/utils/responsive.utils.js

/**
 * Handle screen resize for Three.js camera and renderer
 *
 * @param {number} width - Container width
 * @param {number} height - Container height
 * @param {THREE.PerspectiveCamera} camera - Three.js camera
 * @param {THREE.WebGLRenderer} renderer - Three.js renderer
 */
export const handleScreenResize = (width, height, camera, renderer) => {
    // Update camera aspect ratio
    camera.aspect = width / height;
    camera.updateProjectionMatrix();

    // Adjust camera position based on screen size
    if (width < 768) {
        // Mobile devices
        camera.position.set(0, 1.5, 4.5);
    } else if (width < 1200) {
        // Tablets
        camera.position.set(0, 1.4, 4.2);
    } else {
        // Desktops
        camera.position.set(0, 1.2, 3.8);
    }

    // Update renderer size with device pixel ratio
    renderer.setSize(width, height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
};

/**
 * Adjust scene elements based on screen size
 *
 * @param {number} width - Container width
 * @param {number} height - Container height
 * @param {THREE.Group} boxGroup - The box group to scale
 */
export const adjustElementsForScreenSize = (width, height, boxGroup) => {
    // Calculate a scale factor based on screen width
    let scaleFactor = 1; // Default for large screens

    if (width < 480) {
        // Very small mobile devices
        scaleFactor = 0.7;
    } else if (width < 768) {
        // Mobile devices
        scaleFactor = 0.8;
    } else if (width < 1200) {
        // Tablets
        scaleFactor = 0.9;
    }

    // Only adjust if box exists
    if (boxGroup) {
        // Scale the box if needed
        boxGroup.scale.set(scaleFactor, scaleFactor, scaleFactor);

        // You might need to adjust position if you scale it
        boxGroup.position.y = 0 * scaleFactor;
    }
};
