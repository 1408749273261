// src/components/MysteryBox/utils/touch.utils.js

/**
 * Initialize touch support for the component
 *
 * @param {HTMLElement} container - The container element
 * @param {Object} options - Options
 * @param {Function} options.onBoxClick - Callback when box is clicked
 * @param {Function} options.onClose - Callback when close button is clicked
 */
export const initializeTouchSupport = (container, options = {}) => {
    // Add touch start event (equivalent to click for mobile)
    container.addEventListener(
        'touchstart',
        (event) => {
            // Check if we're touching the close button
            let target = event.target;
            if (target.classList.contains('close-button')) {
                // If close button was touched, stop propagation and call closeBox
                event.preventDefault();
                event.stopPropagation();
                if (options.onClose) {
                    options.onClose();
                }
                return; // Exit early
            }

            // Normal touch handling for the box interaction
            // Prevent default to avoid scrolling
            event.preventDefault();

            // Only process click if onBoxClick was provided
            if (options.onBoxClick) {
                options.onBoxClick(event);
            }
        },
        { passive: false },
    );

    // Add touch event handler specifically for the close buttons
    const addCloseButtonTouchHandler = () => {
        const closeButtons = container.querySelectorAll('.close-button');

        closeButtons.forEach((button) => {
            button.addEventListener(
                'touchstart',
                (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (options.onClose) {
                        options.onClose();
                    }
                },
                { passive: false },
            );
        });
    };

    // Call it now
    addCloseButtonTouchHandler();

    // Set up a MutationObserver to attach handlers to close buttons that might be added later
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.addedNodes.length) {
                addCloseButtonTouchHandler();
            }
        });
    });

    observer.observe(container, { childList: true, subtree: true });

    // Optional: Add touch move handler
    container.addEventListener(
        'touchmove',
        (event) => {
            event.preventDefault();
        },
        { passive: false },
    );

    // Return a function to remove all event listeners
    return () => {
        container.removeEventListener('touchstart', () => {});
        container.removeEventListener('touchmove', () => {});
        observer.disconnect();
    };
};
