<script>
import { mapGetters } from 'vuex';
import { orderBuy, orderSell } from '@/store/modules/exchange';

export default {
    props: {
        showHeader: {
            type: Boolean,
            default: false,
        },
        hiddenHeader: {
            type: Boolean,
            default: false,
        },
        coin: {
            type: Object,
            default: null,
        },
        tabActive: {
            type: String,
            default: '',
        },
        itemPick: {
            type: Object,
            default: null,
        },
    },
    name: 'ExchangeComponent',
    data() {
        return {
            orderActive: 'isBuy',
            infoSell: {
                amount: 0,
                price: 0.0,
            },
            infoBuy: {
                amount: 0,
                price: 0.0,
            },
            pending: false,
        };
    },
    watch: {
        tabActive: {
            handler(newVal) {
                if (newVal === 'isBuy' || newVal === 'isSell') {
                    this.orderActive = newVal;
                }
            },
        },
        itemPick: {
            handler(newVal) {
                this.calculatorAmount({
                    type: newVal.type,
                    amount: newVal.total,
                    totalPrice: newVal.totalPrice,
                    price: newVal.price,
                });
            },
        },
    },
    computed: {
        ...mapGetters({
            // BalanceSystem: 'sbox/BalanceUser2',
            UserInfo: 'info/UserInfo',
        }),
        TypeAction() {
            let type = {
                sell: 'xinCoin',
                buy: 'USDT',
            };
            if (this.coin && this.coin.name.length > 1) {
                const { name } = this.coin;
                const arrName = name.split('/');
                type = {
                    sell: arrName[0],
                    buy: arrName[1],
                };
            }
            return type;
        },
        BalanceSystem() {
            return {
                xinCoin: this.UserInfo.xinCoin,
                USDT: this.UserInfo.USDT,
            };
        },
    },
    methods: {
        async onChoseAmount(type, percent, coin) {
            const balance = this.BalanceSystem[coin];
            const balanceSelect = this.BalanceSystem[coin];
            console.log(coin);
            if (type === 'Sell') {
                if (this.infoSell.price <= 0) {
                    this.$toastr.e(
                        'Please enter the price before chose percentage or sell',
                        'Oops!',
                    );
                    return;
                }
                this.infoSell.amount = balance * percent;
            } else {
                if (this.infoBuy.price <= 0) {
                    this.$toastr.e(
                        'Please enter the price before chose percentage or buy',
                        'Oops!',
                    );
                    return;
                }
                console.log(balanceSelect * this.infoBuy.price);
                this.infoBuy.amount = parseInt(
                    (balanceSelect / this.infoBuy.price) * percent,
                    10,
                );
            }
        },
        async onBuySell(type) {
            // if (1 !== 2) {
            //     this.$toastr.w(
            //         'Energy Trading Is ComingSoon, Please Come Back Later',
            //         'ComingSoon',
            //     );
            //     return;
            // }
            if (!type) {
                this.$toastr.e('Error', 'Please try again');
            }
            if (this.pending) {
                return;
            }
            this.pending = true;

            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('p2pExchange');

            if (type === 'Sell') {
                const { amount, price } = this.infoSell;
                if (
                    !amount ||
                    this.isNaN(amount) ||
                    !price ||
                    this.isNaN(price)
                ) {
                    this.$toastr.e('Error', 'Please try again');
                    this.pending = false;
                    return;
                }

                await orderSell({
                    idCoin: this.coin.id,
                    amount,
                    price,
                    token,
                });
            } else {
                const { amount, price } = this.infoBuy;

                if (
                    !amount ||
                    this.isNaN(amount) ||
                    !price ||
                    this.isNaN(price)
                ) {
                    this.$toastr.e('Error', 'Please try again');
                    this.pending = false;
                    return;
                }

                await orderBuy({
                    idCoin: this.coin.id,
                    amount,
                    price,
                    token,
                });
            }
            this.pending = false;

            this.$emit('getListTrading', true);
        },
        calculatorAmount({ price, amount, totalPrice, type }) {
            const { USDT } = this.BalanceSystem;
            const ChosenCoin = this.BalanceSystem[this.coin.item];
            if (type === 'buy') {
                this.infoBuy.price = price;
                if (USDT >= totalPrice) {
                    this.infoBuy.amount = amount;
                } else {
                    this.infoBuy.amount = parseInt(USDT / price, 10);
                }
            }
            if (type === 'sell') {
                this.infoSell.price = price;
                console.log(ChosenCoin);
                if (ChosenCoin >= amount) {
                    this.infoSell.amount = amount;
                } else {
                    this.infoSell.amount = ChosenCoin;
                }
            }

            //     if (type === 'sell') {
            //         this.infoSell.amount = amount;
            //     } else {
            //         this.infoBuy.amount = amount;
            //     }
            // } else if (type === 'sell') {
            //     this.infoSell.amount = parseInt(HBG / price - 1, 10);
            // } else {
            //     this.infoBuy.amount = parseInt(HBG / price - 1, 10);
            // }

            // if (type === 'sell') {
            //     this.infoSell.price = price;
            // } else {
            //     this.infoBuy.price = price;
            // }
        },
    },
    mounted() {
        if (this.tabActive === 'isBuy' || this.tabActive === 'isSell') {
            this.orderActive = this.tabActive;
        }
        if (this.itemPick.type) {
            const { type, total, totalPrice, price } = this.itemPick;
            this.calculatorAmount({
                type,
                amount: total,
                totalPrice,
                price,
            });
        }
    },
};
</script>
<template>
    <div class="form-trade">
        <div class="trade">
            <div class="trade-header" v-if="showHeader && !hiddenHeader">
                <b-button
                    class="trade-header-item"
                    variant="none"
                    :class="{ active: orderActive == 'isBuy' }"
                    @click="orderActive = 'isBuy'"
                >
                    Order Buy
                </b-button>

                <b-button
                    class="trade-header-item"
                    variant="none"
                    :class="{ active: orderActive == 'isSell' }"
                    @click="orderActive = 'isSell'"
                >
                    Order Sell
                </b-button>
            </div>
            <div class="trade-body">
                <div
                    class="trade-body-buy"
                    v-if="!showHeader || orderActive == 'isBuy'"
                >
                    <div class="form-trade">
                        <div class="label-balance">
                            <div class="label-balance-left">Avbl</div>
                            <div class="label-balance-right">
                                {{
                                    TruncateToDecimals2(
                                        BalanceSystem[TypeAction.buy] || 0,
                                        '',
                                        2,
                                    )
                                }}
                                {{ TypeAction.buy }}
                            </div>
                        </div>
                        <b-input-group :append="TypeAction.buy" prepend="Price">
                            <input
                                class="form-control"
                                :placeholder="`Price ${TypeAction.buy}`"
                                v-model="infoBuy.price"
                                type="number"
                                step="any"
                                @focus="$event.target.select()"
                            />
                        </b-input-group>
                    </div>
                    <div class="form-trade">
                        <div class="label-balance"></div>
                        <b-input-group
                            :append="TypeAction.sell"
                            prepend="Amount"
                        >
                            <input
                                class="form-control"
                                :placeholder="`Amount ${TypeAction.sell}`"
                                v-model="infoBuy.amount"
                                type="number"
                                step="any"
                                @focus="$event.target.select()"
                            />
                        </b-input-group>
                    </div>
                    <div class="suggest-trade">
                        <b-button
                            @click="onChoseAmount('Buy', 0.25, TypeAction.buy)"
                            variant="none"
                            class="suggest-trade-box"
                        >
                            25%
                        </b-button>
                        <b-button
                            @click="onChoseAmount('Buy', 0.5, TypeAction.buy)"
                            variant="none"
                            class="suggest-trade-box"
                        >
                            50%
                        </b-button>
                        <b-button
                            @click="onChoseAmount('Buy', 0.75, TypeAction.buy)"
                            variant="none"
                            class="suggest-trade-box"
                        >
                            75%
                        </b-button>
                        <b-button
                            @click="onChoseAmount('Buy', 1, TypeAction.buy)"
                            variant="none"
                            class="suggest-trade-box"
                        >
                            100%
                        </b-button>
                    </div>
                    <div class="form-trade mt-3">
                        <div class="label-balance"></div>
                        <b-input-group :append="TypeAction.buy" prepend="Total">
                            <b-form-input
                                type="text"
                                readonly
                                :value="
                                    TruncateToDecimals2(
                                        infoBuy.amount * infoBuy.price,
                                        '',
                                        4,
                                    )
                                "
                            ></b-form-input>
                        </b-input-group>
                    </div>
                    <div
                        class="form-trade d-flex justify-content-center align-items-center"
                    >
                        <b-button
                            variant="none"
                            class="button-trade buy"
                            :disabled="
                                infoBuy.price <= 0 || infoBuy.amount <= 0
                            "
                            @click.prevent="onBuySell('Buy')"
                        >
                            Buy
                        </b-button>
                    </div>
                </div>
                <div
                    class="trade-body-sell"
                    v-if="!showHeader || orderActive == 'isSell'"
                >
                    <div class="form-trade">
                        <div class="label-balance">
                            <div class="label-balance-left">Avbl</div>
                            <div class="label-balance-right" v-if="coin">
                                {{
                                    TruncateToDecimals2(
                                        BalanceSystem[TypeAction.sell] || 0,
                                        '',
                                        2,
                                    )
                                }}
                                {{ TypeAction.sell }}
                            </div>
                        </div>
                        <b-input-group :append="TypeAction.buy" prepend="Price">
                            <input
                                class="form-control"
                                :placeholder="`Price ${TypeAction.sell}`"
                                v-model="infoSell.price"
                                type="number"
                                step="any"
                                @focus="$event.target.select()"
                            />
                        </b-input-group>
                    </div>
                    <div class="form-trade">
                        <div class="label-balance"></div>
                        <b-input-group
                            :append="TypeAction.sell"
                            prepend="Amount"
                        >
                            <input
                                class="form-control"
                                :placeholder="`Amount ${TypeAction.sell}`"
                                v-model="infoSell.amount"
                                type="number"
                                step="any"
                                @focus="$event.target.select()"
                            />
                        </b-input-group>
                    </div>
                    <div class="suggest-trade">
                        <b-button
                            @click="
                                onChoseAmount('Sell', 0.25, TypeAction.sell)
                            "
                            variant="none"
                            class="suggest-trade-box"
                        >
                            25%
                        </b-button>
                        <b-button
                            @click="onChoseAmount('Sell', 0.5, TypeAction.sell)"
                            variant="none"
                            class="suggest-trade-box"
                        >
                            50%
                        </b-button>
                        <b-button
                            @click="
                                onChoseAmount('Sell', 0.75, TypeAction.sell)
                            "
                            variant="none"
                            class="suggest-trade-box"
                        >
                            75%
                        </b-button>
                        <b-button
                            @click="onChoseAmount('Sell', 1, TypeAction.sell)"
                            variant="none"
                            class="suggest-trade-box"
                        >
                            100%
                        </b-button>
                    </div>
                    <div class="form-trade mt-3">
                        <div class="label-balance"></div>
                        <b-input-group :append="TypeAction.buy" prepend="Total">
                            <b-form-input
                                type="text"
                                readonly
                                :value="
                                    TruncateToDecimals2(
                                        infoSell.amount * infoSell.price,
                                        '',
                                        4,
                                    )
                                "
                            ></b-form-input>
                        </b-input-group>
                    </div>
                    <div
                        class="form-trade d-flex justify-content-center align-items-center"
                    >
                        <b-button
                            variant="none"
                            class="button-trade sell"
                            :disabled="
                                infoSell.price <= 0 || infoSell.amount <= 0
                            "
                            @click.prevent="onBuySell('Sell')"
                        >
                            Sell
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.form-trade {
    .trade {
        width: 100%;
        min-height: 300px;
        padding: 15px;
        font-weight: 700;
        &-header {
            // border: 1px solid #97D7F8;
            width: 100%;
            // max-width: 300px;
            height: 40px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            &-item {
                color: #fff;
                height: 40px;
                display: flex;
                font-weight: 600;
                box-shadow: none;
                border: 1px solid transparent;
                margin: 0 5px;
                &.active {
                    color: #97d7f8;
                    border: 1px solid #97d7f8;
                }
            }
        }
        &-body {
            height: calc(100% - 55px);
            margin-top: 25px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            flex-wrap: wrap;

            &-sell,
            &-buy {
                height: 100%;
                padding: 0 15px;
                width: 100%;
                .form-trade {
                    margin-bottom: 15px;
                    .label-balance {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: #fff;

                        .label-balance-left {
                        }
                        .label-balance-right {
                        }
                    }
                    .input-group {
                        border: 1px solid #97d7f8;
                        border-radius: 5px;
                    }
                    input {
                        background-color: rgba(119, 119, 119, 0.6);
                        color: #fff;
                        border: 0;

                        height: 40px;
                        // font-size: 19px;
                        &::placeholder {
                            color: #fff;
                            opacity: 0.6;
                        }
                    }
                    input[type='number'] {
                        -moz-appearance: textfield;
                    }
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        /* display: none; <- Crashes Chrome on hover */
                        -webkit-appearance: none;
                        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
                    }
                    .input-group-text {
                        border: 0;
                        background: linear-gradient(#fff, #97d7f8);
                        color: #1478b0;
                        font-weight: 700;
                    }
                }
                .suggest-trade {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .suggest-trade-box {
                        flex: 1;
                        margin: 0 5px;
                        background-color: #fff;
                        border: 1px solid #97d7f8;
                        color: #1478b0;
                        font-weight: 700;
                        transition: 0.2s;
                        &:hover {
                            border-color: #fff;
                            background-color: #1478b0;
                            color: #fff;
                        }
                        &:first-child {
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                .button-trade {
                    margin: 10px auto;
                    width: 175px;
                    height: 50px;
                    color: #fff;
                    outline: none !important;
                    font-style: italic;
                    letter-spacing: 1px;
                    font-size: 1.3em;
                    font-weight: 600;

                    box-shadow: none;
                    // filter: drop-shadow(
                    //     2px 2px 1px rgba($color: #000000, $alpha: 0.7)
                    // );
                    transition: 500ms;
                    &:focus,
                    &:hover {
                        transform: scale(0.9);
                    }
                    &.sell {
                        background: url('~@/assets/images/mockup/account/bgBtn-red.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                    &.buy {
                        background: url('~@/assets/images/mockup/account/bgBtn-green.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                }
            }
            &-sell {
            }
            &-buy {
            }
            @media (min-width: 1400px) {
                flex-wrap: wrap;
                &-sell,
                &-buy {
                    width: 50%;
                }
            }
        }
    }
}
</style>
