<template>
    <div class="detail-location">
        <button @click="$bvModal.show('detail-location-' + location.id)">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 16 16"
            >
                <path
                    fill="#0087cc"
                    fill-rule="evenodd"
                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M9 5a1 1 0 1 1-2 0a1 1 0 0 1 2 0M7 7a.75.75 0 0 0 0 1.5h.25v2h-1a.75.75 0 0 0 0 1.5h3.5a.75.75 0 0 0 0-1.5h-1V7z"
                    clip-rule="evenodd"
                />
            </svg>
        </button>
        <b-modal
            :id="'detail-location-' + location.id"
            hide-header
            hide-footer
            centered
            size="lg"
            dialog-class="location-detail"
        >
            <b-carousel id="carousel-1" :interval="100000" indicators>
                <b-carousel-slide
                    v-for="image in dataDetail.image"
                    :key="image"
                >
                    <img :src="image" alt="img-detail" />
                </b-carousel-slide>
                <b-carousel-slide v-if="dataDetail.image.length === 0">
                    <div class="no-image">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50%"
                            height="50%"
                            viewBox="0 0 32 32"
                        >
                            <path
                                fill="currentColor"
                                d="M30 3.414L28.586 2L2 28.586L3.414 30l2-2H26a2.003 2.003 0 0 0 2-2V5.414zM26 26H7.414l7.793-7.793l2.379 2.379a2 2 0 0 0 2.828 0L22 19l4 3.997zm0-5.832l-2.586-2.586a2 2 0 0 0-2.828 0L19 19.168l-2.377-2.377L26 7.414zM6 22v-3l5-4.997l1.373 1.374l1.416-1.416l-1.375-1.375a2 2 0 0 0-2.828 0L6 16.172V6h16V4H6a2 2 0 0 0-2 2v16z"
                            />
                        </svg>
                    </div>
                </b-carousel-slide>
            </b-carousel>
            <div class="info">
                <p class="badge-base">Master XNode</p>
                <div class="title">
                    <img
                        v-if="dataDetail.logo"
                        :src="dataDetail.logo"
                        alt="logo-LNP"
                    />
                    <p>{{ dataDetail.name }}</p>
                </div>
                <ul class="contact">
                    <li>
                        <img
                            src="@/assets/images/icons/basil_map-location-solid.svg"
                            alt="location-icon"
                        />
                        <p>{{ dataDetail.physical_address }}</p>
                    </li>
                    <li>
                        <img
                            src="@/assets/images/icons/mail.svg"
                            alt="location-icon"
                        />
                        <p>{{ dataDetail.email }}</p>
                    </li>
                    <li>
                        <img
                            src="@/assets/images/icons/phone.svg"
                            alt="location-icon"
                        />
                        <p>{{ dataDetail.phone }}</p>
                    </li>
                </ul>
                <div class="description">
                    <p>{{ dataDetail.description }}</p>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'DetailLocationModal',

    data() {
        return {
            dataDetail: null,
        };
    },

    props: {
        location: {
            type: Object,
        },
    },

    computed: {
        ...mapGetters({
            AddressList: 'map/AddressList',
        }),
    },

    methods: {
        onSlideStart(slide) {
            console.log(slide);
            this.sliding = true;
        },
        onSlideEnd(slide) {
            console.log(slide);
            this.sliding = false;
        },
    },

    mounted() {
        this.dataDetail =
            this.AddressList.results.filter(
                (item) => item.id === this.location.id,
            )[0] ?? null;
    },
};
</script>

<style lang="scss" scoped>
.detail-location {
    button {
        &:focus {
            outline: none;
        }

        &:hover svg {
            scale: 1.1;
        }

        svg {
            border-radius: 50%;
            transition: all 0.2s ease-in-out;
        }
    }
}

.modal-body {
    padding: 32px;

    .info {
        margin-top: 32px;

        .badge-base {
            font-size: 14px;
            font-weight: 500;
            color: white;
            background: linear-gradient(180deg, #00d1ff 0%, #0087c8 100%);
            width: fit-content;
            padding: 2px 8px;
            border-radius: 4px;
            margin-bottom: 8px;
        }

        .title {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 16px;

            img {
                width: 48px;
                height: 48px;
                object-fit: contain;
            }

            p {
                font-size: 32px;
                font-weight: 700;
                line-height: 48px;
                margin: 0;
                color: #3c3c3c;
            }
        }

        .contact {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-bottom: 16px;
            padding: 0;

            li {
                list-style: none;
                display: flex;
                gap: 8px;
                align-items: center;

                p {
                    margin: 0;
                }
            }
        }

        .description {
            color: #3c3c3c;

            p {
                margin: 0;
            }
        }
    }

    @media (max-width: 992px) {
        .info {
            .title {
                p {
                    font-size: 24px;
                }
            }

            .contact {
                font-size: 14px;
            }
        }
    }

    @media (max-width: 576px) {
        .info {
            margin-top: 16px;

            .title {
                p {
                    font-size: 20px;
                }
            }
        }
    }

    @media (max-width: 428px) {
        .swiper-container {
            height: 300px;
        }

        .info {
            .title {
                p {
                    font-size: 14px;
                }
            }

            .contact {
                p {
                    font-size: 12px;
                }
            }

            .description {
                p {
                    font-size: 12px;
                }
            }
        }
    }
}

.carousel {
    height: 300px;

    .carousel-inner {
        height: 100%;
    }
}
</style>

<style lang="scss">
.location-detail {
    .modal-body {
        overflow-y: scroll;
        padding: 32px;
        border-radius: 20px;

        &::-webkit-scrollbar {
            display: none;
        }

        @media (max-width: 576px) {
            padding: 16px;
        }

        .swiper-pagination {
            padding: 0 32px;
            display: flex;
            top: 10px;

            .swiper-pagination-bullet {
                width: 100%;
                border-radius: 16px;
                background-color: #3c3c3c99;
                opacity: 1;
            }

            .swiper-pagination-bullet-active {
                background-color: white;
            }
        }
    }
}

.carousel {
    .carousel-inner {
        height: 100%;

        .carousel-item {
            height: 100%;

            .carousel-caption {
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                padding: 0;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 16px;
                }

                .no-image {
                    width: 100%;
                    height: 100%;
                    color: black;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .carousel-indicators {
        padding: 0 32px;
        top: 0;
        width: 100%;
        margin: 0;

        li {
            width: 100%;
            height: 8px;
            opacity: 1;
            background-color: #3c3c3c99;
            border-radius: 6px;
            border-top: none;
            border-bottom: none;
            margin-top: 8px;
        }

        .active {
            background-color: white;
        }
    }
}
</style>
